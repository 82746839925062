import {getSalaryList, updateSalaryList, deleteSalaryListById} from "../../utils/api";

export const GET_SALARY_LIST_FETCHING = 'GET_SALARY_LIST_FETCHING';
export const GET_SALARY_LIST_FAIL = 'GET_SALARY_LIST_FAIL';
export const GET_SALARY_LIST_SUCCESS = 'GET_SALARY_LIST_SUCCESS';

export const UPDATE_SALARY_LIST_FETCHING = 'UPDATE_SALARY_LIST_FETCHING';
export const UPDATE_SALARY_LIST_FAIL = 'UPDATE_SALARY_LIST_FAIL';
export const UPDATE_SALARY_LIST_SUCCESS = 'UPDATE_SALARY_LIST_SUCCESS';

export const DELETE_SALARY_LIST_FETCHING = 'DELETE_SALARY_LIST_FETCHING';
export const DELETE_SALARY_LIST_FAIL = 'DELETE_SALARY_LIST_FAIL';
export const DELETE_SALARY_LIST_SUCCESS = 'DELETE_SALARY_LIST_SUCCESS';

export const getSalaryListAction = (list_id, query) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type: GET_SALARY_LIST_FETCHING});
        getSalaryList(list_id, query, state.auth.user.accessToken).then(({data}) => {
            dispatch(getSalaryListSuccess(data.data));
        }).catch(e => {
            dispatch(getSalaryListFail(e));
        })
    }
};
export const getSalaryListFail = (e) => {
    return {type: GET_SALARY_LIST_FAIL, payload: {message: e?.message}}
};
export const getSalaryListSuccess = (salary_list) => {
    return {type: GET_SALARY_LIST_SUCCESS, payload: {salary_list}}
};

export const updateSalaryListAction = (newSalaryList, setSuccessModal, user_id, id, setErrorModal, setErrorText) => {
    return (dispatch, getState) => {
        const state = getState();
        updateSalaryList(newSalaryList, user_id, id, state.auth.user.accessToken).then(res => {
            if(res.data.success) {
                setSuccessModal(true);
                dispatch(updateSalaryListSuccess(newSalaryList));
            } else {
                setErrorText(res.data.errors[0].message)
                setErrorModal(true);
            }
        }).catch(error=> {
            dispatch(updateSalaryListFail(error));});
    }
}
export const updateSalaryListFail = (e) => {
    return {type: UPDATE_SALARY_LIST_FAIL, payload: {message: e?.message}}
};
export const updateSalaryListSuccess = (newSalaryList) => {
    return {type: UPDATE_SALARY_LIST_SUCCESS, payload: {newSalaryList}}
};

export const deleteSalaryList = (list_id, id, setSuccessResultModal) =>{
     return (dispatch, getState) => {
        const state = getState();
         dispatch({type: DELETE_SALARY_LIST_FETCHING});
         deleteSalaryListById(list_id, id, state.auth.user.accessToken).then(response => {
             setSuccessResultModal(true);
             dispatch(deleteSalaryListSuccess(id));
         }).catch(e => {
             dispatch(deleteSalaryListFail(e));
         })
    }
};
export const deleteSalaryListFail = (e) => {
    return {type: DELETE_SALARY_LIST_FAIL, payload: {message: e?.message}}
};
export const deleteSalaryListSuccess = (id) => {
    return {type: DELETE_SALARY_LIST_SUCCESS, payload: { id}}
};
