import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import {
  addFinancialReportRow,
  editFinancialReportField,
  getFinancialReportEditAction,
  removeFinancialReportRow,
  setFinancialReportFirstThreeFields,
  setValidationErrors,
} from '../../../../../redux/actions/financialReportEditActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../shared/components/Loading';
import FinancialReportEditForm from './FinancialReportEditForm';
import _ from 'lodash';
import { putFinancialReportEdit } from '../../../../../utils/api';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';

const validate = values => {
  const {expense_constant, investments, spent_corp_events, num_of_working_days, currency, expense_salary} = values;
  const errors = {}

  if (_.isArray(expense_constant)) {
    for (let [index, value] of _.entries(expense_constant)) {
      if(_.every(_.values(_.pick(value, ["amount"])), v => !v)) {
        _.set(errors, `expense_constant.${index}.amount.error`, '*Amount field shouldn’t be empty');
      }
      if(_.every(_.values(_.pick(value, ["amount"])), v => !/^[0-9.,]*$/.test(v))) {
        _.set(errors, `expense_constant.${index}.amount.error`, '*Invalid data type');
      }
      if(_.every(_.values(_.pick(value, ["description"])), v => !v)) {
        _.set(errors, `expense_constant.${index}.description.error`, '*Description field shouldn’t be empty');
      }
    }
  }

  if (_.isArray(investments)) {
    for (let [index, value] of _.entries(investments)) {
      if(_.every(_.values(_.pick(value, ["amount"])), v => !v)) {
        _.set(errors, `investments.${index}.amount.error`, '*Amount field shouldn’t be empty');
      }
      if(_.every(_.values(_.pick(value, ["amount"])), v => !/^[0-9.,]*$/.test(v))) {
        _.set(errors, `investments.${index}.amount.error`, '*Invalid data type');
      }
      if(_.every(_.values(_.pick(value, ["description"])), v => !v)) {
        _.set(errors, `investments.${index}.description.error`, '*Description field shouldn’t be empty');
      }
    }
  }

  if (_.isArray(spent_corp_events)) {
    for (let [index, value] of _.entries(spent_corp_events)) {
      if(_.every(_.values(_.pick(value, ["amount"])), v => !v)) {
        _.set(errors, `spent_corp_events.${index}.amount.error`, '*Amount field shouldn’t be empty');
      }
      if(_.every(_.values(_.pick(value, ["amount"])), v => !/^[0-9.,]*$/.test(v))) {
        _.set(errors, `spent_corp_events.${index}.amount.error`, '*Invalid data type');
      }
      if(_.every(_.values(_.pick(value, ["description"])), v => !v)) {
        _.set(errors, `spent_corp_events.${index}.description.error`, '*Description field shouldn’t be empty');
      }
    }
  }

  if (!num_of_working_days) {
    _.set(errors, 'num_of_working_days.error', '*Number of working days field shouldn’t be empty');
  } else if(num_of_working_days <= 18 || num_of_working_days >= 24) {
    _.set(errors, 'num_of_working_days.error', '*Your number should be between 18 and 24');
  } else if (!/^[0-9]*$/.test(num_of_working_days)) {
    _.set(errors, 'num_of_working_days.error', '*Invalid data type');
  }

  if (!currency) {
    _.set(errors, 'currency.error', '*Currency field shouldn’t be empty');
  } else if (!/^[0-9.]*$/.test(currency)) {
    _.set(errors, 'currency.error', '*Invalid data type');
  }

  if (!!expense_salary && !/^ *[0-9.]*$/.test(expense_salary)) {
    _.set(errors, 'expense_salary.error', '*Invalid data type');
  }

    return errors
}

const FinancialReportEdit = () => {
  const { loading, errors } = useSelector(state => state.financialReportEdit);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const authUser = useSelector(state => state.auth.user);

  const toggleCloseModal = (type) => {
    switch (type) {
      case 'success':
        setSuccessModal(!successModal);
        break;
      case 'danger':
        setErrorModal(!errorModal);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(getFinancialReportEditAction(id));
  }, [dispatch, id])

  const handleFinancialReportEditForm = (values) => {
    const errors = validate(values);
    if (!_.isEmpty(errors)) {
      dispatch(setValidationErrors(errors));
      return;
    }

    dispatch(setValidationErrors({}));

    putFinancialReportEdit(values, id, authUser.accessToken)
      .then(res => {
        if(res.data.success !== true) {
          setErrorMessage(res.data.errors[0].message);
          setErrorModal(!errorModal);
        } else {
          setSuccessModal(!successModal);
        }
      })
  }

  const handleAddRow = (type) => () => {
    dispatch(addFinancialReportRow(type));
  };

  const handleRemoveRow = (type, index) => () => {
    dispatch(removeFinancialReportRow(type, index))
  }

  const handleChangeField = (type, index, field) => (e) => {
    dispatch(editFinancialReportField(type, index, field, e.target.value));
  }

  const handleChangeFirstThreeFields = (value, type) => {
    dispatch(setFinancialReportFirstThreeFields({type, value}));
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="card">
            <div className="card-body pl-0 pr-0">
              {
                loading ? (<Loading />) : (
                  <>
                    <FinancialReportEditForm
                      handleAddRow={handleAddRow}
                      handleChangeField={handleChangeField}
                      onSubmit={handleFinancialReportEditForm}
                      handleRemoveRow={handleRemoveRow}
                      handleChangeFirstThreeFields={handleChangeFirstThreeFields}
                    />
                    <CustomModal
                      successModal={successModal}
                      toggleCloseModal={() => toggleCloseModal('success')}
                      textModal={"Financial Report has been successfully saved!"}
                      color={"success"}
                    />
                    <CustomModal
                      successModal={errorModal}
                      toggleCloseModal={() => toggleCloseModal('danger')}
                      textModal={errorMessage}
                      color={"danger"}
                    />
                  </>
                )
              }
              {
                errors ? (<div>{errors}</div>) : null
              }
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialReportEdit;