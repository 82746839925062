import {
    GET_CLIENTS_FAIL,
    GET_CLIENTS_FETCHING,
    GET_CLIENTS_SUCCESS
} from '../actions/clientsActions';

const initialState = {
    clients: [],
    loading: false,
    errors: '',
    totalRecords: 0
};

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENTS_FETCHING:
            return { ...state, loading: true };
        case GET_CLIENTS_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_CLIENTS_SUCCESS:
            return { ...state, clients: action.payload.clients, totalRecords: Number(action.payload.total_records), loading: false, errors: '' };
        default:
            return state;
    }
};

export default clientsReducer;
