import {getFinancialReports} from "../../utils/api";

export const GET_FINANCIAL_REPORTS_FETCHING = 'GET_FINANCIAL_REPORTS_FETCHING';
export const GET_FINANCIAL_REPORTS_FAIL = 'GET_FINANCIAL_REPORTS_FAIL';
export const GET_FINANCIAL_REPORTS_SUCCESS = 'GET_FINANCIAL_REPORTS_SUCCESS';


export const getFinancialReportsAction = (query) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type: GET_FINANCIAL_REPORTS_FETCHING});
        getFinancialReports(query, state.auth.user.accessToken)
            .then(({data}) => {
                dispatch(getFinancialReportsSuccess(data));
            })
            .catch(e => {
                dispatch(getFinancialReportsFail(e));
            })
    }
};
export const getFinancialReportsFail = (e) => {
    return {type: GET_FINANCIAL_REPORTS_FAIL, payload: {message: e?.message}}
};
export const getFinancialReportsSuccess = (financial_reports) => {
    return {type: GET_FINANCIAL_REPORTS_SUCCESS, payload: {financial_reports}}
};
