import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col } from 'reactstrap';
import { getAllProjects } from '../../../../../utils/api';
import validate from './validate';
import renderSelectField from '../../../../../shared/components/form/Select';
import _ from 'lodash';
import {renderField} from '../../../../../utils/helpers';
import { useSelector } from 'react-redux';


const FinancialReportAddIncomeForm = ({ handleSubmit, initialize }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    initialize({
      from_date: "1",
      to_date: "31"
    });
  }, [initialize])

  useEffect(() => {
    getAllProjects(authUser.accessToken)
      .then(res => setAllProjects(res.data.data.projects))
      .catch(error => console.log(error));
  }, []);

  const handleChangeProject = (value) => {
    setProjectId(value);
  };

  return (
    <div className='card'>
      <div className='card-body'>
        <Col className="col-12">
          <h5 className='mb-2'>COMPLETE INCOME DETAILS</h5>
        </Col>
        <form className='form form-add-income' onSubmit={handleSubmit}>
          <Col sm={6} lg={3} className='col-12'>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Projects</span>
              <div className='form__form-group-field'>
                <Field
                  name='projects'
                  component={renderSelectField}
                  onChange={(e) => handleChangeProject(e.value)}
                  options={[{id: '', name: 'All'}, ...allProjects || []]?.map(u => {
                    return {
                      value: u.id,
                      label: `${u.name}`
                    }
                  })}
                >
                </Field>
              </div>
            </div>
          </Col>
          <Col sm={6} lg={3} className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Users</span>
              <div className='form__form-group-field'>
                <Field
                  name='developers'
                  component={renderSelectField}
                  options={_.map(_.find([{id: '', first_name: 'null', last_name: 'null'}, ...allProjects], { id: Number(projectId) })?.developers, (p) => {
                    return {
                      value: p.id,
                      label: `${p.first_name} ${p.last_name}`
                    }
                  })}
                >
                </Field>
              </div>
            </div>
          </Col>
          <Col sm={4} lg={2} className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Amount</span>
              <div className='form__form-group-field d-flex'>
                <span className='pr-2 mt-2' style={{fontSize: "1rem"}}>$</span>
                <Field
                  name='amount'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col sm={4} lg={2} className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>From date</span>
              <div className='form__form-group-field'>
                <Field
                  name='from_date'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col sm={4} lg={2} className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>To date</span>
              <div className='form__form-group-field'>
                <Field
                  name='to_date'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className='col-12'>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Description</span>
              <div className='form__form-group-field'>
                <Field
                  name='description'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col sm={12} className='d-flex justify-content-end w-100'>
            <button type='submit' className='btn btn-primary'>Save</button>
          </Col>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'financialReportAddIncomeForm',
  validate
})(FinancialReportAddIncomeForm);