import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Card, Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "../../../../../shared/components/form/Select";
import validate from "./validate";
import _ from 'lodash';

let timerId;

const SelectProjects = (props) => {
  const { handleSubmit, onSubmit, projects, date, queryProjects, setQueryProjects, totalRecordsProjects } = props;
  const { t } = useTranslation('common');

  return (
    <Card>
      <form className="form form--statistics" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={9}>
            <Row>
              <Col sm={4} md={5} lg={5} xl={4} className="col-12 col-xxl-3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="select_project"
                      component={renderSelectField}
                      type="text"
                      className="select-project"
                      placeholder={t('statistics_projects.select_project_placeholder')}
                      options={_.sortBy(projects, "name")?.map(s => ({
                        id: s.id,
                        value: s.name,
                        label: s.name
                      }))}
                      onInputChange={(value) => {
                        if(totalRecordsProjects <= 10) return;

                        clearTimeout(timerId);

                        timerId = setTimeout(() => {
                          if (!value) return setQueryProjects({...queryProjects, search_query: ''});

                          setQueryProjects({...queryProjects, search_query: value});
                        }, 600)
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={5} md={3} lg={2} xl={1} className="col-xxl-1 col-12">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="select_year"
                      component={renderSelectField}
                      type="text"
                      className="select-year"
                      options={date?.map(d => ({
                        value: d,
                        label: d
                      }))}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} lg={3}>
            <ButtonToolbar className="form__button-toolbar d-flex w-100 justify-content-end">
              <Button color="primary" type="submit" size='sm'>
                {t('statistics_projects.select_cta')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export default reduxForm({
  form: 'form_select_projects', // a unique identifier for this form
  validate,
})(SelectProjects);