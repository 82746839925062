export const columns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'EMPLOYEE',
        accessor: 'user',
    },
    {
        Header: 'SALARY',
        accessor: 'salary',
    },
    {
        Header: 'OFFICIAL SALARY',
        accessor: 'official_salary',
    },
    {
        Header: 'BONUSES',
        accessor: 'bonuses'
    },
    {
        Header: 'PROJECT BONUSES',
        accessor: 'project_bonuses'
    },
    {
        Header: 'OVERTIME',
        accessor: 'overtime_value'
    },
    {
        Header: 'SURCHARGES',
        accessor: 'other_surcharges'
    },
    {
        Header: 'HOSPITAL EXPENSES',
        accessor: 'hospital_value'
    },
    {
        Header: 'DAY OFF EXPENSES',
        accessor: 'day_off'
    },
    {
        Header: 'CURRENCY RATE',
        accessor: 'currency_rate'
    },
    {
        Header: 'ACTION',
        accessor: 'actions',
        disableSortBy: true,
        disableGlobalFilter: true,
    }
];