import React from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
} from 'react-table';
import ReactTableHeader from './ReactTableHeader';
import BodyReactTable from './ReactTableBody';
import ReactTableFooter from './ReactTableFooter';
import ReactTableFilter from './ReactTableFilter';
import ReactTablePagination from './ReactTablePagination';


const ReactTableConstructor = ({
  tableConfig,
  tableOptions,
  tableOptionalHook,
  tableClass,
  searchValue,
  explanation,
  notes,
  emptyStateMessage,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    pagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    state: { pageIndex, pageSize },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook,
  );

  return (
    <div id={"idForDownload"} className={tableClass}>
      {withSearchEngine && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
          searchValue={searchValue}
        />
      )}
      <div
        className={
          !!pagination
            ? 'table react-table'
            : 'table react-table table--not-pagination'
        }
      >
        <table
          {...getTableProps()}
          className={
            isEditable
              ? 'react-table editable-table'
              : 'react-table resizable-table'
          }
        >
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
            withDragAndDrop={withDragAndDrop}
            emptyStateMessage={emptyStateMessage}
          />
          {(pageCount === pageIndex + 1 ||
            (!!pagination && rows.length !== 0)) && (
            <ReactTableFooter footerGroups={footerGroups} />
          )}
        </table>
      </div>
      {
        explanation ? (
          <>
            <span>MNUR - Min non upwork sale rate</span><br/>
            <span>MUR - Min Upwork sale rate</span><br/>
            <span>OSR - Optimal Sale rate</span><br/>
          </>
        ) : null
      }
      {
        notes && (
          <>
            <div className="d-flex align-items-center">
              <span style={{color: '#75797f', paddingTop: "3px"}}>*</span>
              <div style={{
                background: "#DEEEDD",
                width: "14px",
                height: "14px",
                margin: "0 3px"
              }}></div>
              <span style={{
                color: "#75797f",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "14px"
              }}>{notes.note_one}</span>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <span style={{color: '#75797f', paddingTop: "3px"}}>*</span>
              <div style={{
                background: '#efefef',
                width: '14px',
                height: '14px',
                margin: '0 3px'
              }}></div>
              <span style={{
                color: '#75797f',
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
              }}>{notes.note_two}</span>
            </div>
          </>
        )
      }
      {!!pagination && rows.length > 0 && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          dataLength={dataLength}
          manualPageSize={manualPageSize}
          setPageSize={setPageSize}
          {...pagination}
        />
      )}
    </div>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  },
  tableOptions: [
    {
      columns: [],
      data: [],
      isEditable: PropTypes.bool,
      setFilterValue: () => {},
      updateData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      autoResetPage: false,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
      manualPagination: false,
      initialState: {
        pageIndex: null,
        pageSize: null,
      },
    },
  ],
};

export default ReactTableConstructor;
