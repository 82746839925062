import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { renderField } from '../../../../../../utils/helpers';


const FormCreateSalaryReports = ({handleSubmit}) => {
  return (
    <Card>
      <CardBody className="card-body-adaptive">
        <form className="form" onSubmit={handleSubmit}>
          <Col className="pb-3">
            <span style={{color: "#29b6f6"}}>COMPLETE SALARY REPORT DETAILS</span>
          </Col>
          <Col className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>ENTER MONTH (1-12)</span>
              <div className='form__form-group-field'>
                <Field
                  name='report_date'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12 d-flex justify-content-end">
            <Button type="submit" color="primary">
              Save
            </Button>
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

 export default reduxForm({
  form: "formCreateSalaryReports",
  validate
})(FormCreateSalaryReports);