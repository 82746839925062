import {
  getSubcontractor,
  postSubcontractor,
  putSubcontractor,
  deleteSubcontractor,
  getUsers,
} from '../../utils/api';

import { displayNotification } from '../../shared/components/Notification';
import roles from '../../config/roles';

export const GET_SUBCONTRACTORS_FETCHING = 'GET_SUBCONTRACTORS_FETCHING';
export const GET_SUBCONTRACTORS_FAIL = 'GET_SUBCONTRACTORS_FAIL';
export const GET_SUBCONTRACTORS_SUCCESS = 'GET_SUBCONTRACTORS_SUCCESS';

export const MODAL_SUBCONTRACTORS_FETCHING = 'MODAL_SUBCONTRACTORS_FETCHING';
export const MODAL_SUBCONTRACTORS_FAIL = 'MODAL_SUBCONTRACTORS_FAIL';

export const GET_SUBCONTRACTORS_USERS_FETCHING =
  'GET_SUBCONTRACTORS_USERS_FETCHING';
export const GET_SUBCONTRACTORS_USERS_FAIL = 'GET_SUBCONTRACTORS_USERS_FAIL';
export const GET_SUBCONTRACTORS_USERS_SUCCESS =
  'GET_SUBCONTRACTORS_USERS_SUCCESS';

export const getSubcontractorsFail = (errors) => {
  return { type: GET_SUBCONTRACTORS_FAIL, payload: { errors } };
};

export const getSubcontractorsSuccess = (subcontractors) => {
  return { type: GET_SUBCONTRACTORS_SUCCESS, payload: { ...subcontractors } };
};

export const getSubcontractorsAction = (id, query) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: GET_SUBCONTRACTORS_FETCHING });

    getSubcontractor(id, query, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          dispatch(getSubcontractorsSuccess(data.data));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(getSubcontractorsFail(data.errors));
          } else {
            dispatch(getSubcontractorsFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(getSubcontractorsFail(e));
        } else {
          dispatch(getSubcontractorsFail([e]));
        }
      });
  };
};

export const editSubcontractorAction = (
  finance_id,
  id_subcontrator,
  financial_report_id,
  user_owner_id,
  title,
  amount,
  toggle,
) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODAL_SUBCONTRACTORS_FETCHING });

    putSubcontractor(
      id_subcontrator,
      financial_report_id,
      user_owner_id,
      title,
      amount,
      state.auth.user.accessToken
    )
      .then(({ data }) => {
        if (data.success) {
          toggle();
          dispatch(getSubcontractorsAction(finance_id));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(modalSubcontractorsFail(data.errors));
          } else {
            dispatch(modalSubcontractorsFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(modalSubcontractorsFail(e));
        } else {
          dispatch(modalSubcontractorsFail([e]));
        }
      });
  };
};

export const deleteSubcontractorAction = (
  finance_id,
  subcontractor_id,
  toggle,
) => {
  return (dispatch, getState) => {
    const state = getState();

    deleteSubcontractor(subcontractor_id, state.auth.user.accessToken)
      .then(({ data }) => {
        toggle();

        if (data.success) {
          dispatch(getSubcontractorsAction(finance_id));
          displayNotification('Deleted', 'success');
        } else {
          if (Array.isArray(data.errors)) {
            data.errors.forEach((error) => {
              displayNotification(error.message, 'danger');
            });
          } else {
            displayNotification(data.errors.message, 'danger');
          }
        }
      })
      .catch((e) => {
        toggle();
        displayNotification(e.message, 'danger');
      });
  };
};

export const modalSubcontractorsFail = (errors) => {
  return { type: MODAL_SUBCONTRACTORS_FAIL, payload: { errors } };
};

export const addSubcontractorsAction = (
  financial_report_id,
  params,
  toggle,
) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODAL_SUBCONTRACTORS_FETCHING });

    postSubcontractor(params, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          toggle();
          dispatch(getSubcontractorsAction(financial_report_id));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(modalSubcontractorsFail(data.errors));
          } else {
            dispatch(modalSubcontractorsFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(modalSubcontractorsFail(e));
        } else {
          dispatch(modalSubcontractorsFail([e]));
        }
      });
  };
};

export const getSubcontractorUsers = (search_query, isLoaded) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!isLoaded) {
      dispatch({ type: GET_SUBCONTRACTORS_USERS_FETCHING });
    }

    getUsers({
      'roles[0]': roles.ADMIN,
      'roles[1]': roles.SALES,
      search_query,
    }, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: GET_SUBCONTRACTORS_USERS_SUCCESS,
            payload: {
              users: data.data.users,
              totalUsers: data.data.total_records,
            },
          });
        } else {
          dispatch({
            type: GET_SUBCONTRACTORS_USERS_FAIL,
            payload: {
              error: data.errors[0].message,
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: GET_SUBCONTRACTORS_USERS_FAIL,
          payload: {
            error: e?.message,
          },
        });
      });
  };
};
