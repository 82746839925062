import {getMyInvoices} from "../../utils/api";

export const GET_MY_INVOICES_FETCHING = 'GET_MY_INVOICES_FETCHING';
export const GET_MY_INVOICES_FAIL = 'GET_MY_INVOICES_FAIL';
export const GET_MY_INVOICES_SUCCESS = 'GET_MY_INVOICES_SUCCESS';

export const getMyInvoicesFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_MY_INVOICES_FETCHING});
    getMyInvoices(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getMyInvoicesSuccess(data.data));
      })
      .catch(e => {
        dispatch(getMyInvoicesFail(e));
      })
  }
};

export const getMyInvoicesFail = (e) => {
  return {type: GET_MY_INVOICES_FAIL, payload: {message: e?.message}}
};

export const getMyInvoicesSuccess = (myInvoices) => {
  return {type: GET_MY_INVOICES_SUCCESS, payload: {myInvoices}}
};