import {getBusinessPaymentMethod} from "../../utils/api";

export const GET_BUSINESS_PAYMENT_METHOD_EDIT_FETCHING = 'GET_BUSINESS_PAYMENT_METHOD_EDIT_FETCHING';
export const GET_BUSINESS_PAYMENT_METHOD_EDIT_FAIL = 'GET_BUSINESS_PAYMENT_METHOD_EDIT_FAIL';
export const GET_BUSINESS_PAYMENT_METHOD_EDIT_SUCCESS = 'GET_BUSINESS_PAYMENT_METHOD_EDIT_SUCCESS';

export const getBusinessPaymentMethodEditAction = (id, method_id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_BUSINESS_PAYMENT_METHOD_EDIT_FETCHING});
    getBusinessPaymentMethod(id, method_id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getBusinessPaymentMethodEditSuccess(data.data));
      })
      .catch(e => {
        dispatch(getBusinessPaymentMethodEditFail(e));
      })
  }
};
export const getBusinessPaymentMethodEditFail = (e) => {
  return {type: GET_BUSINESS_PAYMENT_METHOD_EDIT_FAIL, payload: {message: e?.message}}
};
export const getBusinessPaymentMethodEditSuccess = (paymentMethod) => {
  return {type: GET_BUSINESS_PAYMENT_METHOD_EDIT_SUCCESS, payload: {paymentMethod}}
};