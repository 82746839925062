const validate = (values) => {
  const errors = {};

  if (values.date_from > values.date_to) {
    errors.date_from = 'Date From cannot be greater than Date To';
  }

  if (!values.date_from) {
    errors.date_from = 'Date From field shouldn’t be empty';
  }

  if (!values.date_to) {
    errors.date_to = 'Date To field shouldn’t be empty';
  }

  if (!values.amount) {
    errors.amount = 'Amount field shouldn’t be empty';
  }

  if (!values.user_id) {
    errors.user_id = 'Please select the option';
  }

  return errors;
};

export default validate;
