/* eslint-disable */
const validate = (values) => {
  const errors = {};
  const regex = /^\w+$/;

  if (!values.branch) {
    errors.branch = 'Key field shouldn’t be empty';
  }

  if (!values.roles) {
    errors.roles = 'Value field shouldn’t be empty';
  }

  if (!regex.test(values.branch)) {
    errors.branch = 'Value can include just letters, numbers and underscore';
  }

  return errors;
};

export default validate;
