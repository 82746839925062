import {
  GET_MY_SALARY_HISTORY_FAIL,
  GET_MY_SALARY_HISTORY_FETCHING,
  GET_MY_SALARY_HISTORY_SUCCESS,
  GET_MY_SALARY_USERS_SUCCESS,
} from '../actions/mySalaryHistoryAction';

const initialState = {
  mySalary: [],
  loading: false,
  errors: '',
  totalRecords: 0,
  users: [],
  totalUsers: null,
};

const mySalaryHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_SALARY_HISTORY_FETCHING:
      return { ...state, loading: true };
    case GET_MY_SALARY_HISTORY_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_MY_SALARY_HISTORY_SUCCESS:
      return {
        ...state,
        mySalary: action.payload.salary_history,
        totalRecords: Number(action.payload.total_records),
        loading: false,
        errors: '',
      };
    case GET_MY_SALARY_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        totalUsers: state.totalUsers ?? action.payload.total_records,
      };
    default:
      return state;
  }
};

export default mySalaryHistory;
