import {
  GET_USERS_FAIL,
  GET_USERS_FETCHING,
  GET_USERS_SUCCESS
} from '../actions/usersActions';

const initialState = {
  users: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_FETCHING:
      return { ...state, loading: true };
    case GET_USERS_FAIL:
      return { ...state, loading: false, errors: action.payload.errors[0].message };
    case GET_USERS_SUCCESS:
      return { ...state, users: action.payload.data.users, totalRecords: Number(action.payload.data.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default usersReducer;
