import moment from 'moment';
import { useMemo } from 'react';

export const TableConfig = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        sortType: 'basic',
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: 'Jira',
        accessor: 'jira',
        sortType: 'basic',
      },
      {
        Header: 'Logged Hours',
        accessor: 'total_logged',
        sortType: (rowA, rowB, id, desc) => {
          return compareNumbers(rowA.values[id], rowB.values[id], desc);
        },
      },
      {
        Header: 'Paid Hours',
        accessor: 'total_paid',
        sortType: (rowA, rowB, id, desc) => {
          return compareNumbers(rowA.values[id], rowB.values[id], desc);
        },
      },
      {
        Header: 'Approved Hours',
        accessor: 'total_approved',
        sortType: (rowA, rowB, id, desc) => {
          return compareNumbers(rowA.values[id], rowB.values[id], desc);
        },
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        sortType: (rowA, rowB, id, desc) => {
          let a = rowA.values[id].replace(/\./g, '');
          a = a.replace(/,/g, '.');
          a = a.replace(/[^\d.-]/g, '');

          let b = rowB.values[id].replace(/\./g, '');
          b = b.replace(/,/g, '.');
          b = b.replace(/[^\d.-]/g, '');

          return compareNumbers(a, b, desc);
        },
      },
      {
        Header: 'Date Start',
        accessor: 'date_start',
        sortType: (rowA, rowB, id, desc) => {
          return compareDates(rowA, rowB, id, desc);
        },
      },
      {
        Header: 'Date End',
        accessor: 'date_end',
        sortType: (rowA, rowB, id, desc) => {
          return compareDates(rowA, rowB, id, desc);
        },
      },
      {
        Header: 'Developers',
        accessor: 'developers',
        disableSortBy: true,
      },
      {
        Header: 'Clients',
        accessor: 'clients',
        sortType: 'basic',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  return {
    columns,
  };
};

const compareNumbers = (rowA, rowB, desc) => {
  let a = Number.parseFloat(rowA);
  let b = Number.parseFloat(rowB);
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 1;
};

const compareDates = (rowA, rowB, id, desc) => {
  let a = moment(rowA.values[id], 'DD/MM/YYYY').unix();
  let b = moment(rowB.values[id], 'DD/MM/YYYY').unix();

  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }

  if (a > b) return 1;
  if (a < b) return -1;
  return 1;
};
