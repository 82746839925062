import {
  GET_BUSINESS_PAYMENT_METHODS_FAIL,
  GET_BUSINESS_PAYMENT_METHODS_FETCHING,
  GET_BUSINESS_PAYMENT_METHODS_SUCCESS
} from '../actions/businessesPaymentMethodsActions';

const initialState = {
  paymentMethods: [],
  loading: false,
  errors: '',
  // totalRecords: 0,
};


const businessesPaymentMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_PAYMENT_METHODS_FETCHING:
      return { ...state, loading: true };
    case GET_BUSINESS_PAYMENT_METHODS_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_BUSINESS_PAYMENT_METHODS_SUCCESS:
      return { ...state,
        paymentMethods: action.payload.paymentMethods,
        // totalRecords: Number(action.payload.businesses.total_records),
        loading: false,
        errors: ''
      };
    default:
      return state;
  }
};

export default businessesPaymentMethodsReducer;
