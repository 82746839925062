import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import validate from './validate';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from "lodash";


const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div style={{width: "100%"}}>
    <div style={{width: "100%"}}>
      <input {...input} placeholder={label} type={type} />
      {touched && ((error && <span style={{color: 'red'}}>*{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)

export const FieldDatePicker = ({ input, maxDate }) => (
  <DatePicker
    selected={input.value ? new Date(input.value) : null}
    onChange={(date) => {
     input.onChange(moment(date).format('YYYY-MM-DD'));
    }}
    maxDate={maxDate}
  />
);

const shouldDisableButton = (formValues, initialValues) => {
  return _.isEqual(formValues, initialValues);
};

let MyBankAccountForm = ({handleSubmit, formValues, initialValues }) => {
  const isButtonDisabled = shouldDisableButton(formValues, initialValues);

  return(
    <form onSubmit={handleSubmit} className="my-bank-account-form">
      <div className="form_Settings">
        <div className="form_Settings-Left">
          <div className="form__form-group">
            <span className="form__form-group-label">Bank Bic</span>
            <div className="form__form-group-field">
              <Field
                name="bank_bic"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Bank Account</span>
            <div className="form__form-group-field">
              <Field
                name="bank_account"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Bank Country</span>
            <div className="form__form-group-field">
              <Field
                name="bank_country"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Registration Date</span>
            <div className="form__form-group-field">
              <Field
                name="registration_date"
                component={FieldDatePicker}
                type="date"
                className="input-without-border-radius"
                required
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
        <div className="form_Settings-Right">
          <div className="form__form-group">
            <span className="form__form-group-label">Bank Address</span>
            <div className="form__form-group-field">
              <Field
                name="bank_address"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Bank City</span>
            <div className="form__form-group-field">
              <Field
                name="bank_city"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Bank Postcode</span>
            <div className="form__form-group-field">
              <Field
                name="bank_postcode"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Registration №</span>
            <div className="form__form-group-field">
              <Field
                name="registration_no"
                component={renderField}
                type="text"
                className="input-without-border-radius"
                required
              />
            </div>
          </div>
          <div className="form_Settings-button">
            <Button type="submit" color="primary" className="account__btn" disabled={isButtonDisabled}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

MyBankAccountForm = reduxForm({
  form: "MyBankAccountForm",
  validate
})(MyBankAccountForm);

MyBankAccountForm = connect(
  state => ({
  initialValues: state.bankAccount.bankAccount,
  formValues: getFormValues("MyBankAccountForm")(state),
  enableReinitialize: true,
}))(MyBankAccountForm)


export default MyBankAccountForm;