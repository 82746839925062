import { getMySalaryHistory, getUsers } from '../../utils/api';

export const GET_MY_SALARY_HISTORY_FETCHING = 'GET_MY_SALARY_HISTORY_FETCHING';
export const GET_MY_SALARY_HISTORY_FAIL = 'GET_MY_SALARY_HISTORY_FAIL';
export const GET_MY_SALARY_HISTORY_SUCCESS = 'GET_MY_SALARY_HISTORY_SUCCESS';
export const GET_MY_SALARY_USERS_FETCHING = 'GET_MY_SALARY_USERS_FETCHING';
export const GET_MY_SALARY_USERS_SUCCESS = 'GET_MY_SALARY_USERS_SUCCESS';

export const getMySalaryHistoryFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: GET_MY_SALARY_HISTORY_FETCHING });
    getMySalaryHistory(query, state.auth.user.accessToken)
      .then(({ data }) => {
        dispatch(getMySalaryHistorySuccess(data.data));
      })
      .catch((e) => {
        dispatch(getMySalaryHistoryFail(e));
      });
  };
};

export const getMySalaryUsersFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: GET_MY_SALARY_USERS_FETCHING });
    getUsers(query, state.auth.user.accessToken)
      .then(({ data }) => {
        if (!data.success) {
          throw data.errors[0];
        }
        dispatch(getMySalaryUsersSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getMySalaryHistoryFail(e));
      });
  };
};

export const getMySalaryHistoryFail = (e) => {
  return { type: GET_MY_SALARY_HISTORY_FAIL, payload: { message: e?.message } };
};

export const getMySalaryHistorySuccess = (data) => {
  return { type: GET_MY_SALARY_HISTORY_SUCCESS, payload: data };
};

export const getMySalaryUsersSuccess = (data) => {
  return { type: GET_MY_SALARY_USERS_SUCCESS, payload: data };
};
