import React from 'react';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../shared/components/form/Select';
import { Button, ButtonToolbar, Col } from 'reactstrap';

const MyInvoicesSelectForm = ({handleSubmit, users, setSelectedUserId}) => {
  return (
    <Col sm={12}>
      <form className="form form--myInvoice" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Select a User</span>
          <div className="form__form-group-field">
            <Field
              name="select"
              component={renderSelectField}
              id="users"
              value={"All"}
              options={[{id: '', first_name: "All", last_name: ""}, ...users || []]?.map(u => {
                return {
                  value: u.id,
                  label: `${u.first_name} ${u.last_name}`
                }
              })}
              onChange={(e) => setSelectedUserId(e.value)}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar w-100 justify-content-end">
          <Button color="primary" type="submit">Submit</Button>
        </ButtonToolbar>
      </form>
    </Col>
  );
};

export default reduxForm({
  form: 'myInvoicesSelectForm',
})(MyInvoicesSelectForm);