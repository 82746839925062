const validate = (values) => {
  const errors = {};

  if(!values.projects) {
    errors.projects = 'Please select the option';
  }

  if(!values.developers) {
    errors.developers = 'Please select the option';
  }

  if(!values.amount) {
    errors.amount = 'Amount field shouldn’t be empty'
  } else if(!/^[0-9.,]*$/.test(values.amount)) {
    errors.amount = 'Invalid data type';
  }

  if(!values.from_date) {
    errors.from_date = 'From Date field shouldn’t be empty';
  } else if(+values.from_date > +values.to_date) {
    errors.from_date = 'From date cannot be greater than To date';
  } else if(!/^[0-9]*$/.test(values.from_date)) {
     errors.from_date = 'Invalid data type';
  }

  if(!values.to_date) {
    errors.to_date = 'To Date field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(values.to_date)) {
     errors.to_date = 'Invalid data type';
  }

  if(!values.description) {
    errors.description = 'Description field shouldn’t be empty';
  }

  return errors;
};

export default validate;
