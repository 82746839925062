import {
  GET_FINANCIAL_REPORT_INCOME_FAIL,
  GET_FINANCIAL_REPORT_INCOME_FETCHING,
  GET_FINANCIAL_REPORT_INCOME_SUCCESS,
} from '../actions/financialReportIncomesActions';


const initialState = {
  financialReportIncome: [],
  loading: false,
  errors: '',
  totalRecords: 0
};

const financialReportIncomesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCIAL_REPORT_INCOME_FETCHING:
      return { ...state, loading: true };
    case GET_FINANCIAL_REPORT_INCOME_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_FINANCIAL_REPORT_INCOME_SUCCESS:
      return { ...state, financialReportIncome: action.payload.financialReportIncome.financial_incomes, totalRecords: Number(action.payload.financialReportIncome.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default financialReportIncomesReducer;
