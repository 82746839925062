import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_FETCHING,
  GET_SETTINGS_SUCCESS
} from '../actions/settingsActions';

const initialState = {
  settings: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_FETCHING:
      return { ...state, loading: true };
    case GET_SETTINGS_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_SETTINGS_SUCCESS:
      return { ...state, settings: [...action.payload?.settings.settings], totalRecords: Number(action.payload?.settings?.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default settingsReducer;
