import {getBusinesses} from "../../utils/api";

export const GET_BUSINESSES_FETCHING = 'GET_BUSINESSES_FETCHING';
export const GET_BUSINESSES_FAIL = 'GET_BUSINESSES_FAIL';
export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS';

export const getBusinessesFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_BUSINESSES_FETCHING});
    getBusinesses(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getBusinessesSuccess(data.data));
      })
      .catch(e => {
        dispatch(getBusinessesFail(e));
      })
  }
};

export const getBusinessesFail = (e) => {
  return {type: GET_BUSINESSES_FAIL, payload: {message: e?.message}}
};

export const getBusinessesSuccess = (businesses) => {
  return {type: GET_BUSINESSES_SUCCESS, payload: {businesses}}
};