import {
  GET_FINANCIAL_REPORT_EDIT_FAIL,
  GET_FINANCIAL_REPORT_EDIT_FETCHING,
  GET_FINANCIAL_REPORT_EDIT_SUCCESS,
  ADD_FINANCIAL_REPORT_ROW,
  REMOVE_FINANCIAL_REPORT_ROW,
  EDIT_FINANCIAL_REPORT_FIELD,
  SET_VALIDATION_ERRORS,
  SET_FINANCIAL_REPORT_FIRST_THREE_FIELDS,
} from '../actions/financialReportEditActions';
import _ from 'lodash';

const initialState = {
  expense_constant: [],
  investments: [],
  spent_corp_events: [],
  validationErrors: {},

  num_of_working_days: '',
  currency: '',
  expense_salary: '',

  loading: false,
  errors: ''
};

const financialReportEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCIAL_REPORT_EDIT_FETCHING:
      return { ...state, loading: true };
    case GET_FINANCIAL_REPORT_EDIT_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_FINANCIAL_REPORT_EDIT_SUCCESS:
      return { ...state, ...action.payload.financialReportEdit, loading: false, errors: '' };
    case ADD_FINANCIAL_REPORT_ROW: {
      const newState = {...state};
      newState[action.payload?.type] = [...state[action.payload?.type], {amount: '', description: '', date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}];
      return newState;
    }
    case EDIT_FINANCIAL_REPORT_FIELD: {
      const newState = {...state};
      _.set(newState, `${action.payload?.type}.${action.payload?.index}.${action.payload?.field}`, action.payload?.value);
      return newState;
    }
    case REMOVE_FINANCIAL_REPORT_ROW: {
      const newState = {...state};
      newState[action.payload?.type] = _.filter(state[action.payload?.type], (r, index) => index !== action.payload?.index);
      return newState;
    }
    case SET_VALIDATION_ERRORS: {
      return {...state, validationErrors: action.payload}
    }
    case SET_FINANCIAL_REPORT_FIRST_THREE_FIELDS: {
      return {...state, [action.payload.type]: action.payload.value}
    }
    default:
      return state;
  }
};

export default financialReportEditReducer;
