import React, {useEffect, useState} from "react";
import {Col, Container, Modal, Row} from "reactstrap";
import { useTranslation } from 'react-i18next';
import DataReactTable from "./../../Tables/DataTable/components/DataReactTable";
import {useDispatch, useSelector} from "react-redux";
import {columns} from "./SalaryListTableColumns.js";
import {getSalaryListAction, deleteSalaryList} from "../../../redux/actions/salaryListActions";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import ConfirmModal from "../Projects/components/Environments/Variables/ConfirmModal/ConfirmModal";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Alert from "../../../shared/components/Alert";
import _ from 'lodash';
import { getSalaryReportsAction } from '../../../redux/actions/salaryReportsActions';
import ReactTooltip from 'react-tooltip';
import roles from "../../../config/roles.js";


const SalaryReportsList = () => {
    const { t } = useTranslation('common');
    const {salary_reports, ...salaryReportsProps} = useSelector(state => state.salary_reports);
    const {salary_list, errors, totalRecords} = useSelector(state => state.salary_list);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [successConfirmModal, setSuccessConfirmModal] = useState(false);
    const [successResultModal, setSuccessResultModal] = useState(false);
    const [idForDelete, assignidForDelete] = useState(0);
    const [nameForDelete, assignNameForDelete] = useState("");
    const {id} = useParams();
    const authUser = useSelector(state => state.auth.user);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '', user_id: '' });
    const [querySalary] = useState({start: 0, limit: 10, user_id: authUser.userId, id: id});

    useEffect(() => {
        dispatch(getSalaryReportsAction(querySalary));
    },  [dispatch, querySalary]);

    useEffect(() => {
        if(salaryReportsProps.loading || _.isEmpty(salary_reports)) return;

        if(!salary_reports[0]?.is_locked) {
            dispatch(getSalaryListAction(id, query));
        } else {
            navigate("/dashboard/salary-reports")
        }
    }, [salaryReportsProps.loading, query, id, dispatch, navigate, salary_reports])

    useEffect(() => {
        setQuery((prevState) => {
            return {
                ...prevState,
                start: (currentPage - 1) * pageLimit,
                limit: pageLimit,
            };
        });
    }, [currentPage, pageLimit]);

    const deleteList = ( id, name) => {
        assignidForDelete(id);
        assignNameForDelete(name);
        setSuccessConfirmModal(true);
    }
    const handleOk = () => {
        setSuccessConfirmModal(false);
        if(authUser.role === roles.ADMIN || authUser.role === roles.FIN) {
            dispatch(deleteSalaryList(id, idForDelete, setSuccessResultModal));
        }
    };
    const handleCancel = () =>{
        setSuccessConfirmModal(false);
    };
    const toggleCloseModal = () => {
        setSuccessResultModal(!successResultModal);
    }

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangePageSize = (newSize) => {
        setCurrentPage(1);
        setPageLimit(newSize);
    };

    const getSearchValue = _.debounce((value) => {
        setQuery({...query, search_query: value});
    }, 600)

    return (
        <Container className="dashboard">
            <Row>
                <Col sm={12}>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success" onClick={() => navigate(`/dashboard/salary-reports/${id}/edit/add-list`)}>Create Salary List</button>
                    </div>
                </Col>
                <Col md={12}>
                    <DataReactTable
                      tableName={"Salary reports"}
                      sortTable={false}
                      search={true}
                      searchValue={getSearchValue}
                      pagination={{
                          onChangePageSize: handleChangePageSize,
                          onChangePage: handleChangePage,
                          currentPage,
                          totalRecords,
                          pageLimit,
                      }}
                      classList={"classList"}
                      emptyStateMessage={t('empty_states.reports')}
                      reactTableData={{tableHeaderData: columns, tableRowsData: salary_list?.map((c) => {
                              return {
                                  ...c,
                                  id: `${c.id}`,
                                  user: `${c.user.first_name} ${c.user.last_name}`,
                                  salary: `UAH ${c.salary ? c.salary : 0}`,
                                  official_salary: `UAH ${c.official_salary ? c.official_salary : 0}`,
                                  bonuses: `$ ${c.bonuses ? c.bonuses : 0}`,
                                  project_bonuses: `$ ${c.project_bonuses ? c.project_bonuses : 0}`,
                                  overtime_value: `$ ${c.overtime_value ? _.round(c.overtime_value, 2) : 0}`,
                                  other_surcharges: `$ ${c.other_surcharges ? c.other_surcharges : 0}`,
                                  hospital_value: `$ ${c.hospital_value ? _.round(c.hospital_value, 2) : 0}` ,
                                  day_off: `$ ${!isFinite(Math.round(c.salary / c.worked_days * c.day_off * 100) / 100)  ? 0 : Math.round(c.salary / c.worked_days * c.day_off * 100) / 100}/${c.day_off ? c.day_off : 0}`,
                                  currency_rate: `UAH ${c.currency_rate ? c.currency_rate : 0}`,
                                  actions:
                                    <div className={"tableActions"}>
                                        <NavLink to={`/dashboard/salary-reports/${id}/edit/${c.id}/edit-list`}>
                                            <ReactTooltip id={`editIcon-${c.id}`} type="dark">
                                              <span style={{ color: 'white' }}>
                                                {t("reactTooltips.edit")}
                                              </span>
                                            </ReactTooltip>
                                            <button style={{border: 0, background: "transparent"}} data-tip data-for={`editIcon-${c.id}`}>
                                                <PencilIcon color="#b1c3c8" size={18} />
                                            </button>
                                        </NavLink>

                                        <ReactTooltip id={`deleteIcon-${c.id}`} type="dark">
                                          <span style={{ color: 'white' }}>
                                            {t("reactTooltips.deleteSalaryListId")} = {c.id}
                                          </span>
                                        </ReactTooltip>
                                        <button
                                          style={{border: 0, background: "transparent"}}
                                          onClick={() => deleteList(c.id, c.user.last_name)}
                                          data-tip
                                          data-for={`deleteIcon-${c.id}`}
                                        >
                                            <DeleteIcon color="#b1c3c8" size={18} />
                                        </button>
                                    </div>
                              }
                          })}}
                    />
                    {
                        errors ? (<div>{errors}</div>) : null
                    }
                </Col>
                <ConfirmModal message={`Are you sure you want to delete the list of ${nameForDelete}?`}
                     color="danger"
                     modal={successConfirmModal}
                     toggle={handleCancel}
                     onNext={handleOk}
                />
                <Modal
                    isOpen={successResultModal}
                    toggle={toggleCloseModal}
                    modalClassName="ltr-support"
                    className="modal-dialog  modal-dialog--primary theme-light modal-add-new-variable"
                >
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            type="button"
                            onClick={toggleCloseModal}
                        />
                    </div>
                    <div className="modal__body">
                        <Alert
                            color="success"
                            className="alert--bordered buttonHide"
                            icon
                        >
                            <p>
                                <span className="bold-text">Salary list is deleted!</span>
                            </p>
                        </Alert>
                    </div>
                </Modal>
            </Row>
        </Container>
    );
};

export default SalaryReportsList;