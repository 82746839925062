import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';


const CustomAlert = ({ color, className, children, }) => {
  return (
    <Alert color={color} className={className}>
      <div className="alert__icon"><ThumbUpOutlineIcon/></div>
      <div className="alert__content">{children}</div>
    </Alert>
  );
};

export default CustomAlert;

CustomAlert.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

CustomAlert.defaultProps = {
  color: '',
  icon: false,
  className: '',
};
