export const columns = [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'From Date',
    accessor: 'from_date',
  },
  {
    Header: 'To Date',
    accessor: 'to_date',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Project',
    accessor: 'project.name',
  },
  {
    Header: 'Developer',
    accessor: 'developer_user.name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Added By',
    accessor: 'added_by_user.name',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
  },
];