import {getVacationsHistory} from "../../utils/api";

export const GET_VACATIONS_HISTORY_FETCHING = 'GET_VACATIONS_HISTORY_FETCHING';
export const GET_VACATIONS_HISTORY_FAIL = 'GET_VACATIONS_HISTORY_FAIL';
export const GET_VACATIONS_HISTORY_SUCCESS = 'GET_VACATIONS_HISTORY_SUCCESS';

export const getVacationsHistoryFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_VACATIONS_HISTORY_FETCHING});
    getVacationsHistory(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getVacationsHistorySuccess(data.data));
      })
      .catch(e => {
        dispatch(getVacationsHistoryFail(e));
      })
  }
};

export const getVacationsHistoryFail = (e) => {
  return {type: GET_VACATIONS_HISTORY_FAIL, payload: {message: e?.message}}
};

export const getVacationsHistorySuccess = (vacationsHistory) => {
  return {type: GET_VACATIONS_HISTORY_SUCCESS, payload: {vacationsHistory}}
};