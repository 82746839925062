import React from 'react';
import { Field } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';
import { useTranslation } from 'react-i18next';

const CommentEdit = ({comment, setComment, setSelectedFile, selectedFile, handleSubmitEvent, mesErrors}) => {
  const { t } = useTranslation('common');
  return (
    <div>
        <form className="form form_comment_edit" onSubmit={handleSubmitEvent} style={{flexWrap: "nowrap", padding: "0 10px", maxWidth: "700px", margin: "0 auto"}}>
          <div className="form__form-group">
            <div className='form__form-group-field flex-column'>
              <textarea value={comment}
                        rows='5'
                        style={{ height: 'auto', minHeight: 'auto', background: 'white' }}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder={t('leads.single_summary.placeholder_textarea')}
              />
              {
                mesErrors && (
                  <div className='form__form-group-error'>
                    {mesErrors?.map((r) => r.message)}
                  </div>
                )
              }
            </div>
          </div>
          <div style={{ paddingLeft: '25px', whiteSpace: 'nowrap' }}>
          <div className="form__form-group" style={{marginBottom: 0}}>
              <div className="form__form-group" style={{marginBottom: 0, maxWidth: "126px"}}>
                <div className="form__form-group-field">
                  <Field
                    name="comment_file"
                    value={selectedFile}
                    onChange={(e) => setSelectedFile(e)}
                    component={renderFileInputField}
                  />
                </div>
              </div>
            </div>
            <div>
              <button className="btn btn-primary mt-1" style={{
                border: 0,
                background: "#29b6f6",
                padding: "8px 12px",
                color: "white",
                maxWidth: "126px"
              }}>{t('leads.post_form.submit_button')}</button>
            </div>
          </div>
        </form>
    </div>
  );
};

export default CommentEdit;