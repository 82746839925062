import { getUsers } from '../../utils/api';

export const GET_USERS_FETCHING = 'GET_USERS_FETCHING';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const getUsersFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_USERS_FETCHING});
    getUsers(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getUsersSuccess(data));
      })
      .catch(e => {
        dispatch(getUsersFail(e));
      })
  }
};

export const getUsersFail = (e) => {
  return {type: GET_USERS_FAIL, payload: {message: e?.message}}
};

export const getUsersSuccess = (data) => {
  return {type: GET_USERS_SUCCESS, payload: data}
};