import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from 'reactstrap';
import AccountClockIcon from "mdi-react/AccountClockIcon";
import AccountCheckIcon from "mdi-react/AccountCheckIcon";
import AccountStarIcon from "mdi-react/AccountStarIcon";
import AccountCancelIcon from "mdi-react/AccountCancelIcon";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import AccountDetailsIcon from "mdi-react/AccountDetailsIcon";
import CommentIcon from "mdi-react/CommentIcon";

const TimeLineIcon = ({ type }) => {
  let Icon;

  switch (type) {
    case 'work':
      Icon = <span className="lnr lnr-briefcase" />;
      break;
    case 'video':
      Icon = <span className="lnr lnr-film-play" />;
      break;
    case 'file':
      Icon = <span className="lnr lnr-file-add" />;
      break;
    case 'new_lead':
      Icon = <AccountPlusIcon color="#fff" size={22} />;
      break;
    case 'qualified':
      Icon = <AccountStarIcon color="#fff" size={22} />;
      break;
    case 'prospected':
      Icon = <AccountClockIcon color="#fff" size={22} />;
      break;
    case 'killed':
      Icon = <AccountCancelIcon color="#fff" size={22} />;
      break;
    case 'contracted':
      Icon = <AccountCheckIcon color="#fff" size={22} />;
      break;
    case 'quoted':
      Icon = <AccountDetailsIcon color="#fff" size={22} />;
      break;
    case 'comment':
      Icon = <CommentIcon color="#fff" size={22} />;
      break;
    default:
      break;
  }

  return (
    <div className={`timeline__icon ${type}`}>
      {Icon}
    </div>
  );
};

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: '',
};

const TimeLineItem = ({
                        type, img, title, date, children,
                      }) => (
  <div className="timeline__item">
    {img
      ? <div className="timeline__icon"><img src={img} alt="img" /></div>
      : <TimeLineIcon type={type} />
    }
    <CardBody className="timeline__content">
      <h5 className="timeline__title">{title}</h5>
      <h4 className="subhead timeline__date">{date}</h4>
      {children}
    </CardBody>
  </div>
);

TimeLineItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

TimeLineItem.defaultProps = {
  type: '',
  img: '',
  title: '',
  date: '',
};

export default TimeLineItem;
