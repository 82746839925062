import {getAllSales} from "../../utils/api";

export const GET_ALL_SALES_FETCHING = 'GET_ALL_SALES_FETCHING';
export const GET_ALL_SALES_FAIL = 'GET_ALL_SALES_FAIL';
export const GET_ALL_SALES_SUCCESS = 'GET_ALL_SALES_SUCCESS';

export const getAllSalesFetchingAction = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type: GET_ALL_SALES_FETCHING});
        getAllSales(state.auth.user.accessToken)
            .then(({data}) => {
                dispatch(getAllSalesSuccess(data.data.users));
            })
            .catch(e => {
                dispatch(getAllSalesFail(e));
            })
    }
};

export const getAllSalesFail = (e) => {
    return {type: GET_ALL_SALES_FAIL, payload: {message: e?.message}}
};

export const getAllSalesSuccess = (sales) => {
    return {type: GET_ALL_SALES_SUCCESS, payload: {sales}}
};