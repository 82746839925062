import React from 'react';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../../shared/components/form/Select';
import _ from 'lodash';


let timerId;

let SelectInputRedux = ({projects, queryProjects, setQueryProjects, totalRecordsProjects}) => {
  return (
    <form className="p-0">
      <div className='form__form-group' style={{marginBottom: "7px"}}>
        <div className='form__form-group-field'>
          <Field
            name="editProject"
            component={renderSelectField}
            options={_.sortBy(projects, "name")?.map(c => {
              return {
                value: c.id,
                label: c.name
              }
            })}
            onInputChange={(value) => {
              if (totalRecordsProjects <= 10) return;

              clearTimeout(timerId);

              timerId = setTimeout(() => {
                if (!value) return setQueryProjects({ ...queryProjects, search_query: '' });

                setQueryProjects({ ...queryProjects, search_query: value });
              }, 600)
            }}
          />
        </div>
      </div>
    </form>
  );
};

SelectInputRedux = reduxForm({
  form: 'selectInputRedux',
  enableReinitialize : true
})(SelectInputRedux);

export default SelectInputRedux;