import React from 'react';


const UserPhoto = ({userPhoto, setShowUserPhoto, handleDeletePhoto}) => {
  return (
    <div className="d-flex flex-column justify-content-center user-photo-container">
      <img src={userPhoto || process.env.PUBLIC_URL + '/img/user-avatar.svg'} alt={'Avatar'} />
      <div className="d-flex justify-content-between">
        <button className="btn btn-danger" onClick={() => handleDeletePhoto()}>Delete Photo</button>
        <button className="btn btn-primary" onClick={() => setShowUserPhoto(false)}>Change Photo</button>
      </div>
    </div>
  );
};

export default UserPhoto;