const validate = (values) => {
  const errors = {};

  if (!values.selectProject) {
    errors.selectProject = 'Please select the option';
  }

  if (!values.date_report) {
    errors.date_report = 'Date field shouldn’t be empty';
  }

  if (!values.task) {
    errors.task = 'Task field shouldn’t be empty';
  }

  if (!values.hours) {
    errors.hours = 'Hours field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(values.hours)) {
    errors.hours = 'Invalid data type';
  } else if(values.hours > 12) {
    errors.hours = 'Maximum total hours is 12'
  }

  return errors;
};

export default validate;

