import { getLead } from '../../utils/api';

export const GET_LEAD_FETCHING = "GET_LEAD_FETCHING";
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
export const GET_LEAD_FAIL = "GET_LEAD_FAIL";

export const getLeadRequest = (id) => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({ type: GET_LEAD_FETCHING });

        getLead(id, state.auth.user.accessToken)
            .then(({ data }) => {
                dispatch(getLeadSuccess(data.data));
            })
            .catch(e => {
                dispatch(getLeadFail(e));
            })
    };
};

export const getLeadFail = (e) => {
    return { type: GET_LEAD_FAIL, payload: { message: e?.message } }
};

export const getLeadSuccess = (data) => {
    return { type: GET_LEAD_SUCCESS, payload: { result: data } }
};