import {
    GET_ALL_SALES_FAIL,
    GET_ALL_SALES_FETCHING,
    GET_ALL_SALES_SUCCESS
} from '../actions/salesActions';

const initialState = {
    sales: [],
    loading: false,
    errors: ''
};


const mySalaryHistory = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SALES_FETCHING:
            return { ...state, loading: true };
        case GET_ALL_SALES_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_ALL_SALES_SUCCESS:
            return { ...state, sales: action.payload.sales, loading: false, errors: '' };
        default:
            return state;
    }
};

export default mySalaryHistory;
