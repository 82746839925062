import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import { getBankAccountDataAction } from '../../../../redux/actions/bankAccountActions';
import Roles from '../../../../config/roles';
import Loading from '../../../../shared/components/Loading';
import MyBankAccountForm from './MyBankAccountForm';
import MyBankAccountModal from './MyBankAccountModal';
import { updateBankAccountData } from '../../../../utils/api';


const MyBankAccount = () => {
  const {loading, errors} = useSelector(state => state.auth.user);
  const {userId, role, accessToken} = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [successModal, setSuccessModal] = useState(false);

  const toggleCloseModal = () => {
    setSuccessModal(!successModal);
  }

  useEffect(() => {
    if (role === Roles.ADMIN) {
      dispatch(getBankAccountDataAction(userId));
    }
    dispatch(getBankAccountDataAction(userId));
  }, [refreshCounter, dispatch, role, userId]);

  const saveNewBankAccountData = (values) => {
    updateBankAccountData(values, userId, accessToken)
      .then(res => {
        if(!res.data.success) return;
        setSuccessModal(true);
        setRefreshCounter(refreshCounter + 1);
      })
  }

  return (
    <Container className="myBankAccount">
      <Row>
        <Col>
          {
            loading ? (<Loading/>) : (
              <div className="card">
                <div className="card-body">
                  <h5 className="card__title bold-text">My Bank Account</h5>
                  <MyBankAccountForm onSubmit={saveNewBankAccountData} />
                  <MyBankAccountModal toggleCloseModal={toggleCloseModal} successModal={successModal}/>
                </div>
              </div>
            )
          }
          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>
      </Row>
    </Container>
  )
}

MyBankAccount.propTypes = {
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
};

MyBankAccount.defaultProps = {
  errorMessage: '',
};

export default MyBankAccount;

