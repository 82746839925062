import {getProjects} from "../../utils/api";

export const GET_PROJECTS_FETCHING = 'GET_PROJECTS_FETCHING';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

export const getProjectsFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_PROJECTS_FETCHING});
    getProjects(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getProjectsSuccess(data.data));
      })
      .catch(e => {
        dispatch(getProjectsFail(e));
      })
  }
};

export const getProjectsFail = (e) => {
  return {type: GET_PROJECTS_FAIL, payload: {message: e?.message}}
};

export const getProjectsSuccess = (projects) => {
  return {type: GET_PROJECTS_SUCCESS, payload: {projects}}
};