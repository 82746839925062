import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import AddNewBusinessesForm from './AddNewBusinessesForm/AddNewBusinessesForm';
import { postBusinesses } from '../../../../../utils/api';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';


const AddNewBusinesses = () => {
  const navigate = useNavigate();
  const authUser = useSelector(state => state.auth.user);
  const [successAddedModal, setSuccessAddedModal] = useState(false);
  const [businessesId, setBusinessesId] = useState(null);
  const [failedAddedModal, setFailedAddedModal] = useState(false);
  const [messageAddedError, setMessageAddedError] = useState('');

  const handleSubmit = (value) => {
    postBusinesses(value, authUser.accessToken).then(res => {
      if(res.data.success) {
        setBusinessesId(res.data.data.business_id);
        setSuccessAddedModal(!successAddedModal);
      } else {
        setMessageAddedError(res.data.errors[0].message);
        setFailedAddedModal(!failedAddedModal);
      }
    })
  }

  return (
    <Container>
      <Row>
        <AddNewBusinessesForm onSubmit={handleSubmit} />
      </Row>

      <CustomModal
        successModal={successAddedModal}
        toggleCloseModal={() => {
          setSuccessAddedModal(!successAddedModal);
          navigate('/dashboard/businesses');
        }}
        textModal={`Business №${businessesId} was successfully added!`}
        color={"success"}
      />
      <CustomModal
        successModal={failedAddedModal}
        toggleCloseModal={() => setFailedAddedModal(!failedAddedModal)}
        textModal={messageAddedError}
        color={"danger"}
      />
    </Container>
  );
};

export default AddNewBusinesses;