import {getFinancialActivity} from "../../utils/api";

export const GET_FINANCIAL_ACTIVITY_FETCHING = 'GET_FINANCIAL_ACTIVITY_FETCHING';
export const GET_FINANCIAL_ACTIVITY_FAIL = 'GET_FINANCIAL_ACTIVITY_FAIL';
export const GET_FINANCIAL_ACTIVITY_SUCCESS = 'GET_FINANCIAL_ACTIVITY_SUCCESS';

export const getFinancialActivityFetchingAction = (userId, query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_FINANCIAL_ACTIVITY_FETCHING});
    getFinancialActivity(userId, query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getFinancialActivitySuccess(data.data));
      })
      .catch(e => {
        dispatch(getFinancialActivityFail(e));
      })
  }
};

export const getFinancialActivityFail = (e) => {
  return {type: GET_FINANCIAL_ACTIVITY_FAIL, payload: {message: e?.message}}
};

export const getFinancialActivitySuccess = (financialActivity) => {
  return {type: GET_FINANCIAL_ACTIVITY_SUCCESS, payload: {financialActivity}}
};