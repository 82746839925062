import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FinancialReportStepsOneAutomaticIncomeForm from './FinancialReportStepsOneAutomaticIncomeForm';


const FinancialReportAutomaticIncomes = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  return (
    <Container>
      <Row>
        <Col sm={12} className="d-flex justify-content-end">
          <button onClick={() => navigate(`/dashboard/financial-reports/${id}/incomes/income`)} className="btn btn-success">Manual Income</button>
        </Col>
        <Col className="col-12" md={8} lg={6}>
          <h1>Step 1</h1>
          <FinancialReportStepsOneAutomaticIncomeForm />
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialReportAutomaticIncomes;