import {getReports} from "../../utils/api";

export const GET_REPORTS_FETCHING = 'GET_REPORTS_FETCHING';
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

export const getReportsFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_REPORTS_FETCHING});
    getReports(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getReportsSuccess(data.data));
      })
      .catch(e => {
        dispatch(getReportsFail(e));
      })
  }
};

export const getReportsFail = (e) => {
  return {type: GET_REPORTS_FAIL, payload: {message: e?.message}}
};

export const getReportsSuccess = (data) => {
  return {type: GET_REPORTS_SUCCESS, payload: data}
};