const validate = (values) => {
  const errors = {};

  if(!values.report_date) {
    errors.report_date = 'Month field shouldn’t be empty'
  } else if(!/^[0-9.]*$/.test(values.report_date)) {
    errors.report_date = 'Invalid data type';
  } else if(values.report_date > 12 || values.report_date < 1) {
    errors.report_date = 'Month shouldn`t be less then 0 and more than 12';
  }

  return errors;
};

export default validate;
