import {
  GET_SALARY_EDIT_LIST_FAIL,
  GET_SALARY_EDIT_LIST_FETCHING,
  GET_SALARY_EDIT_LIST_SUCCESS
} from '../actions/salaryReportEditActions';

const initialState = {
  salaryEditList: [],
  loading: false,
  errors: ''
};


const salaryEditListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALARY_EDIT_LIST_FETCHING:
      return { ...state, loading: true };
    case GET_SALARY_EDIT_LIST_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_SALARY_EDIT_LIST_SUCCESS:
      return { ...state, salaryEditList: action.payload.salaryEditList, loading: false, errors: '' };
    default:
      return state;
  }
};

export default salaryEditListReducer;
