import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';

const ReactTablePagination = ({ manualPageSize, pageLimit, currentPage, onChangePage, onChangePageSize, totalRecords }) => {
  const pageCount = Math.ceil(totalRecords / pageLimit);

  const onLastPreviousPage = () => {
    onChangePage(1);
  }

  const onPreviousPage = () => {
    if (currentPage !== 1) {
      onChangePage(currentPage - 1);
    }
  }

  const onNextPage = () => {
    if (currentPage + 1 > pageCount) return;
    onChangePage(currentPage + 1);
  }

  const onLastNextPage = () => {
    onChangePage(pageCount);
  }

  const onSelectPageSize = (value) => {
    onChangePageSize(value);
  }

  return (
    <Pagination className="wrapper-pagination" dir="ltr">
      <div className="pagination">
        <div className="d-flex align-items-center">
          <PaginationLink
            className="pagination__link pagination__link--arrow"
            type="button"
            onClick={() => onLastPreviousPage()}
            disabled={currentPage === 1}
          >
            <ChevronDoubleLeftIcon className="pagination__link-icon" />
          </PaginationLink>
          <PaginationLink
            className="pagination__link pagination__link--arrow"
            type="button"
            onClick={() => onPreviousPage()}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className="pagination__link-icon" />
          </PaginationLink>

          <PaginationItem
            className="pagination__item"
          >
            <span>
              {currentPage}
            </span>
          </PaginationItem>

          <PaginationItem className="pagination__item">
            <PaginationLink
              className="pagination__link pagination__link--arrow"
              type="button"
              onClick={() => onNextPage()}
              disabled={currentPage + 1 > pageCount}
            >
              <ChevronRightIcon className="pagination__link-icon" />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="pagination__item">
            <PaginationLink
              className="pagination__link pagination__link--arrow"
              type="button"
              onClick={() => onLastNextPage()}
              disabled={currentPage + 1 > pageCount}
            >
              <ChevronDoubleRightIcon className="pagination__link-icon" />
            </PaginationLink>
          </PaginationItem>
        </div>
        <PaginationItem className="pagination__item pagination-info">
          Showing {(currentPage - 1) * pageLimit + 1} to {Math.min(pageLimit * currentPage, totalRecords)} of {totalRecords}
        </PaginationItem>
        {manualPageSize.length > 1 && (
          <PaginationItem className="pagination__item">
            <FormGroup className="pagination__select-form mb-0">
              <Input
                className="pagination__item pagination-info"
                type="select"
                name="select"
                id="exampleSelect"
                value={pageLimit}
                onChange={(event) => {
                  onSelectPageSize(Number(event.target.value));
                }}
              >
                {manualPageSize.map(item => (
                  <option className="pagination__item pagination__item-option" key={item} value={item}>
                    Show {item}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </PaginationItem>
        )}
      </div>
    </Pagination>
  );
};

ReactTablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

ReactTablePagination.defaultProps = {
  manualPageSize: [10, 20, 30, 40],
};

export default ReactTablePagination;
