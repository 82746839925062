import React from 'react';
import { connect } from 'react-redux';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import YearlyStatistics from '../Statistics/YearlyStatistics';
import StatisticProjects from '../Statistics/StatisticProjects';
import Roles from '../../../config/roles';
import SkynixTeam from './components/SkynixTeam';

const AppDashboard = ({ auth }) => {
  return (
    <div className="dashboard">
      {(auth.user.role === Roles.ADMIN ||
        auth.user.role === Roles.FIN ||
        auth.user.role === Roles.SALES) && <StatisticProjects />}
      <SkynixTeam />
      {auth.user.role === Roles.ADMIN && <YearlyStatistics />}
    </div>
  );
};

AppDashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
  auth: state.auth,
}))(AppDashboard);
