import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import Alert from '../../../shared/components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Loading from "../../../shared/components/Loading";
import { updateSalaryListAction } from '../../../redux/actions/salaryListActions';
import { useNavigate, useParams } from 'react-router-dom';
import { getSalaryEditListAction } from '../../../redux/actions/salaryReportEditActions';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import SalaryReportEditForm from './SalaryReportEditForm';


const SalaryReportEdit = () => {
    const {loading} = useSelector(state => state.salaryEditList);
    const dispatch = useDispatch();
    let {id, user_id} = useParams();
    const navigate = useNavigate();
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        dispatch(getSalaryEditListAction(user_id, id));
    }, [user_id, id, dispatch])

    const toggleCloseModal = () => {
        setSuccessModal(!successModal);
        navigate(-1);
    }
    const saveNewBankAccountData = (newDataForSave) => {
        dispatch(updateSalaryListAction(newDataForSave, setSuccessModal, user_id, id, setErrorModal, setErrorText));
    }

    const toggleCloseErrorModal = () => {
        setErrorModal(!errorModal);
    }

    return (
        <div>
            {
                loading ? (<Loading/>) : (
                    <div className="form_Settings-Page card card-body">
                        <h5 className="card__title bold-text" style={{marginLeft: "0", paddingLeft: "15px"}}>Edit Salary List Details</h5>
                        <Modal
                            isOpen={successModal}
                            toggle={toggleCloseModal}
                            modalClassName="ltr-support"
                            className="modal-dialog  modal-dialog--primary theme-light modal-add-new-variable"
                        >
                            <div className="modal__header">
                                <button
                                    className="lnr lnr-cross modal__close-btn"
                                    type="button"
                                    onClick={toggleCloseModal}
                                />
                            </div>
                            <div className="modal__body">
                                <Alert
                                    color="success"
                                    className="alert--bordered buttonHide"
                                    icon
                                >
                                    <p>
                                        <span className="bold-text">Salary list is updated!</span>
                                    </p>
                                </Alert>
                            </div>
                        </Modal>
                        <SalaryReportEditForm onSubmit={saveNewBankAccountData} />
                        <CustomModal
                            successModal={errorModal}
                            toggleCloseModal={toggleCloseErrorModal}
                            color={"danger"}
                            textModal={errorText}
                        />
                    </div>
                )
            }
        </div>
    )
}

SalaryReportEdit.propTypes = {
    handleSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
};
SalaryReportEdit.defaultProps = {
    errorMessage: '',
};

export default SalaryReportEdit;
