import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import FormAddInvoices from './FormAddInvoices/FormAddInvoices';
import {
  getBusinesses,
  getCustomerProjects, getCustomers,
  getMethods, postInvoices,
} from '../../../../../utils/api';
import { formValueSelector, change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const AddInvoices = ({selectCustomer, selectBusiness}) => {
  const [query, setQuery] = useState({search_query: ''});
  const [queryProjects, setQueryProjects] = useState('');
  const [customer, setCustomer] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalProjectsRecords, setTotalProjectsRecords] = useState(0);
  const [business, setBusiness] = useState([]);
  const [methods, setMethods] = useState([]);
  const [customerProjects, setCustomerProjects] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    const modifiedQuery = {search_query: query.search_query};

    if (!modifiedQuery.search_query) {
      modifiedQuery.is_active = 1;
    }

    getCustomers(modifiedQuery, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setCustomer(res.data.data.users);

        if (isInitialRender.current) {
          setTotalRecords(Number(res.data.data.total_records));
          isInitialRender.current = false;
        }
      }
    })
  }, [query.search_query])

  useEffect(() => {
    getBusinesses('', authUser.accessToken).then((res) => {
      if(res.data.success) {
        setBusiness(res.data.data.businesses);
      }
    })
    getMethods(1, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setMethods(res.data.data);
      }
    })
  }, [])

  useEffect(() => {
    if(!selectCustomer) return;

    dispatch(change('formAddInvoices', 'select_projects', null));

    getCustomerProjects({customer_id: selectCustomer.value}, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setCustomerProjects(res.data.data.projects);
        setTotalProjectsRecords(Number(res.data.data.total_records));
      }
    })
  }, [selectCustomer]);

  useEffect(() => {
    if(!selectCustomer) return;

    const params = {
      search_query: queryProjects,
      customer_id: selectCustomer.value,
    };

    getCustomerProjects(params, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setCustomerProjects(res.data.data.projects);
      }
    })
  }, [queryProjects]);

  useEffect(() => {
    if(!selectBusiness) return;

    getMethods(selectBusiness.value, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setMethods(res.data.data);
        dispatch(change('formAddInvoices', 'select_method', {value: res.data.data[0].id, label: res.data.data[0].name}));
      }
    })
  }, [selectBusiness, dispatch])

  const handleSubmit = (values) => {
    if(values.currency === "USD") {
      delete values.exchange_rate;
    }

    postInvoices(values, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setSuccessModal(true);
      } else {
        setErrorMessage(res.data.errors[0].message);
        setErrorModal(true);
      }
    })
  }

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <FormAddInvoices
            onSubmit={handleSubmit}
            customer={customer}
            business={business}
            methods={methods}
            customerProjects={customerProjects}
            setQuery={setQuery}
            setQueryProjects={setQueryProjects}
            query={query}
            totalRecords={totalRecords}
            totalProjectsRecords={totalProjectsRecords}
          />
          <CustomModal
            successModal={successModal}
            toggleCloseModal={() => {
              setSuccessModal(!successModal);
              navigate("/dashboard/invoices");
            }}
            textModal={"Invoices has been successfully added!"}
            color={"success"}
          />
          <CustomModal
            successModal={errorModal}
            toggleCloseModal={() => setErrorModal(!errorModal)}
            textModal={errorMessage}
            color={"danger"}
          />
        </Col>
      </Row>
    </Container>
  );
};

const selector = formValueSelector('formAddInvoices')

export default connect(state => ({
  selectCustomer: selector(state, 'select_customer'),
  selectBusiness: selector(state, 'select_business')
}))(AddInvoices)