import {getClients} from "../../utils/api";

export const GET_CLIENTS_FETCHING = 'GET_CLIENTS_FETCHING';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';

export const getClientsFetchingAction = (query) => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({type: GET_CLIENTS_FETCHING});
        getClients(query, state.auth.user.accessToken, state.auth.user.clientToken)
            .then(({data}) => {
                dispatch(getClientsSuccess(data.data));
            })
            .catch(e => {
                dispatch(getClientsFail(e));
            })
    }
};

export const getClientsFail = (e) => {
    return {type: GET_CLIENTS_FAIL, payload: {message: e?.message}}
};

export const getClientsSuccess = (data) => {
    return {type: GET_CLIENTS_SUCCESS, payload: data}
};