const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name field shouldn’t be empty';
  } else if(!/^[a-zA-Z ]*$/.test(values.name)) {
    errors.name = 'Invalid data type';
  } else if(values.name?.length > 50) {
    errors.name = 'Name field cannot exceed 50 characters in length';
  }

  if (!values.address) {
    errors.address = 'Address field shouldn’t be empty';
  }

  if (!values.selectDirector) {
    errors.selectDirector = 'Director ID field shouldn’t be empty';
  }

  return errors;
};

export default validate;
