import React from "react";
import { Card, CardBody } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {options, colors } from "./config"


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LinearGraph = (props) => {
  const { statisticsProject } = props;

  const keys = !statisticsProject?.length ? statisticsProject : [] && _.map(_.filter(Object.keys(statisticsProject[0]), k => k !== "month"), (k, i) => {
    return {
      label: k,
      fill: false,
      lineTension: 0.3,
      backgroundColor: colors[i],
      borderColor: colors[i],
      borderWidth: 1,
      pointBackgroundColor: colors[i],
      pointHoverRadius: 4,
      pointHoverBorderWidth: 1,
      pointRadius: 3,
      pointHitRadius: 10,
      data: statisticsProject.map((p) => Number(p[k]).toFixed(2)),
    }
  });

  const data = {
    labels: statisticsProject?.map((m) => (m.month)),
    datasets: keys
  }

  const { t } = useTranslation('common');

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">
            {t('statistics_projects.title_linear_graph')}
          </h5>
        </div>
        {statisticsProject?.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <p>{t('empty_states.default')}</p>
        )}
      </CardBody>
    </Card>
  );
}

export default LinearGraph;