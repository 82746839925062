import {getUser} from "../../utils/api";

export const GET_USER_FETCHING = 'GET_USER_FETCHING';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const getUserFetchingAction = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_USER_FETCHING});
    getUser(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getUserSuccess(data.data));
      })
      .catch(e => {
        dispatch(getUserFail(e));
      })
  }
};

export const getUserFail = (e) => {
  return {type: GET_USER_FAIL, payload: {message: e?.message}}
};

export const getUserSuccess = (user) => {
  return {type: GET_USER_SUCCESS, payload: {user}}
};