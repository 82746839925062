import {getBusiness} from "../../utils/api";

export const GET_BUSINESS_EDIT_FETCHING = 'GET_BUSINESS_EDIT_FETCHING';
export const GET_BUSINESS_EDIT_FAIL = 'GET_BUSINESS_EDIT_FAIL';
export const GET_BUSINESS_EDIT_SUCCESS = 'GET_BUSINESS_EDIT_SUCCESS';

export const getBusinessEditAction = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_BUSINESS_EDIT_FETCHING});
    getBusiness(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getBusinessSuccess(data.data));
      })
      .catch(e => {
        dispatch(getBusinessFail(e));
      })
  }
};
export const getBusinessFail = (e) => {
  return {type: GET_BUSINESS_EDIT_FAIL, payload: {message: e?.message}}
};
export const getBusinessSuccess = (business) => {
  return {type: GET_BUSINESS_EDIT_SUCCESS, payload: {business}}
};