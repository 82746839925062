import { getBankAccountData } from '../../utils/api';

export const GET_BANK_ACCOUNT_DATA_FETCHING = 'GET_BANK_ACCOUNT_DATA_FETCHING';
export const GET_BANK_ACCOUNT_DATA_FAIL = 'GET_BANK_ACCOUNT_DATA_FAIL';
export const GET_BANK_ACCOUNT_DATA_SUCCESS = 'GET_BANK_ACCOUNT_DATA_SUCCESS';

export const getBankAccountDataAction = (userId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_BANK_ACCOUNT_DATA_FETCHING});
    getBankAccountData(userId, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getBankAccountDataSuccess(data.data));
      })
      .catch(e => {
        dispatch(getBankAccountDataFail(e));
      })
  }
};

export const getBankAccountDataFail = (e) => {
  return {type: GET_BANK_ACCOUNT_DATA_FAIL, payload: {message: e?.message}}
};

export const getBankAccountDataSuccess = (bank_account_data) => {
  return {type: GET_BANK_ACCOUNT_DATA_SUCCESS, payload: bank_account_data}
};