export const columns = [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    disableGlobalFilter: true,
  },
  {
    Header: 'Source',
    accessor: 'source',
    disableGlobalFilter: true,
  },
  {
    Header: 'Manager',
    accessor: 'manager',
    disableGlobalFilter: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    disableGlobalFilter: true,
  },
];
