import {
  GET_BANK_ACCOUNT_DATA_FAIL,
  GET_BANK_ACCOUNT_DATA_FETCHING,
  GET_BANK_ACCOUNT_DATA_SUCCESS,
} from '../actions/bankAccountActions';

const initialState = {
  bankAccount: {},
  loading: false,
  errors: false
};

const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNT_DATA_FETCHING:
      return { ...state, loading: true };
    case GET_BANK_ACCOUNT_DATA_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_BANK_ACCOUNT_DATA_SUCCESS:
      return { ...state, bankAccount: action.payload,  loading: false, errors: '' };
    default:
      return state;
  }
};

export default bankAccountReducer;
