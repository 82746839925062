export const columns = [
  {
    Header: 'ID',
    accessor: 'report_id',
  },
  {
    Header: 'Date',
    accessor: 'reported_date'
  },
  {
    Header: 'Task',
    accessor: 'task',
  },
  {
    Header: 'Hours',
    accessor: 'hour',
  },
  {
    Header: 'Project',
    accessor: 'project_name',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    disableGlobalFilter: true,
  },
];