import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';
import { removeExpiredItem } from '../../utils/helpers';
import { getManageSaaS } from '../../utils/api';

const FETCH_APP_CONFIG_REQUEST = 'FETCH_APP_CONFIG_REQUEST';
const FETCH_APP_CONFIG_SUCCESS = 'FETCH_APP_CONFIG_SUCCESS';
const FETCH_APP_CONFIG_FAILURE = 'FETCH_APP_CONFIG_FAILURE';
const SET_MANAGE_SAAS = 'SET_MANAGE_SAAS';

export const fetchAppConfigRequest = createAction(FETCH_APP_CONFIG_REQUEST);
export const fetchAppConfigSuccess = createAction(FETCH_APP_CONFIG_SUCCESS);
export const fetchAppConfigFailure = createAction(FETCH_APP_CONFIG_FAILURE);
export const setManageSaaS = createAction(SET_MANAGE_SAAS);

export const fetchAppConfig = () => async (dispatch) => {
	try {
		dispatch(fetchAppConfigRequest());
		const response = await api.appConfig.getAppConfigData(); // Fake request example
		const configAppData = response.data;
		dispatch(fetchAppConfigSuccess(configAppData));
	} catch (error) {
		dispatch(fetchAppConfigFailure({ error }));
		throw error;
	}
};

export const fetchManageSaas = (manageSaaS) => (dispatch, getState) => {
	const manageSaasId = 'manageSaas';
	const state = getState();

	const isRemoved = removeExpiredItem(manageSaasId);

	if (!manageSaaS || isRemoved) {
		getManageSaaS(state.auth.user.accessToken, state.auth.user.clientToken).then(res => {
			const { success, data } = res.data;
			const isAdmin = data?.isAdmin;

			let time;
			let currentTimestamp = Date.now();
			let val = false;

			if (success) {
				// 24h.
				time = currentTimestamp + 24 * 60 * 60 * 1000;
				val = isAdmin;
			} else {
				// One year.
				time = currentTimestamp + 8760 * 60 * 60 * 1000;
			}

			const body = {
				val,
				expire: time,
			};

			localStorage.setItem(manageSaasId, JSON.stringify(body));
			dispatch(setManageSaaS(data));
		});
	}
};
