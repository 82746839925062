const validate = (values) => {
  const errors = {};
  const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();

  if(!String(values.bonuses)) {
    errors.bonuses = 'Bonuses field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(values.bonuses)) {
    errors.bonuses = 'Invalid data type';
  }

  if(!String(values.hospital_days)) {
    errors.hospital_days = 'Hospital Days field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(values.hospital_days)) {
    errors.hospital_days = 'Invalid data type';
  } else if(values.hospital_days > daysInMonth) {
    errors.hospital_days = 'Сan not be greater than the number of working days per month';
  }

  if(!String(values.overtime_days)) {
    errors.overtime_days = 'Overtime Days field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(values.overtime_days)) {
    errors.overtime_days = 'Invalid data type';
  } else if(values.overtime_days > daysInMonth) {
    errors.overtime_days = 'Сan not be greater than the number of working days per month';
  }

  if(!String(values.other_surcharges)) {
    errors.other_surcharges = 'Other Surcharges field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(values.other_surcharges)) {
    errors.other_surcharges = 'Invalid data type';
  }

  if(!String(values.vacation_days)) {
    errors.vacation_days = 'Vacations Days field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(values.vacation_days)) {
    errors.vacation_days = 'Invalid data type';
  } else if(values.vacation_days > daysInMonth) {
    errors.vacation_days = 'Сan not be greater than the number of working days per month';
  }

  return errors;
};

export default validate;
