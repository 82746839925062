import { getYearlyStatistics } from '../../utils/api';

export const GET_YEARLY_STATISTICS_FETCHING = 'GET_YEARLY_STATISTICS_FETCHING';
export const GET_YEARLY_STATISTICS_FAIL = 'GET_YEARLY_STATISTICS_FAIL';
export const GET_YEARLY_STATISTICS_SUCCESS = 'GET_YEARLY_STATISTICS_SUCCESS';

export const getYearlyStatisticsFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_YEARLY_STATISTICS_FETCHING});
    getYearlyStatistics(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getYearlyStatisticsSuccess(data.data));
      })
      .catch(e => {
        dispatch(getYearlyStatisticsFail(e));
      })
  }
};

export const getYearlyStatisticsFail = (e) => {
  return {type: GET_YEARLY_STATISTICS_FAIL, payload: {message: e?.message}}
};

export const getYearlyStatisticsSuccess = (data) => {
  return {type: GET_YEARLY_STATISTICS_SUCCESS, payload: data}
};