import { getProjectById, getProjectByIdMonitoringServices } from '../../utils/api';

export const GET_PROJECT_FETCHING = 'GET_PROJECT_FETCHING';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_MONITORING_SERVICES_FETCHING = 'GET_PROJECT_MONITORING_SERVICES_FETCHING';
export const GET_PROJECT_MONITORING_SERVICES_FAIL = 'GET_PROJECT_MONITORING_SERVICES_FAIL';
export const GET_PROJECT_MONITORING_SERVICES_SUCCESS = 'GET_PROJECT_MONITORING_SERVICES_SUCCESS';

export const getProjectFetchingAction = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_PROJECT_FETCHING});
    getProjectById(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getProjectSuccess(data.data));
      })
      .catch(e => {
        dispatch(getProjectFail(e));
      })
  }
};

export const getProjectMonitoringServices = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_PROJECT_MONITORING_SERVICES_FETCHING});
    getProjectByIdMonitoringServices(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getProjectMonitoringServicesSuccess(data.data));
      })
      .catch(e => {
        dispatch(getProjectMonitoringServicesFail(e));
      })
  }
}

export const getProjectFail = (e) => {
  return {type: GET_PROJECT_FAIL, payload: {message: e?.message}}
};

export const getProjectSuccess = (project) => {
  return {type: GET_PROJECT_SUCCESS, payload: {project}}
};

export const getProjectMonitoringServicesFail = (e) => {
  return {type: GET_PROJECT_MONITORING_SERVICES_FAIL, payload: {message: e?.message}}
};

export const getProjectMonitoringServicesSuccess = (monitoring) => {
  return {type: GET_PROJECT_MONITORING_SERVICES_SUCCESS, payload: {monitoring}}
};