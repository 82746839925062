import {
  GET_VACATIONS_HISTORY_FAIL,
  GET_VACATIONS_HISTORY_FETCHING,
  GET_VACATIONS_HISTORY_SUCCESS
} from '../actions/vacationsHistoryActions';

const initialState = {
  vacationsHistory: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const vacationsHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_VACATIONS_HISTORY_FETCHING:
      return { ...state, loading: true };
    case GET_VACATIONS_HISTORY_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_VACATIONS_HISTORY_SUCCESS:
      return { ...state, vacationsHistory: action.payload.vacationsHistory.vacationHistoryItems, totalRecords: Number(action.payload.vacationsHistory.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default vacationsHistory;
