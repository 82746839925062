import {
  GET_PROJECT_FAIL,
  GET_PROJECT_FETCHING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_MONITORING_SERVICES_SUCCESS,
  GET_PROJECT_MONITORING_SERVICES_FETCHING,
  GET_PROJECT_MONITORING_SERVICES_FAIL
} from '../actions/projectsEditActions';

const initialState = {
  editProject: [],
  monitoringServices: [],
  loading: false,
  errors: ''
};


const editProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_FETCHING:
      return { ...state, loading: true };
    case GET_PROJECT_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_PROJECT_SUCCESS:
      return { ...state, editProject: action.payload.project.projects, loading: false, errors: '' };
    case GET_PROJECT_MONITORING_SERVICES_FETCHING:
      return { ...state, loading: true };
    case GET_PROJECT_MONITORING_SERVICES_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_PROJECT_MONITORING_SERVICES_SUCCESS:
      return { ...state, monitoringServices: action.payload.monitoring ? action.payload.monitoring : [], loading: false, errors: '' };
    default:
      return state;
  }
};

export default editProjectReducer;
