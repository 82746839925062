const validate = (values) => {
  const errors = {};

  if(!/^[0-9]*$/.test(values.bonuses)) {
    errors.bonuses = 'Invalid data type';
  }

  return errors;
};

export default validate;
