import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import WrappedRoutes from './WrappedRoutes';

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/404" element={<NotFound404 />} />
        <Route path="*" element={<WrappedRoutes />} />
        <Route element={<NotFound404 />} />
      </Routes>
    </main>
  </MainWrapper>
);

export default Router;
