import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccountDataAction } from '../../../../../redux/actions/bankAccountActions';
import AccountGeneralForm from './AccountGeneralForm/AccountGeneralForm';
import { putAccount } from '../../../../../utils/api';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import roles from '../../../../../config/roles';

const AccountGeneral = () => {
  const {bankAccount, errors} = useSelector(state => state.bankAccount);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [failedEditModal, setFailedDeleteModal] = useState(false);
  const [messageEditError, setMessageEditError] = useState('');
  const [successEditModal, setSuccessEditModal] = useState(false);

  useEffect(() => {
    dispatch(getBankAccountDataAction(authUser.userId));
  }, [dispatch, authUser.userId, refreshCounter]);

  const handleSubmit = (values) => {
    putAccount(authUser.userId, values, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setSuccessEditModal(!successEditModal);
      } else {
        setMessageEditError(res.data.errors[0].message);
        setFailedDeleteModal(!failedEditModal);
      }
    })
  }

  return (
    <>
      <div className="wrapper-general-cards">
        <div>
          <span>Hello <b>{bankAccount.first_name} {bankAccount.role}</b></span><br/>
          <span>You are joined Skynix on <b>{bankAccount.joined}</b></span><br/>
          <span>Last time you visited Skynix on <b>{bankAccount.last_login}</b></span><br/>
          <span>Available vacation days this year <b>{bankAccount.vacation_days_available} ({bankAccount.vacation_days})</b></span>
        </div>
        {authUser.role !== roles.CLIENT && (
          <div>
            <span>Today you have reported <b>{bankAccount.today_logged_hours} hours</b></span><br/>
            <span>This week you have reported <b>{bankAccount.week_logged_hours} hours</b></span><br/>
            <span>This month you have reported <b>{bankAccount.month_logged_hours} hours</b></span>
        </div>
        )}
      </div>
      <Card>
        <CardBody className="pl-0 pr-0">
          <Col className="mb-3">
            <h4>Account Details</h4>
          </Col>
          <AccountGeneralForm onSubmit={handleSubmit} />
        </CardBody>
      </Card>
      {
        errors && (<div>{errors}</div>)
      }

      <CustomModal
        successModal={failedEditModal}
        toggleCloseModal={() => setFailedDeleteModal(!failedEditModal)}
        textModal={messageEditError}
        color={"danger"}
      />
      <CustomModal
        successModal={successEditModal}
        toggleCloseModal={() => {
          setSuccessEditModal(!successEditModal);
          setRefreshCounter(refreshCounter + 1);
        }}
        textModal={`Information was successfully changed!`}
        color={"success"}
      />
    </>
  );
};

export default AccountGeneral;
