import React from 'react';
import { Modal } from 'reactstrap';
import UploadForm from './UploadForm/UploadForm';

const BusinessChangeLogoModal = ({modal, toggle, id}) => {
  return (
    <Modal
      isOpen={!!modal}
      toggle={toggle}
      modalClassName="ltr-support business-change-logo-modal"
      className="modal-dialog--primary theme-light"
      size={"lg"}
      style={{maxWidth: "800px"}}
    >
      <div className="modal__header" data-testid="edit-modal">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        <UploadForm id={id} />
      </div>
    </Modal>
  );
};

export default BusinessChangeLogoModal;