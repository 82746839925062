import {getSettings} from "../../utils/api";

export const GET_SETTINGS_FETCHING = 'GET_SETTINGS_FETCHING';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

export const getSettingsFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_SETTINGS_FETCHING});
    getSettings(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getSettingsSuccess(data.data));
      })
      .catch(e => {
        dispatch(getSettingsFail(e));
      })
  }
};

export const getSettingsFail = (e) => {
  return {type: GET_SETTINGS_FAIL, payload: {message: e?.message}}
};

export const getSettingsSuccess = (settings) => {
  return {type: GET_SETTINGS_SUCCESS, payload: {settings}}
};