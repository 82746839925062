import {
  GET_SUBCONTRACTORS_FETCHING,
  GET_SUBCONTRACTORS_FAIL,
  MODAL_SUBCONTRACTORS_FAIL,
  MODAL_SUBCONTRACTORS_FETCHING,
  GET_SUBCONTRACTORS_SUCCESS,
  GET_SUBCONTRACTORS_USERS_FETCHING,
  GET_SUBCONTRACTORS_USERS_FAIL,
  GET_SUBCONTRACTORS_USERS_SUCCESS,
} from '../actions/subcontractorsActions';

const defaultState = {
  subcontractors: [],
  loading: false,
  errors: [],
  modalLoading: false,
  modalErrors: [],
  totalRecords: 0,
  users: [],
  totalUsers: 0,
  userLoading: false,
  userLoaded: false,
  userError: '',
};

const subcontractorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SUBCONTRACTORS_FETCHING:
      return { ...state, loading: true };
    case GET_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        subcontractors: action.payload.subcontractors,
        totalRecords: action.payload.total_records,
      };
    case GET_SUBCONTRACTORS_FAIL:
      return { ...state, loading: false, errors: action.payload.errors };
    case MODAL_SUBCONTRACTORS_FETCHING:
      return { ...state, modalLoading: true, modalErrors: [] };
    case MODAL_SUBCONTRACTORS_FAIL:
      return {
        ...state,
        modalLoading: false,
        modalErrors: action.payload.errors,
      };
    case GET_SUBCONTRACTORS_USERS_FETCHING:
      return { ...state, userLoading: true };
    case GET_SUBCONTRACTORS_USERS_SUCCESS:
      const newState = {
        ...state,
        userLoading: false,
        userError: '',
        users: action.payload.users,
      };

      if (!state.userLoaded) {
        newState.totalUsers = +action.payload.totalUsers;
        newState.userLoaded = true;
      }

      return newState;
    case GET_SUBCONTRACTORS_USERS_FAIL:
      return {
        ...state,
        userLoading: false,
        userError: action.payload.error,
      };
    default:
      return state;
  }
};

export default subcontractorsReducer;
