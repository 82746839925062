import { getClientToken, getUser, getUserPhoto, getModules, postLoginsAs } from '../../utils/api';
import _ from "lodash";
import { backToLogin } from '../../utils/helpers';

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_CHECKED = 'AUTHENTICATE_CHECKED';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';
export const LOGIN_AS = 'LOGIN_AS';
export const LOGIN_AS_SUCCESS = 'LOGIN_AS_SUCCESS';
export const LOGIN_AS_ERROR = 'LOGIN_AS_ERROR';
export const BACK_TO_ADMIN = 'BACK_TO_ADMIN';


export const authRequest = (user) => {
	return async dispatch => {
		dispatch(auth());
		const clientToken = await getClientToken(user.accessToken);

		if(!clientToken.data.success) return backToLogin();

		const userPhoto = await getUserPhoto(user.userId, user.accessToken);
		const userData = await getUser(user.userId, user.accessToken);
		const clientModules = await getModules(user.accessToken, clientToken.data.data.token);

		const storageData = {
			accessToken: user.accessToken,
			clientModules: clientModules.data.data,
			clientToken: clientToken.data.data.token,
			crmEnabled: !!_.find(clientModules.data.data, (c) => c.key === "crm"),
			role: user.role,
			ssoEnabled: !!_.find(clientModules.data.data, (c) => c.key === "sso"),
			userData: userData.data.data,
			userId: user.userId,
			userPhoto: userPhoto.data.data[0]
		};

		if (!userPhoto.data.success) {
			dispatch(authError(userPhoto.data.errors[0].message));
		} else if (!clientToken.data.success) {
			dispatch(authError(clientToken.data.errors[0].message));
		} else if (!userData.data.success) {
			dispatch(authError(userData.data.errors[0].message));
		} else {
			dispatch(authSuccess(storageData));
		}
	};
};

export const authError = (errors) => {
	return { type: AUTHENTICATE_ERROR_AUTH, payload: { errors } }
};

export const authSuccess = (user) => {
	return { type: AUTHENTICATE_SUCCESS, payload: { user } }
};

export const auth = () => {
	return { type: AUTHENTICATE }
}

export const authChecked = (user) => {
	return { type: AUTHENTICATE_CHECKED, payload: { user } };
}

export const loginAs = () => {
	return { type: LOGIN_AS };
};

export const loginAsSuccess = (user) => {
	return { type: LOGIN_AS_SUCCESS, payload: { user } }
};

export const loginAsError = (error) => {
	return { type: LOGIN_AS_ERROR, payload: { error } }
};

export const backToAdmin = () => {
	return { type: BACK_TO_ADMIN };
}

export const loginAsRequest = (userId) => {
	return async (dispatch, getState) => {
		const state = getState();
		dispatch(loginAs());

		try {
			const accessData = await postLoginsAs(userId, state.auth.user.accessToken);	

			if (!accessData.data.success) {
				throw accessData.data.errors;
			}

			const clientToken = await getClientToken(accessData.data.data.access_token);

			if (!clientToken.data.success) {
				throw clientToken.data.errors;
			}
			
			const userPhoto = await getUserPhoto(userId, accessData.data.data.access_token);

			if (!userPhoto.data.success) {
				throw userPhoto.data.errors;
			}

			const userData = await getUser(userId, accessData.data.data.access_token);

			if (!userData.data.success) {
				throw userData.data.errors;
			}

			const clientModules = await getModules(accessData.data.data.access_token, clientToken.data.data.token);

			if (!clientModules.data.success) {
				throw clientModules.data.errors;
			}

			const storageData = {
				accessToken: accessData.data.data.access_token,
				clientModules: clientModules.data.data,
				clientToken: clientToken.data.data.token,
				crmEnabled: !!_.find(clientModules.data.data, (c) => c.key === "crm"),
				role: accessData.data.data.role,
				ssoEnabled: !!_.find(clientModules.data.data, (c) => c.key === "sso"),
				userData: userData.data.data,
				userId: userId,
				userPhoto: userPhoto.data.data[0]
			};

			dispatch(loginAsSuccess(storageData));

		} catch (errors) {
			if (_.isArray(errors)) {
				dispatch(loginAsError(errors[0].message));
			} else {
				dispatch(loginAsError(errors?.message));
			}
		}	
	}
}