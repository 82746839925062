import dotenv from 'dotenv';
import config from '12factor-config';

dotenv.config();

export const defaultConfig = config({
  publicUrl: {
    env: 'PUBLIC_URL',
    type: 'string',
    required: true,
    default: '/',
  },
  host: {
    env: 'HOST',
    type: 'string',
    required: false,
    default: 'clodify.com',
  },
  nodeEnv: {
    env: 'NODE_ENV',
    type: 'string',
    required: true,
    default: 'production',
  },
  coreApiUrl: {
    env: 'CORE_API_URL',
    type: 'string',
    default: 'core.api.clodify.com',
  },
});
