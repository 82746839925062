import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GraphBar from './components/GraphBar/GraphBar';
import { getReviews } from '../../../utils/api';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import _ from 'lodash';
import moment from 'moment';

Chart.register(CategoryScale);
Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);

const MyReviews = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const authUser = useSelector(state => state.auth.user);
  const [failModal, setFailModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    getReviews(authUser.userId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setReviewsData(res?.data?.data?.reviews);
      } else {
        setMessageError(res?.data?.errors[0]?.message);
        setFailModal(!failModal);
      }
    })
  }, [authUser.userId, failModal]);

  const barData = {
    labels: _.map(reviewsData, (r) => {
      const dateFrom = moment(r.date_from, 'DD/MM/YYYY');
      return `${monthNames[dateFrom.get('month')]} ${dateFrom.get('year')}`
    }),
    datasets: [
      {
        label: '',
        backgroundColor: 'rgba(124,181,236,0.2)',
        borderColor: 'rgba(124,181,236,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(124,181,236,0.4)',
        hoverBorderColor: 'rgba(124,181,236,1)',
        data: _.map(reviewsData, (r) => r.score_total),
        dataTooltips: _.map(reviewsData, (t) => ({
            "Score A": t.score_performance,
            "Score B": t.score_loyalty,
            "Score C": t.score_earnings,
            notes: t.notes
          }
        ))
      },
    ],
  };

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <Card>
            <div className="my-reviews-description d-flex flex-column card-body">
              <h4 className="mb-2"><b>Reviews Legend</b></h4>
              <p className="m-0">Each review is a combination of 3 parameters that define working process & results and is some percentage with max value of 100%</p>
              <p className="m-0"><b>Score A</b> is usually 100% and is lower when there are non approved hours.</p>
              <p className="m-0"><b>Score B</b> is a complex value of days off, sickness days, working fails. This is 100% if there are no fails, otherwise this is less.</p>
              <p className="m-0"><b>Score C</b> is a value of income. This value is 100% if there is enough earnings to cover salary, taxes, renting, and other expenses + 20% profit. Otherwise less.</p>
            </div>
          </Card>
        </Col>
        <Col className="col-12">
          <Card>
            <CardBody style={{ position: 'relative'}}>
              <GraphBar data={barData} />
              <div id='custom-tooltip'></div>
            </CardBody>
          </Card>
        </Col>

        <CustomModal
          successModal={failModal}
          toggleCloseModal={() => setFailModal(!failModal)}
          textModal={messageError}
          color={"danger"}
        />
      </Row>
    </Container>
  );
};

export default MyReviews;