import {getManagement} from "../../utils/api";

export const GET_MANAGEMENT_FETCHING = 'GET_MANAGEMENT_FETCHING';
export const GET_MANAGEMENT_FAIL = 'GET_MANAGEMENT_FAIL';
export const GET_MANAGEMENT_SUCCESS = 'GET_MANAGEMENT_SUCCESS';

export const getManagementFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_MANAGEMENT_FETCHING});
    getManagement(query, state.auth.user.accessToken)
      .then(({data}) => {
        if(data.success) {
          dispatch(getManagementSuccess(data.data));
        }

        dispatch(getManagementFail(data.errors[0]));
      })
      .catch(e => {
        dispatch(getManagementFail(e));
      })
  }
};

export const getManagementFail = (e) => {
  return {type: GET_MANAGEMENT_FAIL, payload: {message: e?.message}}
};

export const getManagementSuccess = (management) => {
  return {type: GET_MANAGEMENT_SUCCESS, payload: {management}}
};