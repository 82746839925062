import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getMyInvoicesFetchingAction } from '../../../../../redux/actions/myInvoicesAction';
import { Button, ButtonToolbar, Col, Container, Row } from 'reactstrap';
import DataReactTable from '../../../../Tables/DataTable/components/DataReactTable';
import { columns } from './MyInvoicesDataColumns';
import { deleteMyInvoice, getAllUsers, getMyInvoicesPdfFile, postMyInvoice } from '../../../../../utils/api';
import _ from "lodash";
import MyInvoicesSelectForm from './MyInvoicesSelectForm';
import Roles from '../../../../../config/roles';
import MyInvoiceModalForm from './MyInvoiceModalForm';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import DeleteIcon from 'mdi-react/DeleteIcon';
import DownloadOutlineIcon from 'mdi-react/DownloadOutlineIcon';
import ConfirmModal from '../../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import ReactTooltip from 'react-tooltip';


const MyInvoices = () => {
  const { t } = useTranslation('common');
  const user = useSelector(state => state.auth.user);
  const {myInvoices, errors, totalRecords} = useSelector(state => state.myInvoices);
  const dispatch = useDispatch();
  const [query, setQuery] = useState({start: 0, limit: 10, search_query: '', user_id: ''});
  const [users, setUsers] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [messageError, setMessageError] = useState('');
  const [failedPostModal, setFailedPostModal] = useState(false);
  const [failedDeleteModal, setFailedDeleteModal] = useState(false);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [failedDownloadPdfModal, setFailedDownloadPdfModal] = useState(false);
  const [messageErrorDownloadPdf, setMessageErrorDownloadPdf] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [disabled, setDisabled] = useState(false);


  useEffect(() => {
    if(user.role === Roles.ADMIN) {
      getAllUsers(user.accessToken).then(res => setUsers(res.data.data.users))
    }
  }, [user.role]);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    dispatch(getMyInvoicesFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, query, dispatch, refreshCounter]);

  const onDownloadPDF = (id) => {
    setDisabled(true);
    getMyInvoicesPdfFile(id, user.accessToken)
      .then(res => {
        if(res.data.success) {
          const fileURL = 'data:application/octet-stream;base64,' + res?.data?.data?.pdf;
          let a = document.createElement('a');
          a.href = fileURL;
          a.download = `${res.data.data.name}`;
          a.click();
          setDisabled(false);
        }

        setMessageErrorDownloadPdf(res.data.errors[0].message);
        setFailedDownloadPdfModal(true);

      })
  }

  const getSearchValue = _.debounce((value) => {
    if (value === "0") return;

    setQuery({...query, search_query: value});
  }, 600)

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setQuery({...query, user_id: selectedUserId});
  }

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleSubmitModalForm = (value) => {
    postMyInvoice(value, user.accessToken)
      .then((res) => {
        if(res.data.success) {
          setModal(!modal);
          setRefreshCounter(refreshCounter + 1);
        } else {
          setFailedPostModal(true)
          setMessageError(res.data.errors[0].message);
        }
      }
    )
  }

  const handleDelete = () => {
    deleteMyInvoice(deleteId, user.accessToken).then(res => {
      if(res.data.success) {
        setSuccessDeleteModal(!successDeleteModal);
      } else {
        setMessageError(res.data.errors[0].message);
        setFailedDeleteModal(!failedDeleteModal);
      }
    })
  }

  const toggle = () => {
    setConfirmModal(!confirmModal);
  };

  return (
    <Container className="my-invoices">
      <Row>
        {
          (user.role === Roles.ADMIN) && (
            <MyInvoicesSelectForm
              handleSubmit={handleSubmitForm}
              users={users}
              setSelectedUserId={setSelectedUserId}
            />
          )
        }
        <Col sm={12}>
          {
            user.role === Roles.ADMIN && (
              <ButtonToolbar className="form__button-toolbar w-100">
                <Button color="success" onClick={() => setModal(!modal)}>Add Invoice</Button>
              </ButtonToolbar>
            )
          }
        </Col>
        <Col>
          {
            <DataReactTable
              tableName={"My Invoices"}
              pagination={{
                onChangePageSize: handleChangePageSize,
                onChangePage: handleChangePage,
                currentPage,
                totalRecords,
                pageLimit
              }}
              sortTable={true}
              search={true}
              searchValue={getSearchValue}
              emptyStateMessage={t('empty_states.invoices')}
              reactTableData={{tableHeaderData: columns.filter(c => {
                if(c.accessor === 'user.username') {
                  return user.role === Roles.ADMIN;
                }
                return true;
              }), tableRowsData: myInvoices?.map((c) => {
                return {...c,
                  id: `${c.id}`,
                  period: `${c.date_from} ~ ${c.date_to}`,
                  actions: (
                    <>
                      <ReactTooltip id="loadIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.downloadPDF")}
                        </span>
                      </ReactTooltip>
                      <button
                        style={{border: 0, background: 'transparent', padding: 0, margin: '0 5px'}}
                        data-tip
                        data-for="loadIcon"
                        disabled={disabled}
                      >
                        <DownloadOutlineIcon
                          onClick={() => onDownloadPDF(c.id)}
                          size={18}
                          color="#b1c3c8"
                        />
                      </button>
                      {
                        user.role === Roles.ADMIN && (
                          <>
                            <ReactTooltip id="deleteIcon" type="dark">
                              <span style={{ color: 'white' }}>
                                {t("reactTooltips.delete")}
                              </span>
                            </ReactTooltip>
                            <button data-tip
                                    data-for="deleteIcon"
                                    onClick={() => {
                                        setDeleteId(c.id)
                                        setConfirmModal(true)}}
                                    style={{border: 0, background: 'transparent'}}>
                              <DeleteIcon size={18} color="#b1c3c8" />
                            </button>
                          </>
                        )
                      }
                    </>
                  )
                }
              })}}
            />
          }
          {
            errors ? (<div>{errors}</div>) : null
          }
          {
            modal && (<MyInvoiceModalForm toggle={() => setModal(!modal)} modal={modal} onSubmit={handleSubmitModalForm} users={users}/>)
          }

          <CustomModal
            successModal={failedPostModal}
            toggleCloseModal={() => setFailedPostModal(!failedPostModal)}
            textModal={messageError}
            color={"danger"}
          />

          <CustomModal
            successModal={failedDeleteModal}
            toggleCloseModal={() => setFailedDeleteModal(!failedDeleteModal)}
            textModal={messageError}
            color={"danger"}
          />

          <ConfirmModal
            color="danger"
            btn="Default"
            message="Are you sure you want to delete it?"
            toggle={toggle}
            modal={confirmModal}
            onNext={handleDelete}
          />

          <CustomModal
            successModal={successDeleteModal}
            toggleCloseModal={() => {
              setSuccessDeleteModal(!successDeleteModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Invoice ${deleteId} was successfully deleted! `}
            color={"success"}
          />

          <CustomModal
            successModal={failedDownloadPdfModal}
            toggleCloseModal={() => setFailedDownloadPdfModal(!failedDownloadPdfModal)}
            textModal={messageErrorDownloadPdf}
            color={"danger"}
          />
        </Col>
      </Row>
    </Container>
  )
};

export default MyInvoices;