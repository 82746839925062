export const header = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Name',
    },
    {
        id: 3,
        title: 'Basic',
    },
    {
        id: 4,
        title: 'Price',
    },
    {
        id: 5,
        title: 'Actions',
    },
];