import {
  GET_BUSINESSES_FAIL,
  GET_BUSINESSES_FETCHING,
  GET_BUSINESSES_SUCCESS
} from '../actions/businessesActions';

const initialState = {
  businesses: [],
  loading: false,
  errors: '',
  totalRecords: 0,
};


const businessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESSES_FETCHING:
      return { ...state, loading: true };
    case GET_BUSINESSES_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_BUSINESSES_SUCCESS:
      return { ...state,
        businesses: action.payload.businesses.businesses,
        totalRecords: Number(action.payload.businesses.total_records),
        loading: false,
        errors: ''
      };
    default:
      return state;
  }
};

export default businessesReducer;
