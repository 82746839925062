import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../../shared/components/Alert';
import TimeLineElement from './TimeLineElement';
import CommentEdit from './CommentEdit';
import { handleFile } from '../../../utils/helpers';
import { postCommentLead } from '../../../utils/api';
import { useSelector } from 'react-redux';

const TimeLine = ({ items, leadId, onSuccess}) => {
  const { t } = useTranslation('common');
  const [comment, setComment] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [mesErrors, setMesErrors] = useState([]);
  const authUser = useSelector(state => state.auth.user);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const comment_file = await handleFile(selectedFile.file);

    postCommentLead(leadId, comment, comment_file, authUser.accessToken)
      .then(res => {
    !res.data.success
      ? setMesErrors(res.data.errors)
      : onSuccess();
    })
  };

  if (!items.length) {
    return (
      <div className='leads-single__alert'>
        <Alert color="info" className="alert--neutral" icon>
          <div className='typography-card'>
            <h4 className='alert__title'>{t('leads.timeline.alert_title')}</h4>
          </div>
        </Alert>
      </div>
    );
  }

  return (
    <>
      <CommentEdit
        comment={comment}
        setComment={setComment}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        handleSubmitEvent={handleSubmitEvent}
        mesErrors={mesErrors}
      />
      <div className="timeline">
        {items.map((item) => <TimeLineElement key={item.id} {...item} />)}
      </div>
    </>

  );
};

export default TimeLine;