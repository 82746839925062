import React from 'react';

const UserPhoto = ({signPhoto, setShowSignPhoto, handleDeletePhoto}) => {
  return (
    <div className="d-flex flex-column justify-content-center user-photo-container">
      <img src={signPhoto || process.env.PUBLIC_URL + '/img/user-avatar.svg'} alt={'Sign'} />
      <div className="d-flex justify-content-between">
        <button className="btn btn-danger" onClick={() => handleDeletePhoto()}>Delete Sign</button>
        <button className="btn btn-primary" onClick={() => setShowSignPhoto(false)}>Change Sign</button>
      </div>
    </div>
  );
};

export default UserPhoto;