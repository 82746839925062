import {
    GET_CLIENT_MODULES_FAIL,
    GET_CLIENT_MODULES_FETCHING,
    GET_CLIENT_MODULES_SUCCESS
} from '../actions/clientModulesAction';

const initialState = {
    clientModules: [],
    loading: false,
    errors: ''
};


const clientModulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENT_MODULES_FETCHING:
            return { ...state, loading: true };
        case GET_CLIENT_MODULES_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_CLIENT_MODULES_SUCCESS:
            return { ...state, clientModules: action.payload.clientModules, loading: false, errors: '' };
        default:
            return state;
    }
};

export default clientModulesReducer;
