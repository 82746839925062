import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TableConfig = () => {
  const { t } = useTranslation('common');

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: t('my_salary.table.date'),
        accessor: 'date',
      },
      {
        Header: t('my_salary.table.salary'),
        accessor: 'salary',
      },
      {
        Header: t('my_salary.table.bonuses'),
        accessor: 'bonuses',
      },
      {
        Header: t('my_salary.table.day_off'),
        accessor: 'day_off',
      },
      {
        Header: t('my_salary.table.hospital_value'),
        accessor: 'hospital_value',
      },
      {
        Header: t('my_salary.table.vacation_value'),
        accessor: 'vacation_value',
      },
      {
        Header: t('my_salary.table.other_surcharges'),
        accessor: 'other_surcharges',
      },
      {
        Header: t('my_salary.table.subtotal'),
        accessor: 'subtotal',
      },
    ],
    [t],
  );

  return {
    columns,
  };
};

export default TableConfig;
