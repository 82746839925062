import React from 'react';
import { Button, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../../utils/helpers';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';


let ReportsFilterForm = ({handleSubmit, resetForm}) => {
  return (
    <form  onSubmit={handleSubmit} className="form w-100">
      <Col className="col-12" md={8}>
        <div className="form__form-group">
          <span className="form__form-group-label">Search Query</span>
          <div className="form__form-group-field">
            <Field
              name="search"
              component={renderField}
              type="text"
              label={"Search..."}
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" md={2}>
        <div className="form__form-group">
          <span className="form__form-group-label">From Date</span>
          <div className="form__form-group-field bg-white">
            <Field
              name="from_date"
              component={renderDatePickerField}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" md={2}>
        <div className="form__form-group">
          <span className="form__form-group-label">To Date</span>
          <div className="form__form-group-field bg-white">
            <Field
              name="to_date"
              component={renderDatePickerField}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col className="col-12">
        <div className="mt-3 d-flex justify-content-end">
          <Button type="button" color="outline-secondary" onClick={resetForm} style={{maxHeight: "43px"}} className="mt-1">
            Clear Filters
          </Button>
          <Button type="submit" color="primary" style={{maxHeight: "43px"}} className="mt-1">
            Apply Filters
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ReportsFilterForm = reduxForm({
  form: 'reportsFilterForm',
  initialValues: {
    from_date: new Date()
  },
  enableReinitialize: true,
})(ReportsFilterForm);