import React from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.css';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import '../../scss/app.scss';
import PropTypes from 'prop-types';
import Router from './Router';
import configureStore from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import { history } from '../../utils/history';

i18n.init(i18nextConfig);

const store = configureStore()

export const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    palette: {
      mode: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

const App = () => (
  <Provider store={store}>
    <BrowserRouter basename="/">
      <I18nextProvider i18n={i18n}>
        <ScrollToTop>
          <ConnectedThemeComponent>
            <Router history={history} />
          </ConnectedThemeComponent>
        </ScrollToTop>
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
