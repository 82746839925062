import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import UploadForm from './UploadForm/UploadForm';
import { getUserPhoto, postUserPhoto } from '../../../../../utils/api';
import UserPhoto from './UserPhoto/UserPhoto';
import { useSelector } from 'react-redux';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';


const AccountPhoto = () => {
  const [userPhoto, setUserPhoto] = useState('');
  const [showUserPhoto, setShowUserPhoto] = useState(false);
  const [loading, setLoading] = useState(true);
  const authUser = useSelector(state => state.auth.user);
  const [successDeleteLogoModal, setSuccessDeleteLogoModal] = useState(false);
  const [failedDeleteLogoModal, setFailedDeleteLogoModal] = useState(false);
  const [messageDeleteError, setMessageDeleteError] = useState('');

  useEffect( () => {
    getUserPhoto(authUser.userId, authUser.accessToken)
      .then(res => {
        if(!!res.data.data[0]) {
          setUserPhoto(res.data.data[0]);
          setShowUserPhoto(true);
        }
      }).catch(error => {
        console.error('Error fetching user photo:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authUser.userId]);

  const handleDeletePhoto = () => {
    postUserPhoto('', authUser.accessToken).then((res) => {
      if(res.data.success) {
        const data = {...authUser};
        data.userPhoto = "";
        localStorage.setItem("storage:user", JSON.stringify(data));
        setSuccessDeleteLogoModal(true);
      } else {
        setMessageDeleteError(res.data.errors[0].message);
        setFailedDeleteLogoModal(true);
      }
    })
  }

  return (
    <Card>
      <CardBody>
        {loading ? (
          <div>Loading...</div>
        ) : showUserPhoto ? (
          <UserPhoto userPhoto={userPhoto} setShowUserPhoto={setShowUserPhoto} handleDeletePhoto={handleDeletePhoto} />
        ) : (
          <UploadForm />
        )}
      </CardBody>

      <CustomModal
        successModal={failedDeleteLogoModal}
        toggleCloseModal={() => setFailedDeleteLogoModal(!failedDeleteLogoModal)}
        textModal={messageDeleteError}
        color={"danger"}
      />
      <CustomModal
        successModal={successDeleteLogoModal}
        toggleCloseModal={() => {
          setSuccessDeleteLogoModal(!successDeleteLogoModal);
          window.location.reload();
        }}
        textModal={`Photo was successfully deleted!`}
        color={"success"}
      />
    </Card>
  );
};

export default AccountPhoto;