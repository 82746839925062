import { getWorkHistory } from '../../utils/api';

export const GET_WORK_HISTORY_FETCHING = 'GET_WORK_HISTORY_FETCHING';
export const GET_WORK_HISTORY_FAIL = 'GET_WORK_HISTORY_FAIL';
export const GET_WORK_HISTORY_SUCCESS = 'GET_WORK_HISTORY_SUCCESS';

export const getUsersFetchingAction = (id, query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_WORK_HISTORY_FETCHING});
    getWorkHistory(id, query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getWorkHistorySuccess(data));
      })
      .catch(e => {
        dispatch(getWorkHistoryFail(e));
      })
  }
};

export const getWorkHistoryFail = (e) => {
  return {type: GET_WORK_HISTORY_FAIL, payload: {message: e?.message}}
};

export const getWorkHistorySuccess = (data) => {
  return {type: GET_WORK_HISTORY_SUCCESS, payload: data}
};