export const columns = [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'Date Period',
    accessor: 'year',
  },
  {
    Header: 'Income',
    accessor: 'income',
  },
  {
    Header: 'Currency',
    accessor: 'difference',
  },
  {
    Header: 'Expense Constant',
    accessor: 'expense_constant',
  },
  {
    Header: 'Expense Salary',
    accessor: 'expense_salary',
  },
  {
    Header: 'Bonuses',
    accessor: 'bonuses',
  },
  {
    Header: 'Corp Events',
    accessor: 'corp_events',
  },
  {
    Header: 'Spent Corp Events',
    accessor: 'spent_corp_events',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
  },
  {
    Header: 'Investments',
    accessor: 'investments',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    disableGlobalFilter: true,
  }
];