import React from 'react';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultConfig } from '../config';
import roles from '../config/roles';

export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};

export const removeExpiredItem = itemId => {
  const item = JSON.parse(localStorage.getItem(itemId));

  if (!item) {
    return;
  }

  const { expire } = item;

  const currentTimestamp = Date.now();

  if (currentTimestamp >= expire) {
    localStorage.removeItem(itemId);

    return true;
  }

  return false;
};

export const checkApiUrl = () => {
  const url = window.location.host.split('.');

  if (url[0] === 'staging') {
    return `https://${url[0]}.${url[1]}.api.${defaultConfig.host}`;
  }
  return `https://${url[0]}.api.${defaultConfig.host}`;
};

export const checkCoreApiUrl = () => {
  const url = window.location.host.split('.');

  if (url[0] === 'staging') {
    return `https://${url[0]}.${defaultConfig.coreApiUrl}`;
  }
  return `https://${defaultConfig.coreApiUrl}`;
};

export const isHaveUnicode = str => {
  return /[^\x20-\u00ff]/.test(str);
};

export const getLeadCommentType = comment => {
  let type = 'comment';

  const types = [
    {
      type: 'new_lead',
      comment: 'New lead',
    },
    {
      type: 'qualified',
      comment: 'has qualified the lead',
    },
    {
      type: 'prospected',
      comment: 'has prospected the lead',
    },
    {
      type: 'quoted',
      comment: 'has quoted the',
    },
    {
      type: 'contracted',
      comment: 'has contracted the',
    },
    {
      type: 'killed',
      comment: 'has killed the',
    },
  ];

  for (let i = 0; i < types.length; i++) {
    if (!comment) {
      comment = 'No comment';
    }
    if (comment.includes(types[i].comment)) {
      type = types[i].type;
      break;
    }
  }

  return type;
};

export const downloadFile = (name, filePath) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = name;
  link.click();
};

export const handleFile = async file => {
  const reader = new FileReader();
  file && reader.readAsDataURL(file);

  return (
    file &&
    (await new Promise(resolve => {
      reader.addEventListener('load', e => {
        resolve(e?.currentTarget?.result);
      });
    }))
  );
};

export const objectDiff = (a, b) => _.fromPairs(_.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual));

/* eslint-disable react/display-name */
export function withRouter(Component) {
  return props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  };
}

export function isUserRoleIsAdmin(userRole) {
  return userRole === roles.ADMIN;
}

export const renderField = ({ input, label, type, meta: { touched, error, warning }, disabled, max }) => {
  return (
    <div className="form__form-group-input-wrap">
      <input {...input} placeholder={label} type={type} disabled={disabled} max={max} style={{background: "#fff"}} />
      {touched && ((error && <span className="form__form-group-error">*{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  )
}

export function base64Decode(params) {
    try {
      return atob(params);
    } catch (error) {
      console.error('Error decoding string:', error.message);
      return null;
    }
}

export const backToLogin = () => {
  let host = window?.location.hostname;

  return window.location.replace(host.split('.')[0] === "staging" ? 'https://staging.clodify.com/login' : 'https://clodify.com/login');
}
