import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import validate from './validate';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import renderSelectField from '../../../../../../shared/components/form/Select';
import { renderField } from '../../../../../../utils/helpers';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';


let EditInvoiceForm = ({ handleSubmit, business, methods, selectCurrency, dateStart, dateEnd }) => {
  return (
    <>
      <Col className='col-12'>
        <h5 className='mb-2'>EDIT INVOICE</h5>
      </Col>
      <form className='form form-edit-invoice' onSubmit={handleSubmit}>
        <Col className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Customer</span>
            <div className='form__form-group-field'>
              <Field
                name='customer.name'
                component={renderField}
                type='input'
                disabled={true}
              />
            </div>
          </div>
        </Col>
        <Col className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Business</span>
            <div className='form__form-group-field'>
              <Field
                name='select_business'
                component={renderSelectField}
                id='business'
                options={business?.map(u => {
                  return {
                    value: u.id,
                    label: `${u.name}`,
                  };
                })}
              />
            </div>
          </div>
        </Col>
        <Col className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Payment method</span>
            <div className='form__form-group-field'>
              <Field
                name='select_method'
                component={renderSelectField}
                id='methods'
                options={methods?.map(u => {
                  return {
                    value: u.id,
                    label: `${u.name}`,
                  };
                })}
              />
            </div>
          </div>
        </Col>
        <Col md={6} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Date Start</span>
            <div className='form__form-group-field'>
              <Field
                name='start_date'
                component={renderDatePickerField}
              />
            </div>
          </div>
        </Col>
        <Col md={6} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Date End</span>
            <div className='form__form-group-field'>
              <Field
                name='end_date'
                component={renderDatePickerField}
              />
            </div>
          </div>
        </Col>
        <Col md={4} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Subtotal</span>
            <div className='form__form-group-field d-flex align-items-center'>
              <Field
                name='subtotal'
                component={renderField}
                type='input'
              />
            </div>
          </div>
        </Col>
        <Col md={4} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Discount</span>
            <div className='form__form-group-field d-flex align-items-center'>
              <Field
                name='discount'
                component={renderField}
                type='input'
              />
            </div>
          </div>
        </Col>
        <Col md={4} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Total</span>
            <div className='form__form-group-field d-flex align-items-center'>
              <Field
                name='total'
                component={renderField}
                type='input'
              />
            </div>
          </div>
        </Col>
        <Col md={4} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Currency</span>
            <div className='form__form-group-field d-flex align-items-center'>
              <Field
                name='select_currency'
                component={renderSelectField}
                id='currency'
                options={[{ id: 'USD', name: 'USD' }, { id: 'AUD', name: 'AUD' }, {
                  id: 'EUR',
                  name: 'EUR',
                }, { id: 'GBP', name: 'GBP' }]?.map(u => {
                  return {
                    value: u.id,
                    label: `${u.name}`,
                  };
                })}
              />
            </div>
          </div>
        </Col>
        {
          selectCurrency?.value && selectCurrency?.value !== 'USD' ? (
            <Col md={4} className='col-12'>
              <div className='form__form-group'>
                <span className='form__form-group-label'>Currency Exchange Rate</span>
                <div className='form__form-group-field d-flex align-items-center'>
                  <Field
                    name='exchange_rate'
                    component={renderField}
                    type='input'
                  />
                </div>
              </div>
            </Col>
          ) : null
        }
        <Col md={6} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Notes</span>
            <div className='form__form-group-field d-flex align-items-center'>
              <Field
                name='notes'
                component={renderField}
                type='input'
              />
            </div>
          </div>
        </Col>
        <Col md={6} className='col-12'>
          <div className='form__form-group'>
            <span className='form__form-group-label'>VAT Percentage</span>
            <div className='form__form-group-field'>
              <Field
                name='vat_value'
                component={renderField}
                value={0}
                type='input'
              />
            </div>
          </div>
        </Col>
        <Col sm={12} className='d-flex justify-content-end w-100'>
          <button type='submit' className='btn btn-primary'>Save</button>
        </Col>
      </form>
    </>
  );
};

const selector = formValueSelector('editInvoiceForm');

EditInvoiceForm = reduxForm({
  form: 'editInvoiceForm',
  enableReinitialize: true,
  validate,
})(EditInvoiceForm);

EditInvoiceForm = connect(
  state => {
    let editInvoiceStartDate = state?.editInvoice?.editInvoice[0]?.start_date || '';
    let editInvoiceEndDate = state?.editInvoice?.editInvoice[0]?.end_date || '';

    let splitStartDate = editInvoiceStartDate?.split('/') || [];
    let startDate = `${splitStartDate[1]}/${splitStartDate[0]}/${splitStartDate[2]}`;
    let splitEndDate = editInvoiceEndDate?.split('/') || [];
    let endDate = `${splitEndDate[1]}/${splitEndDate[0]}/${splitEndDate[2]}`;

    return {
      initialValues: {
        ...state.editInvoice.editInvoice[0],
        select_currency: {
          value: state?.editInvoice?.editInvoice[0]?.currency,
          label: state?.editInvoice?.editInvoice[0]?.currency,
        },
        start_date: editInvoiceStartDate ? new Date(startDate) : null,
        end_date: editInvoiceEndDate ? new Date(endDate) : null,
        vat_value: 0,
      },
      editInvoice: state.editInvoice.editInvoice[0],
      selectCurrency: selector(state, 'select_currency'),
    };
  })(EditInvoiceForm);

export default EditInvoiceForm;