export const getHumanTime = (timestamp, settings) => {
    
    // Convert to a positive integer.
    const time = Math.abs(timestamp);
    // Define humanTime and units
    let humanTime, units;

    // If there are years
    if (time > (1000 * 60 * 60 * 24 * 365)) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
        units = settings.years;

        // If there are months
    } else if (time > (1000 * 60 * 60 * 24 * 30)) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
        units = settings.months;

        // If there are weeks
    } else if (time > (1000 * 60 * 60 * 24 * 7)) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
        units = settings.weeks;

        // If there are days
    } else if (time > (1000 * 60 * 60 * 24)) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
        units = settings.days;
        
        // If there are hours
    } else if (time > (1000 * 60 * 60)) {
        humanTime = parseInt(time / (1000 * 60 * 60), 10);
        units = settings.hours;

        // If there are minutes
    } else if (time > (1000 * 60)) {
        humanTime = parseInt(time / (1000 * 60), 10);
        units = settings.minutes;

        // Otherwise, use seconds
    } else {
        humanTime = parseInt(time / (1000), 10);
        units = settings.seconds;
    }

    return humanTime + ' ' + units;
};

export const getRelativeTime = (createdTimestamp, settings) => {
    const now = new Date().getTime();
    const howLongAgo = now - createdTimestamp;

    return getHumanTime(howLongAgo, settings);
};
