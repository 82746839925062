import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import BusinessesPaymentMethodEditForm from './BusinessesPaymentMethodEditForm/BusinessesPaymentMethodEditForm';
import { getBusinessPaymentMethodEditAction } from '../../../../../../redux/actions/editBusinessesPaymentMethodActions';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { putBusinessPaymentMethods } from '../../../../../../utils/api';
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';

const BusinessesPaymentMethodEdit = () => {
  const { id, method_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector(state => state.auth.user);
  const [failedEditModal, setFailedDeleteModal] = useState(false);
  const [messageEditError, setMessageEditError] = useState('');
  const [successEditModal, setSuccessEditModal] = useState(false);

  useEffect(() => {
    dispatch(getBusinessPaymentMethodEditAction(id, method_id));
  }, [id, method_id, dispatch]);

  const handleSubmit = (values) => {
    putBusinessPaymentMethods(id, method_id, values, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessEditModal(!successEditModal);
      } else {
        setMessageEditError(res.data.errors[0].message);
        setFailedDeleteModal(!failedEditModal);
      }
    })
  }

  return (
    <Container>
      <Row>
        <BusinessesPaymentMethodEditForm onSubmit={handleSubmit} />
      </Row>

      <CustomModal
        successModal={failedEditModal}
        toggleCloseModal={() => setFailedDeleteModal(!failedEditModal)}
        textModal={messageEditError}
        color={"danger"}
      />
      <CustomModal
        successModal={successEditModal}
        toggleCloseModal={() => {
          setSuccessEditModal(!successEditModal);
          navigate(`/dashboard/businesses/${id}/methods`)
        }}
        textModal={`Payment method №${method_id} was successfully changed!`}
        color={"success"}
      />
    </Container>
  );
};

export default BusinessesPaymentMethodEdit;