import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import _, { cloneDeep } from 'lodash';

import { reduxForm } from 'redux-form';
import Loading from '../../../shared/components/Loading';
import Alert from '../../../shared/components/Alert';
import { getLeadRequest } from '../../../redux/actions/leadsSingleActions';

import TimeLine from './TimeLine';
import InfoTable from './InfoTable';
import InfoTableEdit from './InfoTableEdit';

import { putUpdateLead } from '../../../utils/api';
import { objectDiff } from '../../../utils/helpers';

const LeadsSingle = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  const { result, loading, errors } = useSelector((state) => state.singleLead);
  const authUser = useSelector(state => state.auth.user);

  const [editMode, setEditMode] = React.useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [mesErrors, setMesErrors] = useState([]);

  const [timelineItems, setTimelineItems] = useState([]);
  // const [leadId, setLeadId] = useState(0);
  // const [startDate, setStartDate] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getCleanObjects = (obj) => {
    return _.mapValues({
      budget: obj.budget,
      description: obj.description,
      email: obj.email,
      phone: obj.phone,
      start_date: obj.start_date,
      status: obj.status,
      name: obj.name,
      estimate: obj.estimate,
      source: obj.source
    }, value => !value ? '' : String(value))
  }

  const handleSubmitUpdateLead = async (value) => {
    let diffs = _.keys(objectDiff(getCleanObjects(value), getCleanObjects(result.result)));
    putUpdateLead(
      value.budget,
      value.description,
      value.email,
      value.phone,
      value.start_date,
      value.status,
      value.lead_id,
      value.name,
      value.estimate,
      value.source,
      [_.join(diffs, ', '), `${_.size(diffs) > 1 ? "have" : "has"} been changed`].join(' '),
      authUser.accessToken
    ).then((res) => {
      // eslint-disable-next-line no-unused-expressions
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getLeadRequest(id));
    }
  }, [dispatch, id, refreshCounter]);

  useEffect(() => {
    // Sort history items by last created.
    if (result && result.result.history && result.result.history.length) {
      const items = cloneDeep(result.result.history);
      items.sort((a, b) => {
        const aMsUTC = Date.parse(a.datetime);
        const bMsUTC = Date.parse(b.datetime);

        if (aMsUTC && bMsUTC) {
          return bMsUTC - aMsUTC;
        }

        return 0;
      });

      setTimelineItems(items);
    }
  }, [result]);

  if (errors) {
    return (
      <Alert color="danger" className="alert--bordered">
        <p>{errors}</p>
      </Alert>
    );
  }

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (!result) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <div className="leads-single">
            <div className="typography-card">
              <h3 className="leads-single__title">{result.name}</h3>
            </div>
            <div className="tabs tabs--justify tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => toggle('1')}
                    >
                      {t('leads.tabs.title_general')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => toggle('2')}
                    >
                      {t('leads.tabs.title_history')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Card>
                      <CardBody>
                        {editMode ? (
                          <InfoTable data={result.result} />
                        ) : (
                          <InfoTableEdit
                            data={result.result}
                            handleSubmit={handleSubmitUpdateLead}
                            setEditMode={setEditMode}
                            editMode={editMode}
                          />
                        )}
                        {editMode && (
                          <Button
                            color="primary"
                            className="mt-3"
                            onClick={() => setEditMode(!editMode)}
                          >
                            EDIT
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="2">
                    <TimeLine items={timelineItems} leadId={result.result.id} onSuccess={() => setRefreshCounter(refreshCounter + 1)}/>
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <div className="form__form-group-error">
              {mesErrors?.map((r) => r.message)}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default reduxForm({
  form: 'comment_form',
})(LeadsSingle);
