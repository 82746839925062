import {
  GET_BUSINESS_PAYMENT_METHOD_EDIT_FAIL,
  GET_BUSINESS_PAYMENT_METHOD_EDIT_FETCHING,
  GET_BUSINESS_PAYMENT_METHOD_EDIT_SUCCESS
} from '../actions/editBusinessesPaymentMethodActions';

const initialState = {
  paymentMethod: [],
  loading: false,
  errors: ''
};


const editBusinessPaymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_PAYMENT_METHOD_EDIT_FETCHING:
      return { ...state, loading: true };
    case GET_BUSINESS_PAYMENT_METHOD_EDIT_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_BUSINESS_PAYMENT_METHOD_EDIT_SUCCESS:
      return { ...state, paymentMethod: action.payload.paymentMethod, loading: false, errors: '' };
    default:
      return state;
  }
};

export default editBusinessPaymentMethodReducer;
