const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name field shouldn’t be empty';
  } else if(!/^[a-zA-Z ]*$/.test(values.name)) {
    errors.name = 'Invalid data type';
  } else if(values.name?.length > 50) {
    errors.name = 'Name field cannot exceed 50 characters in length';
  }

  if (!!values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  } else if(values.email?.length > 50) {
    errors.email = 'Email field cannot exceed 50 characters in length';
  }

  return errors;
};

export default validate;
