import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import FormAddNewSubcontractor from '../FormAddSubcontractor';
import FormEditSubcontractor from '../FormEditSubcontractor';
import ConfirmModal from '../ConfirmModal';
import { FullWideNotification } from '../../../../../shared/components/Notification';
import {
  getSubcontractorsAction,
  addSubcontractorsAction,
  modalSubcontractorsFail,
  editSubcontractorAction,
  deleteSubcontractorAction,
  getSubcontractorUsers,
} from '../../../../../redux/actions/subcontractorsActions';
import CustomAlert from '../../../../../shared/components/customComponents/CustomAlert/CustomAlert';
import { columns } from './tableHeadConfig';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import DataReactTable from '../../../../Tables/DataTable/components/DataReactTable';
import roles from '../../../../../config/roles';

const Subcontractors = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    subcontractors,
    errors,
    modalLoading,
    modalErrors,
    totalRecords,
    users,
    userLoading,
    userError,
    totalUsers,
    userLoaded,
  } = useSelector((state) => state.subcontractors);
  const { userId, role } = useSelector((state) => state.auth.user);

  const [modal, setModal] = useState(false);
  const [editElement, setEditElement] = useState({});
  const [deleteElement, setDeleteElement] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '' });
  const [userQuery, setUserQuery] = useState('');

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit,
    };

    if (role === roles.ADMIN || role === roles.SALES || role === roles.FIN) {
      dispatch(getSubcontractorsAction(id, updatedQuery));
    } else {
      setShowNotification(true);
    }
  }, [currentPage, pageLimit, dispatch, id, role, query]);

  useEffect(() => {
    if (role === roles.ADMIN) {
      dispatch(getSubcontractorUsers(userQuery, userLoaded));
    }
  }, [dispatch, userQuery]);

  const toggle = () => {
    dispatch(modalSubcontractorsFail([]));
    setModal(!modal);
  };

  const toggleEdit = (data) => {
    dispatch(modalSubcontractorsFail([]));

    if (!_.isEmpty(data)) {
      return setEditElement(data);
    }

    setEditElement({});
  };

  const toggleDelete = (data) => {
    if (!_.isEmpty(data)) {
      return setDeleteElement(data);
    }

    setDeleteElement({});
  };

  const handleSubmit = (values) => {
    let params = {
      financial_report_id: id,
      user_owner_id: userId,
      title: values.title,
      amount: values.amount,
    };

    if (role === roles.ADMIN && values.select_user) {
      params.user_owner_id = values.select_user.value;
      params.added_by_id = userId;
    }

    dispatch(addSubcontractorsAction(id, params, toggle));
  };

  const handleEdit = (values) => {
    dispatch(
      editSubcontractorAction(
        id,
        editElement.id,
        values.financial_report_id,
        values.user_owner_id,
        values.title,
        values.amount,
        toggleEdit,
      ),
    );
  };

  const handleDelete = (subcontactorId) => {
    dispatch(deleteSubcontractorAction(id, subcontactorId, toggleDelete));
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    setQuery({ ...query, search_query: value });
  }, 600);

  const renderBody = () => {
    if (errors.length) {
      return (
        <Row>
          <Col md={12}>
            {errors.map((error, i) => {
              return (
                <CustomAlert
                  color="danger"
                  className="alert--bordered"
                  icon
                  key={i}
                >
                  <p>
                    <span className="bold-text">{error.param}</span>{' '}
                    {error.message}
                  </p>
                </CustomAlert>
              );
            })}
          </Col>
        </Row>
      );
    }

    return (
      <Fragment>
        <Row>
          {role !== roles.FIN && (
            <Col className="col-12 d-flex justify-content-between flex-column flex-lg-row">
              <h3 className="page-title">
                {t('financial_reports_dashboard.page_title')} #{id}
              </h3>
              <Button
                className="mt-3 mt-lg-0"
                color="primary"
                onClick={toggle}
                style={{ margin: '0 0 20px auto', width: 'max-content' }}
              >
                {t('financial_reports_dashboard.add_button')}
              </Button>
            </Col>
          )}
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {subcontractors.length > 0 ? (
                  <DataReactTable
                    tableName={'Subcontractors'}
                    sortTable={false}
                    search={true}
                    searchValue={getSearchValue}
                    pagination={{
                      onChangePageSize: handleChangePageSize,
                      onChangePage: handleChangePage,
                      currentPage,
                      totalRecords,
                      pageLimit,
                    }}
                    emptyStateMessage={t('empty_states.subcontractors')}
                    reactTableData={{
                      tableHeaderData: columns.filter((col) =>
                        col.roles.includes(role),
                      ),
                      tableRowsData: subcontractors?.map((c) => {
                        return {
                          ...c,
                          id: `${c.id}`,
                          amount: `${c.amount}`,
                          actions: (
                            <div className={'tableActions'}>
                              <button
                                onClick={() => {
                                  toggleDelete(c);
                                }}
                                style={{
                                  border: 0,
                                  background: 'transparent',
                                }}
                              >
                                <DeleteIcon size={18} color="#b1c3c8" />
                              </button>
                              <button
                                onClick={() => toggleEdit(c)}
                                style={{
                                  border: 0,
                                  background: 'transparent',
                                }}
                              >
                                <PencilIcon size={18} color="#b1c3c8" />
                              </button>
                            </div>
                          ),
                        };
                      }),
                    }}
                  />
                ) : (
                  <CustomAlert color="warning" className="alert--bordered" icon>
                    <p>
                      <span className="bold-text">Warning!</span> No found
                      subcontractors for #{id}
                    </p>
                  </CustomAlert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Add Form */}
        {modal && (
          <FormAddNewSubcontractor
            modal={modal}
            toggle={toggle}
            modalErrors={modalErrors}
            onSubmit={handleSubmit}
            isSubmitting={modalLoading}
            users={users}
            userLoading={userLoading}
            userError={userError}
            role={role}
            totalUsers={totalUsers}
            setUserQuery={setUserQuery}
          />
        )}
        {/* Edit Form */}
        {editElement.id && (
          <FormEditSubcontractor
            toggle={toggleEdit}
            modal={editElement}
            errors={modalErrors}
            loading={modalLoading}
            onSubmit={handleEdit}
            data={{
              financial_report_id: id,
              user_owner_id: editElement.added_by.id,
              title: editElement.title,
              amount: editElement.amount,
            }}
          />
        )}
        {/* Delete Form */}
        <ConfirmModal
          color="danger"
          btn="Default"
          message="Are you sure you want to delete it?"
          toggle={toggleDelete}
          modal={deleteElement.id}
          onNext={handleDelete}
        />
      </Fragment>
    );
  };

  return (
    <Container className="dashboard">
      {showNotification ? (
        <div
          style={{
            position: 'absolute',
            top: '60px',
            left: '0',
            zIndex: '999',
          }}
        >
          <FullWideNotification
            color="danger"
            message="You do not have sufficient permissions to view this page!"
          />
        </div>
      ) : (
        <Fragment>{renderBody()}</Fragment>
      )}
    </Container>
  );
};

export default Subcontractors;
