import React, { useEffect, useState } from 'react';
import { putEmailTemplate } from '../../../../../utils/api';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const Template = ({templates, refresh}) => {
  const [editing, setEditing] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFail, setModalFail] = useState(false);
  const { t } = useTranslation('common');
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    if(templates.body || templates.subject) {
      setTextareaValue(templates?.body);
      setSubjectValue(templates?.subject);
    }

  }, [templates.body, templates.subject])

  const handleEdit = () => {
    setEditing(!editing);
  }

  const handleChangeTextarea = (e) => {
    setTextareaValue(e.target.value);
  }

  const handleChangeInput = (e) => {
    setSubjectValue(e.target.value);
  }

  const handleSubmit = () => {
    putEmailTemplate(templates.id, subjectValue, templates.reply_to, textareaValue, authUser.accessToken)
      .then(res => {
        if (res.data.success) {
          setModalSuccess(!modalSuccess);
        } else {
          setModalFail(!modalFail);
        }
      })
  }

  return (
    <div className="template">
      <label htmlFor='subject'>Subject: </label>
      <input type='text' id="subject" value={subjectValue} onChange={handleChangeInput}/>
      {
        editing ? <textarea cols='30' rows='10' value={textareaValue} onChange={handleChangeTextarea}/>
          : <div dangerouslySetInnerHTML={{__html: templates.body}} />
      }
      <div className="wrapper-btn">
        {
          editing ? <>
            <button className="btn-success btn" onClick={handleSubmit}>{t('email_template.btn_save')}</button>
            <button className="btn-danger btn" onClick={handleEdit}>{t('email_template.btn_close')}</button>
          </> : <button className="btn-primary btn" onClick={handleEdit}>{t('email_template.btn_edit')}</button>
        }
      </div>
      {
         <Modal
           modal={modalSuccess}
           setModal={setModalSuccess}
           refresh={refresh}
           color="success"
           title="Well Done!"
           colored
           message={t('email_template.success_modal_message')}
        />
      }
      {
        <Modal
          modal={modalFail}
          setModal={setModalFail}
          refresh={refresh}
          color="danger"
          title="Stop!"
          colored
          message={t('email_template.fail_modal_message')}
        />
      }
    </div>
  );
};

export default Template;