const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name field shouldn’t be empty';
  } else if(values.name?.length > 50) {
    errors.name = 'Name field cannot exceed 50 characters in length';
  }

  if (!values.address) {
    errors.address = 'Address field shouldn’t be empty';
  }

  if (!values.represented_by) {
    errors.represented_by = 'Represented By field shouldn’t be empty';
  }

  if (!values.bank_information) {
    errors.bank_information = 'Bank Information field shouldn’t be empty';
  }

  return errors;
};

export default validate;
