import {
  GET_ALL_LEADS_FAIL,
  GET_ALL_LEADS_FETCHING,
  GET_ALL_LEADS_SUCCESS,
} from '../actions/leadsPageActions';

const initialState = {
  leads: [],
  loading: false,
  errors: '',
  totalRecords: 0
};

const leadsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEADS_FETCHING:
      return { ...state, loading: true };
    case GET_ALL_LEADS_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_ALL_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload.leads,
        loading: false,
        errors: '',
        totalRecords: Number(action.payload.total_records)
      };
    default:
      return state;
  }
};

export default leadsPageReducer;
