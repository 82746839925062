import {
	AUTHENTICATE,
	AUTHENTICATE_SUCCESS,
	AUTHENTICATE_ERROR_AUTH, LOGIN_AS,
	AUTHENTICATE_CHECKED,
	LOGIN_AS_SUCCESS,
	LOGIN_AS_ERROR,
	BACK_TO_ADMIN,
} from '../actions/authActions';
import { backToLogin } from '../../utils/helpers';

const initialState = {
	user: {},
	loading: false,
	checked: false,
	errors: false,
	loginAsBoolean: false,
	loginAsError: '',
	impersonatedUser: {}
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return { ...state, loading: true, checked: true };
		case AUTHENTICATE_CHECKED:
			return { ...state, checked: true, user: action.payload.user };
		case AUTHENTICATE_ERROR_AUTH:
			backToLogin();

			return { ...state, loading: false, errors: action.payload.errors };
		case AUTHENTICATE_SUCCESS:
			localStorage.setItem("storage:user", JSON.stringify(action.payload.user));
			return { ...state, user: action.payload.user, loading: false, errors: false };
		case LOGIN_AS:
			return { ...state, loading: true, loginAsError: ''};
		case LOGIN_AS_SUCCESS:
			return { 
				...state, 
				user: action.payload.user, 
				loading: false, 
				loginAsError: '', 
				loginAsBoolean: true
			};
		case LOGIN_AS_ERROR:
			return { ...state, loading: false, loginAsError: action.payload.error };
		case BACK_TO_ADMIN:
			return {
				...state,
				loginAsBoolean: false,
				user: JSON.parse(localStorage.getItem('storage:user')),
			}
		default:
			return state;
	}
};

export default authReducer;
