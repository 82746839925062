import {
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FETCHING,
  GET_EMAIL_TEMPLATES_FAIL,
} from '../actions/emailTemplatesActions';

const initialState = {
  emailTemplates: [],
  loading: false,
  errors: '',
};

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES_FETCHING:
      return { ...state, loading: true };
    case GET_EMAIL_TEMPLATES_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: [...action.payload.emailTemplates],
        loading: false,
        errors: '',
      };
    default:
      return state;
  }
};

export default emailTemplatesReducer;
