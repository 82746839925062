import {
  GET_USER_FAIL,
  GET_USER_FETCHING,
  GET_USER_SUCCESS
} from '../actions/editUserActions';

const initialState = {
  editUser: [],
  loading: false,
  errors: ''
};


const editUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_FETCHING:
      return { ...state, loading: true };
    case GET_USER_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_USER_SUCCESS:
      return { ...state, editUser: action.payload.user, loading: false, errors: '' };
    default:
      return state;
  }
};

export default editUserReducer;
