import React, {useEffect} from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import validate from './validate.js';
import "./EditForm.scss";
import { renderField } from '../../../../../utils/helpers';


const VerticalForm = (props) => {
    const {client, initialize, handleSubmit} = props;

    useEffect(() => {
        initialize(client);
    }, [client, initialize]);


    return (
        <Col>
            <Card>
                <CardBody>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Company(Name)</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    placeholder="Company(Name)"
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">E-mail</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                    placeholder="example@mail.com"
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">First name</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="first_name"
                                    component={renderField}
                                    type="text"
                                    placeholder="First name"
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Last name</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="last_name"
                                    component={renderField}
                                    type="text"
                                    placeholder="Last name"
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            <Button color="primary" type="submit">Save</Button>
                            <Button type="button" onClick={props.handleHideEditForm}>
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>
    );
};


export default reduxForm({
    form: 'edit_form', // a unique identifier for this form
    validate,
})(VerticalForm);
