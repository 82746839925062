import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import DataReactTable from './../../Tables/DataTable/components/DataReactTable';
import { useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import EyePlusIcon from 'mdi-react/EyePlusIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import CashIcon from 'mdi-react/CashIcon';
import GiftIcon from 'mdi-react/GiftIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import LockIcon from 'mdi-react/LockIcon';
import LockAlertIcon from 'mdi-react/LockAlertIcon';
import { getFinancialReportsAction } from '../../../redux/actions/financialReportsActions';
import { NavLink } from 'react-router-dom';
import TableConfig from './components/tableConfig';
import FinancialReportBonusesModal from './components/FinancialReportBonusesModal/FinancialReportBonusesModal';
import {
  putLockFinancialReport,
  putPreLockFinancialReport,
  putUnPreLockFinancialReport,
} from '../../../utils/api';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import FinancialReportViewModal from './components/FinancialReportViewModal/FinancialReportViewModal';
import moment from 'moment';
import _ from 'lodash';
import ConfirmModal from '../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import ReactTooltip from 'react-tooltip';
import Roles from '../../../config/roles';
import roles from '../../../config/roles';

const FinancialReports = () => {
  const { t } = useTranslation('common');
  const { financial_reports, errors, totalRecords } = useSelector(
    (state) => state.financial_reports,
  );
  const authUser = useSelector(state => state.auth.user);
  const role  = authUser.role;
  const dispatch = useDispatch();
  const columns = TableConfig().columns;
  const [modal, setModal] = useState(false);
  const [modalLock, setModalLock] = useState(false);
  const [modalLockText, setModalLockText] = useState('');
  const [successModalLock, setSuccessModalLock] = useState(false);
  const [modalUnPreLock, setModalUnPreLock] = useState(false);
  const [modalUnPrelockText, setModalUnPrelockText] = useState('');
  const [successModalUnPreLock, setSuccessModalUnPreLock] = useState(false);
  const [modalPrelock, setModalPrelock] = useState(false);
  const [modalPrelockText, setModalPrelockText] = useState('');
  const [successModalPreLock, setSuccessModalPreLock] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [incomeId, setIncomeId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [messageError, setMessageError] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [query, setQuery] = useState({
    start: 0,
    limit: 10,
    search_query: '',
    user_id: '',
  });

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit,
    };

    dispatch(getFinancialReportsAction(updatedQuery));
  }, [currentPage, pageLimit, query, dispatch, refreshCounter]);

  const toggle = (id) => {
    setIncomeId(id);
    setModal(!modal);
  };

  const toggleCloseModal = () => {
    setSuccessModal(!successModal);
  };

  const toggleView = (id) => {
    setIncomeId(id);
    setModalView(!modalView);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    if (value === '0') return;

    setQuery({ ...query, search_query: value });
  }, 1000);

  const onPreLockReport = () => {
    putPreLockFinancialReport(deleteId, authUser.accessToken).then((res) => {
      if (res.data.success) {
        setSuccessModalPreLock(!successModalPreLock);
      } else {
        setMessageError(res.data.errors[0].message);
        setSuccessModal(!successModal);
      }
    });
  };

  const onLockReport = () => {
    putLockFinancialReport(deleteId, authUser.accessToken).then((res) => {
      if (res.data.success) {
        setSuccessModalLock(!successModalLock);
      } else {
        setMessageError(res.data.errors[0].message);
        setSuccessModal(!successModal);
      }
    });
  };

  const onUnPreLockReport = () => {
    putUnPreLockFinancialReport(deleteId, authUser.accessToken).then((res) => {
      if (res.data.success) {
        setSuccessModalUnPreLock(!successModalUnPreLock);
      } else {
        setMessageError(res.data.errors[0].message);
        setSuccessModal(!successModal);
      }
    });
  };

  const renderViewAction = (c) => {
    if (!c.is_locked) {
      return null;
    }

    return (
      <>
        <ReactTooltip id={`eyeIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.view') + ' ' + c.id}
          </span>
        </ReactTooltip>
        <button
          data-tip
          data-for={`eyeIcon-${c.id}`}
          onClick={() => toggleView(c.id)}
        >
          <EyeIcon color="#b1c3c8" size={18} />
        </button>
      </>
    );
  };

  const renderEditAction = (c) => {
    if (role === Roles.SALES) {
      return null;
    }

    if (c.is_locked || c.is_prelocked) {
      return null;
    }

    return (
      <NavLink to={`/dashboard/financial-reports/${c.id}/edit`}>
        <ReactTooltip id={`pencilIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.edit') + c.id}
          </span>
        </ReactTooltip>
        <button data-tip data-for={`pencilIcon-${c.id}`}>
          <PencilIcon color="#b1c3c8" size={18} />
        </button>
      </NavLink>
    );
  };

  const renderIncomeAction = (c) => {
    if (role === Roles.FIN) {
      return null;
    }

    if (c.is_locked || c.is_prelocked) {
      return null;
    }

    return (
      <NavLink to={`/dashboard/financial-reports/${c.id}/incomes`}>
        <ReactTooltip id={`usdIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>{t('reactTooltips.incomes')}</span>
        </ReactTooltip>
        <button data-tip data-for={`usdIcon-${c.id}`}>
          <CurrencyUsdIcon color="#b1c3c8" size={18} />
        </button>
      </NavLink>
    );
  };

  const renderAddIncomeAction = (c) => {
    if (role === Roles.FIN) {
      return null;
    }

    if (c.is_locked || c.is_prelocked) {
      return null;
    }

    return (
      <NavLink to={`/dashboard/financial-reports/${c.id}/automatic-income`}>
        <ReactTooltip id={`cashIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>{t('reactTooltips.addIncome')}</span>
        </ReactTooltip>
        <button data-tip data-for={`cashIcon-${c.id}`}>
          <CashIcon color="#b1c3c8" size={18} />
        </button>
      </NavLink>
    );
  };

  const renderBonusesAction = (c) => {
    if (role === Roles.FIN) {
      return null;
    }

    if (!c.is_prelocked) {
      return null;
    }
    return (
      <>
        <ReactTooltip id={`giftIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.bonuses') + c.id}
          </span>
        </ReactTooltip>
        <button
          data-tip
          data-for={`giftIcon-${c.id}`}
          onClick={() => toggle(c.id)}
        >
          <GiftIcon color="#b1c3c8" size={18} />
        </button>
      </>
    );
  };

  const renderPrelockAction = (c) => {
    if (role !== roles.ADMIN) {
      return null;
    }

    if (c.is_locked || c.is_prelocked) {
      return null;
    }

    return (
      <>
        <ReactTooltip id={`lockOpenIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.prelock') + c.id}
          </span>
        </ReactTooltip>
        <button
          onClick={() => {
            setDeleteId(c.id);
            setModalPrelockText(c.report_date.split(' ~ ')[0]);
            setModalPrelock(!modalPrelock);
          }}
          data-tip
          data-for={`lockOpenIcon-${c.id}`}
        >
          <LockOpenIcon color="#b1c3c8" size={18} />
        </button>
      </>
    );
  };

  const renderUnPrelockAction = (c) => {
    if (role !== roles.ADMIN) {
      return null;
    }

    if (!c.is_prelocked || c.is_locked) {
      return null;
    }

    return (
      <>
        <ReactTooltip id={`lockAlertIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.unPrelock') + c.id}
          </span>
        </ReactTooltip>
        <button
          data-tip
          data-for={`lockAlertIcon-${c.id}`}
          onClick={() => {
            setDeleteId(c.id);
            setModalUnPrelockText(c.report_date.split(' ~ ')[0]);
            setModalUnPreLock(!modalUnPreLock);
          }}
        >
          <LockAlertIcon color="#b1c3c8" size={18} />
        </button>
      </>
    );
  };

  const renderLockAction = (c) => {
    if (role !== roles.ADMIN) {
      return null;
    }

    if (!c.is_prelocked || c.is_locked) {
      return null;
    }


    return (
      <>
        <ReactTooltip id={`lockIcon-${c.id}`} type="dark">
          <span style={{ color: 'white' }}>
            {t('reactTooltips.lock') + c.id}
          </span>
        </ReactTooltip>
        <button
          onClick={() => {
            setDeleteId(c.id);
            setModalLockText(c.report_date.split(' ~ ')[0]);
            setModalLock(!modalLock);
          }}
          data-tip
          data-for={`lockIcon-${c.id}`}
        >
          <LockIcon color="#b1c3c8" size={18} />
        </button>
      </>
    );
  };

  return (
    <Container className="financialReports">
      <Row>
        <Col md={12}>
          <DataReactTable
            tableName={'Financial reports'}
            tableClass={'financialReports'}
            sortTable={false}
            search={true}
            searchValue={getSearchValue}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            }}
            emptyStateMessage={t('empty_states.reports')}
            reactTableData={{
              tableHeaderData: _.filter(
                columns,
                (c) => c.roles.includes(role),
              ),
              tableRowsData: financial_reports?.map((c) => {
                return {
                  ...c,
                  actions: (
                    <div className={'tableActions'}>
                      <NavLink
                        to={`/dashboard/financial-reports/${c.id}/subcontractors`}
                      >
                        <ReactTooltip id={`eyePlusIcon-${c.id}`} type="dark">
                          <span style={{ color: 'white' }}>
                            {t('reactTooltips.subcontractor') + c.id}
                          </span>
                        </ReactTooltip>
                        <button data-tip data-for={`eyePlusIcon-${c.id}`}>
                          <EyePlusIcon color="#b1c3c8" size={18} />
                        </button>
                      </NavLink>
                      {renderViewAction(c)}
                      {renderEditAction(c)}
                      {renderIncomeAction(c)}
                      {renderAddIncomeAction(c)}
                      {renderBonusesAction(c)}
                      {renderPrelockAction(c)}
                      {renderUnPrelockAction(c)}
                      {renderLockAction(c)}
                    </div>
                  ),
                };
              }),
            }}
          />
          {errors ? <div>{errors}</div> : null}
          {modal && (
            <FinancialReportBonusesModal
              toggle={toggle}
              modal={modal}
              incomeId={incomeId}
            />
          )}
          {modalView && (
            <FinancialReportViewModal
              toggle={toggleView}
              modal={modalView}
              incomeId={incomeId}
            />
          )}
          {
            <ConfirmModal
              color="primary"
              btn="Default"
              message={`Are you sure you want to Lock the report for ${moment(
                modalLockText.split('/').reverse().join(''),
              ).format('MMMM YYYY')}?`}
              toggle={() => setModalLock(!modalLock)}
              modal={modalLock}
              onNext={onLockReport}
            />
          }
          {
            <ConfirmModal
              color="primary"
              btn="Default"
              message={`Are you sure you want to Prelock the report for ${moment(
                modalPrelockText.split('/').reverse().join(''),
              ).format('MMMM YYYY')}?`}
              toggle={() => setModalPrelock(!modalPrelock)}
              modal={modalPrelock}
              onNext={onPreLockReport}
            />
          }
          {
            <ConfirmModal
              color="primary"
              btn="Default"
              message={`Are you sure you want to Un Prelock the report for ${moment(
                modalUnPrelockText.split('/').reverse().join(''),
              ).format('MMMM YYYY')}?`}
              toggle={() => setModalUnPreLock(!modalUnPreLock)}
              modal={modalUnPreLock}
              onNext={onUnPreLockReport}
            />
          }
          {
            <CustomModal
              successModal={successModal}
              toggleCloseModal={toggleCloseModal}
              textModal={messageError}
              color={'danger'}
            />
          }
          <CustomModal
            successModal={successModalPreLock}
            toggleCloseModal={() => {
              setSuccessModalPreLock(!successModalPreLock);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Report ${moment(
              modalPrelockText.split('/').reverse().join(''),
            ).format('MMMM YYYY')} was successfully Prelock! `}
            color={'success'}
          />
          <CustomModal
            successModal={successModalUnPreLock}
            toggleCloseModal={() => {
              setSuccessModalUnPreLock(!successModalUnPreLock);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Report ${moment(
              modalUnPrelockText.split('/').reverse().join(''),
            ).format('MMMM YYYY')} was successfully Un Prelock! `}
            color={'success'}
          />
          <CustomModal
            successModal={successModalLock}
            toggleCloseModal={() => {
              setSuccessModalLock(!successModalLock);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Report ${moment(
              modalLockText.split('/').reverse().join(''),
            ).format('MMMM YYYY')} was successfully Lock! `}
            color={'success'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialReports;
