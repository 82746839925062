import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from "reactstrap";
import { getProjectId, getProjects } from "../../../../utils/api";
import SelectProjects from "./SelectProjects/SelectProjects";
import LinearGraph from "./LinearGraph/LinearGraph";
import { FullWideNotification } from "../../../../shared/components/Notification";
import CustomModal from '../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useSelector } from 'react-redux';
import roles from '../../../../config/roles';

const StatisticProjects = () => {
  const authUser = useSelector(state => state.auth.user);
  const [projects, setProjects] = useState([]);
  const [statisticsProject, setStatisticsProject] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFailSuccess, setShowAlertFailSuccess] = useState(false);
  const isUserHasPermission = authUser.role === roles.ADMIN || authUser.role === roles.FIN || authUser.role === roles.SALES;
  const date = Array.from({ length: new Date().getFullYear() - 2013 }, (_, i) => new Date().getFullYear() - i);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalRecordsProjects, setTotalRecordsProjects] = useState(0);
  const isInitialRender = useRef(true);
  const [queryProjects, setQueryProjects] = useState({search_query: ''});


  // Initial fetching.
  useEffect(() => {
    const modifiedQuery = {search_query: queryProjects.search_query};

    getProjects(modifiedQuery, authUser.accessToken)
      .then(res => {
        if (res.data.success) {
          setProjects(res.data.data?.projects);

          if (isInitialRender.current) {
            setTotalRecordsProjects(Number(res.data.data.total_records));
            isInitialRender.current = false;
          }
        } else {
          setErrors(res.data.errors);
          setShowAlertFailSuccess(true);
          setTimeout(() => { setShowAlertFailSuccess(false) }, 5000);
        }
      })
  }, [queryProjects.search_query]);

  useEffect(() => {
    if (isUserHasPermission) {
      return;
    }
    setShowAlert(true);
    setTimeout(() => { setShowAlert(false) }, 5000);
  }, [authUser.role, isUserHasPermission]);

  const onSubmit = (val) => {
    getProjectId(val.select_year.value, val.select_project.id, authUser.accessToken).then(res => {
      if (res.data.success) {
        setStatisticsProject(res.data.data?.projectStatistics);
      } else {
        setErrorMessage(res.data.errors[0].message);
        setErrorModal(true);
      }}
    );
  }

  return (
    <Container>
      {isUserHasPermission ? (
        <Row>
          <Col sm={12}>
            <SelectProjects
              projects={projects}
              onSubmit={onSubmit}
              date={date}
              initialValues={{
                select_year: {
                  value: date[0],
                  label: date[0],
                }
              }}
              queryProjects={queryProjects}
              setQueryProjects={setQueryProjects}
              totalRecordsProjects={totalRecordsProjects}
            />
          </Col>
          <Col>
            <LinearGraph statisticsProject={statisticsProject} />
          </Col>
        </Row>
      ) : (showAlert ? (<div style={{ top: "60px", left: "0", position: "absolute", zIndex: "999" }}>
        <FullWideNotification
          color={"danger"}
          message="You are not ADMIN, FIN or SALES!" />
      </div>) : null)
      }
      {   showAlertFailSuccess ? (
        <div style={{ top: showAlert ? "120px" : "60px", left: "0", position: "absolute", zIndex: "999" }}>
          <FullWideNotification
            color={"danger"}
            message={errors.map(e => (e.message))}
          />
        </div>
      ) : null
      }
      {
        <CustomModal
          successModal={errorModal}
          toggleCloseModal={() => setErrorModal(!errorModal)}
          textModal={errorMessage}
          color={"danger"}
        />
      }
    </Container>
  )
}

export default StatisticProjects;