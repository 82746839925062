import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactTableDnDBody from './ReactTableDnDBody';
import { ThemeProps } from '../../../prop-types/ReducerProps';
import _ from 'lodash';

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow }) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      return (
        <tr
          className={
            _.has(row.original, 'rowCustomClass')
              ? row.original.rowCustomClass.className
              : null
          }
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  withDragAndDrop,
  updateDraggableData,
  theme,
  emptyStateMessage,
}) => {
  const { t } = useTranslation('common');

  if (page.length === 0) {
    const message = emptyStateMessage ?? t('empty_states.default')
    return (
      <Fragment>
        <tbody className="table table--empty">
          <tr>
            <td colSpan="100%">
              <p>{message}</p>
            </td>
          </tr>
        </tbody>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {withDragAndDrop ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
        />
      )}
    </Fragment>
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect((state) => ({
  theme: state.theme,
}))(ReactTableBody);
