const validate = (values) => {
  const errors = {};

  if(!values.selectReporter) {
    errors.selectReporter = 'Reporter field shouldn’t be empty';
  }

  return errors;
};

export default validate;
