import {
    GET_SALARY_LIST_FAIL,
    GET_SALARY_LIST_FETCHING,
    GET_SALARY_LIST_SUCCESS,

    UPDATE_SALARY_LIST_FAIL,
    UPDATE_SALARY_LIST_FETCHING,
    UPDATE_SALARY_LIST_SUCCESS,

    DELETE_SALARY_LIST_FAIL,
    DELETE_SALARY_LIST_FETCHING,
    DELETE_SALARY_LIST_SUCCESS
} from '../actions/salaryListActions';

const initialState = {
    salary_list: [],
    loading: false,
    errors: '',
    totalRecords: 0
};

const salaryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALARY_LIST_FETCHING:
            return { ...state, loading: true };
        case GET_SALARY_LIST_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_SALARY_LIST_SUCCESS:
            return { ...state, salary_list: action.payload.salary_list.lists, totalRecords: Number(action.payload.salary_list.total_records), loading: false, errors: '' };
        case UPDATE_SALARY_LIST_FETCHING:
            return { ...state, loading: true };
        case UPDATE_SALARY_LIST_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case UPDATE_SALARY_LIST_SUCCESS:
            const newSalaryList = state.salary_list.map(obj => {
                if (obj.id === action.payload.newSalaryList.id) {
                    return action.payload.newSalaryList;
                }
                return obj;
            });
            return { ...state, salary_list: newSalaryList, loading: false, errors: '' };
        case DELETE_SALARY_LIST_FETCHING:
            return { ...state, loading: true };
        case DELETE_SALARY_LIST_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case DELETE_SALARY_LIST_SUCCESS:
            const newSalaryListWithDelete = state.salary_list.filter(list => { return list.id !== action.payload.id})
            return { ...state, salary_list: newSalaryListWithDelete, loading: false, errors: '' };
        default:
            return state;
    }
};

export default salaryListReducer;
