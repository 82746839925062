import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

const Breadcrumbs = ({ crumbs }) => {
  if (!crumbs.length) {
    return null;
  }

  return (
    <Container className="breadcrumbs" style={{paddingBottom: "20px"}}>
      <Row>
        <Col>
          {crumbs.map(({ name, path }, key) =>
            key === crumbs.length - 1 ? (
              <span key={key}>{name} </span>
            ) : (
              <Link key={key} to={path}>
                <span>{name}</span>
                <span> <ChevronRightIcon color="rgb(128, 131, 142)" size={14} /> </span>
              </Link>
            ),
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Breadcrumbs;
