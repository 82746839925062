import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../../utils/helpers';
import validate from './validate';
import renderSelectField from '../../../../../../shared/components/form/Select';
import { getDirectors } from '../../../../../../utils/api';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
import { connect } from 'react-redux';


let EditBusinessesForm = ({handleSubmit}) => {
  const [director, setDirector] = useState([]);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getDirectors(authUser.accessToken).then(res => {
      if(res.data.success) {
        setDirector(res.data.data.users);
      }
    })
  }, []);

  return (
    <Card className="business-responsive-form">
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Address</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="address"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Director</span>
              <div className="form__form-group-field">
                <Field
                  name="selectDirector"
                  component={renderSelectField}
                  id="director"
                  options={director.map(u => {
                    return {
                      value: u.id,
                      label: `${u.first_name} ${u.last_name}`
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className='form__form-group'>
              <div className='form__form-group-field'>
                <Field
                  name='is_default'
                  component={renderCheckBoxField}
                  label='Is default'
                />
              </div>
            </div>
          </Col>
          <Col className='col-12'>
            <ButtonToolbar className='form__button-toolbar d-flex w-100 justify-content-end'>
              <Button color='primary' type='submit'>Save</Button>
            </ButtonToolbar>
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

EditBusinessesForm = reduxForm({
  form: 'edit_businesses_form',
  enableReinitialize: true,
  validate,
})(EditBusinessesForm);

EditBusinessesForm = connect(
  state => ({
      initialValues: {
        ...state?.editBusiness?.business[0],
        selectDirector: {
          value: state?.editBusiness.business[0]?.director.id,
          label: `${state?.editBusiness.business[0]?.director.first_name} ${state?.editBusiness.business[0]?.director.last_name}`
        },
      },
  }))(EditBusinessesForm);

export default EditBusinessesForm;