import {
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FETCHING
} from '../actions/myReportsActions';

const initialState = {
  reports: [],
  loading: false,
  errors: '',
  totalRecords: 0,
  totalHours: ''
};


const myReports = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_FETCHING:
      return { ...state, loading: true };
    case GET_REPORTS_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports,
        totalRecords: Number(action.payload.total_records),
        totalHours: action.payload.total_hours,
        loading: false,
        errors: ''
      };
    default:
      return state;
  }
};

export default myReports;
