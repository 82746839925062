import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import { getAllActiveUsersWithStatusClient } from '../../../../utils/api';
import renderSelectField from '../../../../shared/components/form/Select';
import { useSelector } from 'react-redux';


const ContractForm = ({
  modal,
  toggle,
  handleSubmit,
  selectedFile,
  setSelectedFile,
}) => {
  const { t } = useTranslation('common');
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getAllActiveUsersWithStatusClient(authUser.accessToken).then((res) =>
      setAllActiveUsers(res.data.data.users),
    );
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="ltr-support"
      className="modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">
          {t('leads.contract_form.title_form')}
        </h4>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.contract_form.title_input_user_id')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="select"
                component={renderSelectField}
                type="text"
                options={allActiveUsers.map((c) => ({
                  value: `${c.first_name} ${c.last_name}`,
                  label: `${c.first_name} ${c.last_name}`,
                  id: c.id,
                }))}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.contract_form.title_input_comment')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="comment"
                component="textarea"
                type="text"
                placeholder="Comment"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('leads.contract_form.title_input_comment_file')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment_file"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e)}
                  component={renderFileInputField}
                />
              </div>
            </div>
          </div>
          <ButtonToolbar>
            <Button color="primary" type="submit">
              {t('leads.contract_form.title_button')}
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'contract_form',
})(ContractForm);
