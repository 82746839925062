/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
import {
  getVariables,
  postAddNewVariables,
  putEditVariable,
  postShareVariable,
  deleteVariable,
  getProjectById,
} from '../../utils/api';

import { displayNotification } from '../../shared/components/Notification';

export const GET_VARIABLES_FETCHING = 'GET_VARIABLES_FETCHING';
export const GET_VARIABLES_FAIL = 'GET_VARIABLES_FAIL';
export const GET_VARIABLES_SUCCESS = 'GET_VARIABLES_SUCCESS';

export const MODALS_VARIABLES_FETCHING = 'MODALS_VARIABLES_FETCHING';
export const MODALS_VARIABLES_SUCCESS = 'MODALS_VARIABLES_SUCCESS';
export const MODALS_VARIABLES_FAIL = 'MODALS_VARIABLES_FAIL';

export const getVariablesFail = (errors) => {
  return { type: GET_VARIABLES_FAIL, payload: { errors } };
};

export const getVariablesSuccess = (data) => {
  return { type: GET_VARIABLES_SUCCESS, payload: { data } };
};

export const modalsVariablesFail = (errors) => {
  return { type: MODALS_VARIABLES_FAIL, payload: { errors } };
};

export const editVariablesAction = (
  project_id,
  env,
  variable_id,
  key,
  value,
  toggle,
) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODALS_VARIABLES_FETCHING });

    putEditVariable(project_id, env, variable_id, key, value, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          toggle();
          dispatch(getVariablesFetchingAction(project_id, env));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(modalsVariablesFail(data.errors));
          } else {
            dispatch(modalsVariablesFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(modalsVariablesFail(e));
        } else {
          dispatch(modalsVariablesFail([e]));
        }
      });
  };
};

export const shareVariablesAction = (selected_project_id, variable_id, setSuccessModal) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODALS_VARIABLES_FETCHING });

    postShareVariable(selected_project_id, variable_id, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          setSuccessModal(true);
          dispatch({ type: MODALS_VARIABLES_SUCCESS });
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(modalsVariablesFail(data.errors));
          } else {
            dispatch(modalsVariablesFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(modalsVariablesFail(e));
        } else {
          dispatch(modalsVariablesFail([e]));
        }
      });
  };
};

export const deleteVariablesAction = (project_id, env, variable_id, toggle) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODALS_VARIABLES_FETCHING });

    deleteVariable(project_id, env, variable_id, state.auth.user.accessToken)
      .then(({ data }) => {
        toggle();

        if (data.success) {
          dispatch(getVariablesFetchingAction(project_id, env));
          displayNotification('Deleted', 'success');
        } else {
          if (Array.isArray(data.errors)) {
            data.errors.forEach((error) => {
              displayNotification(error.message, 'danger');
            });
          } else {
            displayNotification(data.errors.message, 'danger');
          }
        }
      })
      .catch((e) => {
        toggle();

        if (Array.isArray(e)) {
          e.forEach((error) => {
            displayNotification(error.message, 'danger');
          });
        } else {
          displayNotification(e.message, 'danger');
        }
      });
  };
};

export const getVariablesFetchingAction = (project_id, env) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: GET_VARIABLES_FETCHING });

    getVariables(project_id, env, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          const variables = [];
          const projectsNames = {};
          let rawSharedVariables = null;

          // Separete variables
          data.data.forEach((item) => {
            if (item.shared_variables) {
              rawSharedVariables = item.shared_variables;
            } else {
              variables.push(item);
            }
          });

          // Setup promises for fetching project name for each shared variable
          if (rawSharedVariables) {
            Promise.all(
              rawSharedVariables.map((item) => {
                return getProjectById(item.shared_from_project_id, state.auth.user.accessToken);
              }),
            ).then((responses) => {
              responses.forEach((response) => {
                const project = response.data.data.projects[0];
                projectsNames[project.id] = project.name;
              });

              // Update name field
              const sharedVariables = rawSharedVariables.map((item) => {
                return {
                  ...item,
                  shared_from_project_name:
                    projectsNames[item.shared_from_project_id],
                };
              });

              dispatch(
                getVariablesSuccess({
                  variables,
                  sharedVariables,
                }),
              );
              dispatch(getVariablesFail([]));
            });
          } else {
            dispatch(
              getVariablesSuccess({
                variables,
                sharedVariables: [],
              }),
            );
          }
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(getVariablesFail(data.errors));
          } else {
            dispatch(getVariablesFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(getVariablesFail(e));
        } else {
          dispatch(getVariablesFail([e]));
        }
      });
  };
};

export const addVariablesAction = (project_id, env, key, value, toggle, setSuccessModal) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: MODALS_VARIABLES_FETCHING });

    postAddNewVariables(project_id, env, key, value, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          toggle();
          setSuccessModal(true);
          dispatch(getVariablesFetchingAction(project_id, env));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(modalsVariablesFail(data.errors));
          } else {
            dispatch(modalsVariablesFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(modalsVariablesFail(e));
        } else {
          dispatch(modalsVariablesFail([e]));
        }
      });
  };
};
