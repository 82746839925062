export const columns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'DATE PERIOD',
        accessor: 'report_date',
    },
    {
        Header: 'COMPLETED',
        accessor: 'total_lists',
    },
    {
        Header: 'SALARY',
        accessor: 'total_salary',
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
    }
];