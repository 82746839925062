import {
  GET_MY_INVOICES_FAIL,
  GET_MY_INVOICES_FETCHING,
  GET_MY_INVOICES_SUCCESS
} from '../actions/myInvoicesAction';

const initialState = {
  myInvoices: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const myInvoices = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_INVOICES_FETCHING:
      return { ...state, loading: true };
    case GET_MY_INVOICES_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_MY_INVOICES_SUCCESS:
      return { ...state, myInvoices: action.payload.myInvoices.invoices, totalRecords: Number(action.payload.myInvoices.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default myInvoices;
