import React from 'react';
import {Modal} from 'reactstrap';
import CustomAlert from '../CustomAlert/CustomAlert';
import _ from "lodash";


const CustomModal = ({successModal, toggleCloseModal, textModal, color}) => {
  return (
    <Modal
      isOpen={successModal}
      toggle={toggleCloseModal}
      modalClassName="ltr-support"
      className="modal-dialog  modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleCloseModal}
        />
      </div>
      <div className="modal__body">
        <CustomAlert
          color={color}
          className="alert--bordered"
          icon
        >
          <p>
            {_.isArray(textModal) ? _.map(textModal, (t) => {
              return (
                <>
                  <span className='bold-text'>{t.message}</span> <br/>
                </>
              );
            }) : <span className='bold-text'>{textModal}</span>}

          </p>
        </CustomAlert>
      </div>
    </Modal>
  );
};

export default CustomModal;