import {getAllModules} from "../../utils/api";

export const GET_ALL_MODULES_FETCHING = 'GET_ALL_MODULES_FETCHING';
export const GET_ALL_MODULES_FAIL = 'GET_ALL_MODULES_FAIL';
export const GET_ALL_MODULES_SUCCESS = 'GET_ALL_MODULES_SUCCESS';
export const GET_REMOVED_CLIENT_MODULES_ID = 'GET_REMOVED_CLIENT_MODULES_ID';

export const getAllModulesFetchingAction = () => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({type: GET_ALL_MODULES_FETCHING});
        getAllModules(state.auth.user.accessToken, state.auth.user.clientToken)
            .then(({data}) => {
                dispatch(getAllModulesSuccess(data.data));
            })
            .catch(e => {
                dispatch(getAllModulesFail(e));
            })
    }
};

export const getAllModulesFail = (e) => {
    return {type: GET_ALL_MODULES_FAIL, payload: {message: e?.message}}
};

export const getAllModulesSuccess = (modules) => {
    return {type: GET_ALL_MODULES_SUCCESS, payload: {modules}}
};

export const getRemovedClientModulesId = (id) => {
    return {type: GET_REMOVED_CLIENT_MODULES_ID, payload: {id}}
}