import React from "react";
import { Container, Row } from 'reactstrap';
import MyTable from "./MyTable/MyTable";

const ClientModules = () => {
    return (
        <Container>
            <Row>
                <MyTable />
            </Row>
        </Container>
    )
}

export default ClientModules;