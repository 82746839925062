const roles = {
	ADMIN: 'ADMIN',
	FIN: 'FIN',
	DEV: 'DEV',
	CLIENT: 'CLIENT',
	SALES: 'SALES',
	PM: 'PM',
	GUEST: 'GUEST'
};

export default roles;