import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../../utils/helpers';

const ScrollToTop = ({ children, router: {location} }) => {
  React.useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default withRouter(ScrollToTop);
