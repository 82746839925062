const validate = (value) => {
  const errors = {};

  if(!value.bank_bic) {
    errors.bank_bic = 'Bank BIC field shouldn’t be empty';
  } else if(!/^[a-zA-Z0-9]*$/.test(value.bank_bic)) {
    errors.bank_bic = 'Invalid data type';
  } else if(value.bank_bic?.length > 11) {
    errors.bank_bic = 'Bank BIC field cannot exceed 11 characters in length';
  }

  if(!value.bank_account) {
    errors.bank_account = 'Bank Account field shouldn’t be empty';
  } else if(!/^[a-zA-Z0-9]*$/.test(value.bank_account)) {
    errors.bank_account = 'Invalid data type';
  } else if(value.bank_account?.length > 30) {
    errors.bank_account = 'Bank Account field cannot exceed 30 characters in length';
  }

  if(!value.bank_country) {
    errors.bank_country = 'Bank Country field shouldn’t be empty';
  } else if(!/^[a-zA-Z0-9 ]*$/.test(value.bank_country)) {
    errors.bank_country = 'Invalid data type';
  } else if(value.bank_country?.length > 2) {
    errors.bank_country = 'The field cannot exceed 2 characters in length. Please, enter Country Code';
  }

  if(!value.registration_date) {
    errors.registration_date = 'Registration Date field shouldn’t be empty';
  } else if(value.bank_address > new Date()) {
    errors.registration_date = 'Registration Date not be bigger then today';
  }

  if(!value.bank_address) {
    errors.bank_address = 'Bank Address field shouldn’t be empty';
  } else if(!/^[a-zA-Z 0-9 ~!@#$%^&*()/_+=-]*$/.test(value.bank_address)) {
    errors.bank_address = 'Invalid data type';
  }

  if(!value.bank_city) {
    errors.bank_city = 'Bank City field shouldn’t be empty';
  } else if(!/^[a-zA-Z0-9 ]*$/.test(value.bank_city)) {
    errors.bank_city = 'Invalid data type';
  } else if(value.bank_city?.length > 85) {
    errors.bank_city = 'Bank City field cannot exceed 85 characters in length';
  }

  if(!value.bank_postcode) {
    errors.bank_postcode = 'Bank Postcode field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(value.bank_postcode)) {
    errors.bank_postcode = 'Invalid data type';
  } else if(value.bank_postcode?.length > 5) {
    errors.bank_postcode = 'Bank Postcode field cannot exceed 5 characters in length';
  }

  if(!value.registration_no) {
    errors.registration_no = 'Registration № field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(value.registration_no)) {
    errors.registration_no = 'Invalid data type';
  } else if(value.registration_no?.length > 20) {
    errors.registration_no = 'Registration № field cannot exceed 20 characters in length';
  }

  return errors;
}

export default validate;