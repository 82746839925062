import {
    GET_ALL_MODULES_FAIL,
    GET_ALL_MODULES_FETCHING,
    GET_ALL_MODULES_SUCCESS,
    GET_REMOVED_CLIENT_MODULES_ID
} from '../actions/modulesActions';

const initialState = {
    modules: [],
    arrayDeletedModules: [],
    loading: false,
    errors: ''
};


const ModulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_MODULES_FETCHING:
            return { ...state, loading: true };
        case GET_ALL_MODULES_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_ALL_MODULES_SUCCESS:
            return { ...state, modules: action.payload.modules, loading: false, errors: '' };
        case GET_REMOVED_CLIENT_MODULES_ID:
            let newState = {...state};
            newState['arrayDeletedModules'] = [...state.arrayDeletedModules, {id: +action.payload.id}];

            return newState;
        default:
            return state;
    }
};

export default ModulesReducer;
