import {
  GET_FINANCIAL_ACTIVITY_FAIL,
  GET_FINANCIAL_ACTIVITY_FETCHING,
  GET_FINANCIAL_ACTIVITY_SUCCESS
} from '../actions/financialActivityActions';

const initialState = {
  financialActivity: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const financialActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCIAL_ACTIVITY_FETCHING:
      return { ...state, loading: true };
    case GET_FINANCIAL_ACTIVITY_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_FINANCIAL_ACTIVITY_SUCCESS:
      return { ...state, financialActivity: !!action.payload.financialActivity.reviews ? action?.payload?.financialActivity?.reviews : action?.payload?.financialActivity, loading: false, errors: '', totalRecords: action.payload.financialActivity.total_records };
    default:
      return state;
  }
};

export default financialActivityReducer;
