import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  themeReducer,
  rtlReducer,
  cryptoTableReducer,
  customizerReducer,
  newOrderTableReducer,
  sidebarReducer,
  authReducer,
  clientsReducer,
  clientModulesReducer,
  modulesReducer,
  mySalaryHistoryReducer,
  salaryReportsReducer,
  salaryListReducer,
  financialReportsReducer,
  subcontractorsReducer,
  salesReducer,
  environmentsReducer,
  variablesReducer,
  leadsPageReducer,
  leadsSingleReducer,
  myInvoicesReducer,
  emailTemplatesReducer,
  financialReportEditReducer,
  financialReportIncomesReducer,
  yearlyStatisticsReducer,
  salaryEditListReducer,
  bankAccountReducer,
  invoicesReducer,
  vacationsHistoryReducer,
  editInvoiceReducer,
  usersReducer,
  editUserReducer,
  appConfigReducer,
  workHistoryReducer,
  projectsReducer,
  projectsEditReducer,
  myReportsReducer,
  financialActivityReducer,
  settingsReducer,
  managementReducer,
  businessesReducer,
  editBusinessReducer,
  businessesPaymentMethodsReducer,
  editBusinessPaymentMethodReducer,
} from '../../redux/reducers/index';

import covidReducer from '../Maps/VectorMapWithRequestData/redux/covidReducer';
import todoReducer from '../Todo/redux/reducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  appConfig: appConfigReducer,
  cryptoTable: cryptoTableReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  auth: authReducer,
  covid: covidReducer,
  todo: todoReducer,
  subcontractors: subcontractorsReducer,
  clients: clientsReducer,
  clientModules: clientModulesReducer,
  modules: modulesReducer,
  mySalary: mySalaryHistoryReducer,
  salary_reports: salaryReportsReducer,
  salary_list: salaryListReducer,
  financial_reports: financialReportsReducer,
  financialReportEdit: financialReportEditReducer,
  financialReportIncomes: financialReportIncomesReducer,
  sales: salesReducer,
  environments: environmentsReducer,
  variables: variablesReducer,
  leads: leadsPageReducer,
  singleLead: leadsSingleReducer,
  emailTemplates: emailTemplatesReducer,
  myInvoices: myInvoicesReducer,
  yearlyStatistics: yearlyStatisticsReducer,
  salaryEditList: salaryEditListReducer,
  bankAccount: bankAccountReducer,
  invoices: invoicesReducer,
  vacationsHistory: vacationsHistoryReducer,
  editInvoice: editInvoiceReducer,
  users: usersReducer,
  editUser: editUserReducer,
  workHistory: workHistoryReducer,
  projects: projectsReducer,
  editProjects: projectsEditReducer,
  myReports: myReportsReducer,
  settings: settingsReducer,
  financialActivity: financialActivityReducer,
  management: managementReducer,
  businesses: businessesReducer,
  editBusiness: editBusinessReducer,
  businessesPaymentMethods: businessesPaymentMethodsReducer,
  editBusinessPaymentMethod: editBusinessPaymentMethodReducer,
});

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers)

  const store = createStore(reducer, preloadedState, composedEnhancers);

  return store;
}
