export const columns = [
  {
    Header: '',
    accessor: 'img',
    disableSortBy: true,
    disableGlobalFilter: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Director',
    accessor: 'director',
  },
  {
    Header: 'Is Default',
    accessor: 'is_default',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    disableGlobalFilter: true,
  },
];