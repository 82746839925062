export const columns = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Represented By',
    accessor: 'represented_by',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    disableGlobalFilter: true,
  },
];