import React from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

const DatePickerField = ({value, onChange, maxDate, name}) => {
  return (
    <div className="date-picker bg-white">
      <DatePicker
        className="form__form-group-datepicker"
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        dropDownMode="select"
        withPortal={isMobileOnly}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={maxDate}
        autoComplete='off'
      />
    </div>
  );
};

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const renderDatePickerField = ({ input, maxDate }) => {
  return (
      <DatePickerField {...input} maxDate={maxDate}/>
    )
}

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
};

export default renderDatePickerField;
