import { getEmailTemplates } from '../../utils/api';

export const GET_EMAIL_TEMPLATES_FETCHING = 'GET_EMAIL_TEMPLATES_FETCHING';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAIL = 'GET_EMAIL_TEMPLATES_FAIL';

export const getEmailTemplatesFail = (e) => {
  return { type: GET_EMAIL_TEMPLATES_FAIL, payload: { message: e?.message } };
};

export const getEmailTemplatesSuccess = (emailTemplates) => {
  return { type: GET_EMAIL_TEMPLATES_SUCCESS, payload: { emailTemplates } };
};

export const getEmailTemplatesFetchingActions = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: GET_EMAIL_TEMPLATES_FETCHING });
    getEmailTemplates(state.auth.user.accessToken)
      .then(({ data }) => {
        dispatch(getEmailTemplatesSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getEmailTemplatesFail(e));
      });
  };
};
