import {
  GET_MANAGEMENT_FAIL,
  GET_MANAGEMENT_FETCHING,
  GET_MANAGEMENT_SUCCESS
} from '../actions/managementActions';

const initialState = {
  management: [],
  loading: false,
  errors: '',
  totalRecords: 0,
  totalHours: 0,
  totalCost: 0
};


const managementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGEMENT_FETCHING:
      return { ...state, loading: true };
    case GET_MANAGEMENT_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_MANAGEMENT_SUCCESS:
      return { ...state,
        management: action.payload.management.reports,
        totalRecords: Number(action.payload.management.total_records),
        totalHours: action.payload.management.total_hours,
        totalCost: action.payload.management.total_cost,
        loading: false,
        errors: ''
      };
    default:
      return state;
  }
};

export default managementReducer;
