export  default function maskForStatus(status){
    switch(status){
        case "lead": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        case "qualify_lead": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        case "prospect_lead": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        case "quoted_lead": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        case "client": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        case "dead_client": return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
        default: return { budget: true, description: true, email: true, phone: true, start_date: true, status: true, name: true, source: true, estimate: true}
    }
}