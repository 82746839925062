import { useMemo } from 'react';
import roles from '../../../../config/roles';

const TableConfig = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'DATE PERIOD',
        accessor: 'report_date',
        disablePadding: true,
        roles: [roles.ADMIN, roles.SALES, roles.FIN],
      },
      {
        //Header: t('financial_reports_dashboard.title_column'),
        Header: 'BALANCE',
        accessor: 'balance',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN, roles.FIN],
      },
      {
        //Header: t('financial_reports_dashboard.name_column'),
        Header: 'INCOME',
        accessor: 'income',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN],
      },
      {
        //Header: t('financial_reports_dashboard.date_column'),
        Header: 'CURRENCY',
        accessor: 'currency',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN, roles.FIN],
      },
      {
        //Header: t('financial_reports_dashboard.amount_column'),
        Header: 'EXPENSES',
        accessor: 'expenses',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN, roles.FIN],
      },
      {
        Header: 'PROFIT',
        accessor: 'profit',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN],
      },
      {
        Header: 'INVESTMENTS',
        accessor: 'investments',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN, roles.FIN],
      },
      {
        Header: 'DEVELOPER EXPENSES',
        accessor: 'developer_expenses',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.SALES],
      },
      {
        Header: 'BONUSES',
        accessor: 'bonuses',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.SALES],
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        disablePadding: false,
        disableGlobalFilter: true,
        roles: [roles.ADMIN, roles.SALES, roles.FIN],
      },
    ],
    [],
  );

  return {
    columns,
  };
};

export default TableConfig;
