import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DataReactTable from '../../../../Tables/DataTable/components/DataReactTable';
import { columns } from './BusinessesPaymentMethodsDataColumns';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getBusinessesPaymentMethodsFetchingAction
} from '../../../../../redux/actions/businessesPaymentMethodsActions';
import ConfirmModal from '../../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { deletePaymentMethod } from '../../../../../utils/api';

const BusinessesPaymentMethods = () => {
  const { t } = useTranslation('common');
  const {paymentMethods, errors} = useSelector(state => state.businessesPaymentMethods);
  const authUser = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [deleteId, setDeleteId] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [failedDeleteModal, setFailedDeleteModal] = useState(false);
  const [messageDeleteError, setMessageDeleteError] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(getBusinessesPaymentMethodsFetchingAction(id));
  }, [id, dispatch, refreshCounter]);

 const handleDeletePaymentMethod = () => {
   deletePaymentMethod(id, deleteId, authUser.accessToken).then(res => {
     if(res.data.success) {
       setSuccessDeleteModal(!successDeleteModal);
     } else {
       setMessageDeleteError(res.data.errors[0].message);
       setFailedDeleteModal(!failedDeleteModal);
     }
   })
 }

  return (
    <Container>
      <Row>
        <Col className="col-12 d-flex justify-content-end">
          <button className="btn btn-success" onClick={() => navigate(`/dashboard/businesses/${id}/methods/add`)}>Add A New Payment Method</button>
        </Col>
        <Col className="col-12">
          <DataReactTable
            pagination={false}
            sortTable={true}
            search={false}
            emptyStateMessage={t('empty_states.methods')}
            reactTableData={{tableHeaderData: columns, tableRowsData: paymentMethods?.map((c) => {
                return {...c,
                  id: `${c.id}`,
                  actions: (
                    <>
                      <ReactTooltip id="deleteIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          Delete
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="deleteIcon"
                        style={{border: 0, background: 'transparent', padding: '5px 0 5px 5px'}}>
                        <DeleteIcon
                          onClick={() => {
                            setDeleteId(c.id)
                            setConfirmModal(true)}}
                          size={18}
                          color="#b1c3c8" />
                      </button>
                      <ReactTooltip id="editIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          Edit
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="editIcon"
                        style={{border: 0, background: 'transparent', padding: '5px 0 5px 5px'}}>
                        <PencilIcon
                          onClick={() => {
                            navigate(`/dashboard/businesses/${id}/methods/${c.id}/edit`)
                          }}
                          color="#b1c3c8"
                          size={18}
                        />
                      </button>
                    </>
                  )
                }
              })}}
          />

          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>

        <ConfirmModal
          color="primary"
          btn="Default"
          message={`Are you sure you want to delete payment method #${deleteId}?`}
          toggle={() => setConfirmModal(!confirmModal)}
          modal={confirmModal}
          onNext={handleDeletePaymentMethod}
        />
        <CustomModal
          successModal={failedDeleteModal}
          toggleCloseModal={() => setFailedDeleteModal(!failedDeleteModal)}
          textModal={messageDeleteError}
          color={"danger"}
        />
        <CustomModal
          successModal={successDeleteModal}
          toggleCloseModal={() => {
            setSuccessDeleteModal(!successDeleteModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Payment method №${deleteId} was successfully deleted!`}
          color={"success"}
        />
      </Row>
    </Container>
  );
};

export default BusinessesPaymentMethods;