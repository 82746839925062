import {getClientModules} from "../../utils/api";

export const GET_CLIENT_MODULES_FETCHING = 'GET_CLIENT_MODULES_FETCHING';
export const GET_CLIENT_MODULES_FAIL = 'GET_CLIENT_MODULES_FAIL';
export const GET_CLIENT_MODULES_SUCCESS = 'GET_CLIENT_MODULES_SUCCESS';

export const getClientModulesFetchingAction = (id) => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({type: GET_CLIENT_MODULES_FETCHING});
        getClientModules(id, state.auth.user.accessToken, state.auth.user.clientToken)
            .then(({data}) => {
                dispatch(getClientModulesSuccess(data.data));
            })
            .catch(e => {
                dispatch(getClientModulesFail(e));
            })
    }
};

export const getClientModulesFail = (e) => {
    return {type: GET_CLIENT_MODULES_FAIL, payload: {message: e?.message}}
};

export const getClientModulesSuccess = (clientModules) => {
    return {type: GET_CLIENT_MODULES_SUCCESS, payload: {clientModules}}
};