import {getSalaryReports} from "../../utils/api";

export const GET_SALARY_REPORTS_FETCHING = 'GET_SALARY_REPORTS_FETCHING';
export const GET_SALARY_REPORTS_FAIL = 'GET_SALARY_REPORTS_FAIL';
export const GET_SALARY_REPORTS_SUCCESS = 'GET_SALARY_REPORTS_SUCCESS';

export const UPDATE_SALARY_REPORTS_FETCHING = 'UPDATE_SALARY_REPORTS_FETCHING';
export const UPDATE_SALARY_REPORTS_FAIL = 'UPDATE_SALARY_REPORTS_FAIL';
export const UPDATE_SALARY_REPORTS_SUCCESS = 'UPDATE_SALARY_REPORTS_SUCCESS';
export const CLEAR_STATE_SALARY_REPORTS = 'CLEAR_STATE_SALARY_REPORTS';

export const getSalaryReportsAction = (query) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type: GET_SALARY_REPORTS_FETCHING});
        getSalaryReports(query, state.auth.user.accessToken, state.auth.user.clientToken)
            .then(({data}) => {
                dispatch(getSalaryReportsSuccess(data.data));
            })
            .catch(e => {
                dispatch(getSalaryReportsFail(e));
            })
    }
};
export const getSalaryReportsFail = (e) => {
    return {type: GET_SALARY_REPORTS_FAIL, payload: {message: e?.message}}
};
export const getSalaryReportsSuccess = (data) => {
    return {type: GET_SALARY_REPORTS_SUCCESS, payload: data}
};

export const clearStateSalaryReport = () => {
    return { type: CLEAR_STATE_SALARY_REPORTS }
}