/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';

const InfoTable = ({ data }) => {
  const { t } = useTranslation('common');
  const {
    budget,
    description,
    email,
    phone,
    start_date,
    status,
    name,
    estimate,
    source,
  } = data;

  return (
    <table className="project-summary__info">
      <tbody>
        <tr>
          <th>{t('leads.single_summary.name')}</th>
          <td>
            <p>{name}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.source')}</th>
          <td>
            <p>{source}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.status')}</th>
          <td>
            <p>{status}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.email')}</th>
          <td>
            <p>{email}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.phone')}</th>
          <td>
            <p>{phone}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.start')}</th>
          <td>
            <p>{start_date}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.budget')}</th>
          <td>
            <p>{budget}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.estimate')}</th>
          <td>
            <p>{estimate}</p>
          </td>
        </tr>
        <tr>
          <th>{t('leads.single_summary.description')}</th>
          <td>
            <p>{description}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InfoTable;
