import {getBusinessPaymentMethods} from "../../utils/api";

export const GET_BUSINESS_PAYMENT_METHODS_FETCHING = 'GET_BUSINESS_PAYMENT_METHODS_FETCHING';
export const GET_BUSINESS_PAYMENT_METHODS_FAIL = 'GET_BUSINESS_PAYMENT_METHODS_FAIL';
export const GET_BUSINESS_PAYMENT_METHODS_SUCCESS = 'GET_BUSINESS_PAYMENT_METHODS_SUCCESS';

export const getBusinessesPaymentMethodsFetchingAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_BUSINESS_PAYMENT_METHODS_FETCHING});
    getBusinessPaymentMethods(query, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getBusinessesPaymentMethodsSuccess(data.data));
      })
      .catch(e => {
        dispatch(getBusinessesPaymentMethodsFail(e));
      })
  }
};

export const getBusinessesPaymentMethodsFail = (e) => {
  return {type: GET_BUSINESS_PAYMENT_METHODS_FAIL, payload: {message: e?.message}}
};

export const getBusinessesPaymentMethodsSuccess = (paymentMethods) => {
  return {type: GET_BUSINESS_PAYMENT_METHODS_SUCCESS, payload: {paymentMethods}}
};