import {getSalaryEditList} from "../../utils/api";

export const GET_SALARY_EDIT_LIST_FETCHING = 'GET_SALARY_EDIT_LIST_FETCHING';
export const GET_SALARY_EDIT_LIST_FAIL = 'GET_SALARY_EDIT_LIST_FAIL';
export const GET_SALARY_EDIT_LIST_SUCCESS = 'GET_SALARY_EDIT_LIST_SUCCESS';

export const getSalaryEditListAction = (listId, id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_SALARY_EDIT_LIST_FETCHING});
    getSalaryEditList(listId, id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getSalaryReportsSuccess(data.data.lists));
      })
      .catch(e => {
        dispatch(getSalaryReportsFail(e));
      })
  }
};
export const getSalaryReportsFail = (e) => {
  return {type: GET_SALARY_EDIT_LIST_FAIL, payload: {message: e?.message}}
};
export const getSalaryReportsSuccess = (salaryEditList) => {
  return {type: GET_SALARY_EDIT_LIST_SUCCESS, payload: {salaryEditList}}
};