export  const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += parseFloat(context.parsed.y).toFixed(2)
          }
          return label;
        }
      }
    }
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
  },
};

export const colors = ['#ff1100', '#ffa200', '#ffff00', '#00ff33', '#0066ff', '#ff00e1', '#ff0000'];