import roles from "../../../../../config/roles";

export const columns = [
  {
    Header: '#',
    accessor: 'id',
    roles: [roles.ADMIN, roles.SALES, roles.FIN],
  },
  {
    Header: 'Title',
    accessor: 'title',
    roles: [roles.ADMIN, roles.SALES, roles.FIN],
  },
  {
    Header: 'Sale',
    accessor: 'sale.name',
    roles: [roles.ADMIN, roles.SALES, roles.FIN],
  },
  {
    Header: 'Date',
    accessor: 'date',
    roles: [roles.ADMIN, roles.SALES, roles.FIN],
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    roles: [roles.ADMIN, roles.SALES, roles.FIN],
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    roles: [roles.ADMIN, roles.SALES],
  },
];