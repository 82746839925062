export const columns = [
  {
    Header: 'Year',
    accessor: 'year',
  },
  {
    Header: 'Month',
    accessor: 'month'
  },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Vacations Days',
    accessor: 'days',
  },
];