import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import TimeLineItem from './TimeLineItem';
import { getRelativeTime } from '../../../utils/relativeTime';
import { isHaveUnicode, getLeadCommentType, downloadFile } from '../../../utils/helpers';
import { getLeadFile } from '../../../utils/api';
import Alert from '../../../shared/components/Alert';
import { useSelector } from 'react-redux';

const TimeLineElement = ({ id, comment, datetime, file }) => {
    const { t } = useTranslation('common');
    const [errors, setErrors] = useState(false);
    const timestamp = Date.parse(datetime);
    const type = getLeadCommentType(comment);
    const authUser = useSelector(state => state.auth.user);

    const timelineSettings = {
        years: t('leads.timeline.years'),
        months: t('leads.timeline.months'),
        weeks: t('leads.timeline.weeks'),
        days: t('leads.timeline.days'),
        hours: t('leads.timeline.hours'),
        minutes: t('leads.timeline.minutes'),
        seconds: t('leads.timeline.seconds')
    };

    let formattedDate = datetime;

    if (timestamp) {
        formattedDate = getRelativeTime(timestamp, timelineSettings) + ' ' + t('leads.timeline.ago');
    }

    const handleDownload = () => {
        getLeadFile(file, authUser.accessToken).then((res) => {
            const { data, errors, success } = res.data;

            if (!success) {
                if (errors.length) {
                    setErrors(errors);
                } else if (errors.message) {
                    setErrors([{
                        message: errors.message
                    }]);
                }
                return;
            }

            const decodedFileContent = window.atob(data.file);
            downloadFile(data.name, decodedFileContent);
        });
    };

    const renderFile = () => {
        if (!file) {
            return null;
        }

        if (isHaveUnicode(file)) {
            return null;
        }

        if (errors && errors.length) {
            return errors.map((error, i) => {
                return (
                    <Alert color="danger" className="alert--bordered" icon key={i}>
                        <p>{error.message}</p>
                    </Alert>
                );
            });
        }

        return (
            <Button color="primary" size="sm" onClick={handleDownload}>
                {t('leads.timeline.cta')}
            </Button>
        );
    };

    return (
        <TimeLineItem type={type} title={comment} date={formattedDate} key={id}>
            <div className="timeline__inner">
                {renderFile()}
            </div>
        </TimeLineItem>
    );
};

export default TimeLineElement;