import React from 'react';
import { Field, reduxForm} from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import validate from './validate';
import { connect } from 'react-redux';
import {renderField} from '../../../utils/helpers';


let SalaryReportEditForm=({handleSubmit})=> {
    return(
      <form  onSubmit={handleSubmit}>
          <div className="form_Settings">
              <Row className="w-100">
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">EMPLOYEE</span>
                          <div className="form__form-group-field">
                              <Field
                                name="firstNameLastName"
                                component="input"
                                type="text"
                                className="input-without-border-radius"
                                disabled="disabled"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">BONUSES</span>
                          <div className="form__form-group-field">
                              <Field
                                name="bonuses"
                                component={renderField}
                                type="text"
                                className="input-without-border-radius"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">HOSPITAL</span>
                          <div className="form__form-group-field">
                              <Field
                                name="hospital_days"
                                component={renderField}
                                type="text"
                                className="input-without-border-radius"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">OVERTIME</span>
                          <div className="form__form-group-field">
                              <Field
                                name="overtime_days"
                                component={renderField}
                                type="text"
                                className="input-without-border-radius"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">OTHER SURCHARGES</span>
                          <div className="form__form-group-field">
                              <Field
                                name="other_surcharges"
                                component={renderField}
                                type="text"
                                className="input-without-border-radius"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={4}>
                      <div className="form__form-group">
                          <span className="form__form-group-label">VACATION DAYS</span>
                          <div className="form__form-group-field">
                              <Field
                                name="vacation_days"
                                component={renderField}
                                type="text"
                                className="input-without-border-radius"
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={12}>
                      <div className="form_Settings-button">
                          <Button type="submit" color="primary" className="account__btn">
                              Save
                          </Button>
                      </div>
                  </Col>
              </Row>
          </div>
      </form>
    )
}

SalaryReportEditForm = reduxForm({
    form: "SalaryReportEditForm",
    enableReinitialize: true,
    validate,
})(SalaryReportEditForm);

SalaryReportEditForm = connect(
  state => ({
      initialValues: {
          firstNameLastName: state.salaryEditList.salaryEditList[0]?.user.first_name + " " + state.salaryEditList.salaryEditList[0]?.user.last_name,
          ...state.salaryEditList.salaryEditList[0],
          bonuses: (state?.salaryEditList?.salaryEditList[0]?.bonuses !== null && state?.salaryEditList?.salaryEditList[0]?.bonuses !== 0) ? state?.salaryEditList?.salaryEditList[0]?.bonuses : 0,
          hospital_days: (state.salaryEditList.salaryEditList[0]?.hospital_days !== null && state?.salaryEditList?.salaryEditList[0]?.hospital_days !== 0) ? state?.salaryEditList?.salaryEditList[0]?.hospital_days : 0,
          overtime_days: (state?.salaryEditList?.salaryEditList[0]?.overtime_days !== null && state?.salaryEditList?.salaryEditList[0]?.overtime_days !== 0) ? state?.salaryEditList?.salaryEditList[0]?.overtime_days : 0,
          other_surcharges: (state?.salaryEditList?.salaryEditList[0]?.other_surcharges !== null && state?.salaryEditList?.salaryEditList[0]?.other_surcharges !== 0) ? state?.salaryEditList?.salaryEditList[0]?.other_surcharges : 0,
          vacation_days: (state?.salaryEditList?.salaryEditList[0]?.vacation_days !== null && state?.salaryEditList?.salaryEditList[0]?.vacation_days !== 0) ? state?.salaryEditList?.salaryEditList[0]?.vacation_days : 0,
      }
  })
)(SalaryReportEditForm);

export default SalaryReportEditForm;