import {
    GET_SALARY_REPORTS_FAIL,
    GET_SALARY_REPORTS_FETCHING,
    GET_SALARY_REPORTS_SUCCESS,

    UPDATE_SALARY_REPORTS_FAIL,
    UPDATE_SALARY_REPORTS_FETCHING,
    UPDATE_SALARY_REPORTS_SUCCESS,
    CLEAR_STATE_SALARY_REPORTS
} from '../actions/salaryReportsActions';

const initialState = {
    salary_reports: [],
    loading: false,
    errors: '',
    totalRecords: 0
};


const salaryReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALARY_REPORTS_FETCHING:
            return { ...state, loading: true };
        case GET_SALARY_REPORTS_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_SALARY_REPORTS_SUCCESS:
            return { ...state, salary_reports: action.payload.reports, totalRecords: Number(action.payload.total_records), loading: false, errors: '' };
        case UPDATE_SALARY_REPORTS_FETCHING:
            return { ...state, loading: true };
        case UPDATE_SALARY_REPORTS_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case UPDATE_SALARY_REPORTS_SUCCESS:
            const newSalaryReports = state.salary_reports.map(obj => {
                if (obj.id === action.payload.newSalaryReport.id) {
                    return action.payload.newSalaryReport;
                }
                return obj;
            });
            return { ...state, salary_reports: newSalaryReports, loading: false, errors: '' };
        case CLEAR_STATE_SALARY_REPORTS:
            return {...state, salary_reports: []}
        default:
            return state;
    }
};


export default salaryReportsReducer;
