import React from 'react';
import { Field, reduxForm} from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import PlusThickIcon from "mdi-react/PlusThickIcon";
import MinusThickIcon from "mdi-react/MinusThickIcon";
import { useTranslation } from 'react-i18next';
import {get, map} from 'lodash';
import validate from './validate';
import _ from 'lodash';
import Roles from '../../../../../config/roles';


let FinancialReportEditForm = ({handleSubmit, financialReport, handleAddRow, handleChangeField, handleRemoveRow, handleChangeFirstThreeFields}) => {
  const {expense_constant, investments, spent_corp_events, validationErrors, report_date} = financialReport;
  const { t } = useTranslation('common');
  const daysInMonth = new Date(new Date(report_date).getFullYear(), new Date(_.reverse(report_date?.split("/"))?.join("/")).getMonth() + 1, 0).getDate();
  const role  = useSelector((state) => state.auth.user.role);

  return (
    <form className="form financialReportEditForm" onSubmit={handleSubmit}>
      <Col sm={12} className="pl-0 pr-0 d-flex flex-column flex-lg-row">
        <Col className="field-working-days">
          <div className="form__form-group">
            <div className="form__form-group-label">{t('financial_reports_dashboard_edit.num_of_working_days')}</div>
            <div className="form__form-group-field">
              <Field
                name="num_of_working_days"
                component="input"
                type="text"
                className="input-without-border-radius"
                disabled={role === Roles.FIN}
                onChange={(e) => handleChangeFirstThreeFields(e.target.value, "num_of_working_days")}
              />
            </div>
          </div>
          {
            get(validationErrors, `num_of_working_days.error`)
              ? <div className="validationErrors">{get(validationErrors, `num_of_working_days.error`)}</div>
              : null
          }
        </Col>
        <Col className="field-currency">
          <div className="form__form-group">
            <div className="form__form-group-label">{t('financial_reports_dashboard_edit.currency')}</div>
            <div className="form__form-group-field">
              <Field
                name="currency"
                component="input"
                type="text"
                className="input-without-border-radius"
                onChange={(e) => handleChangeFirstThreeFields(e.target.value, "currency")}
              />
            </div>
          </div>
          {
            get(validationErrors, `currency.error`)
              ? <div className="validationErrors">{get(validationErrors, `currency.error`)}</div>
              : null
          }
        </Col>
        <Col className="field-expense-salary">
          <div className="form__form-group">
            <div className="form__form-group-label">{t('financial_reports_dashboard_edit.expense_salary')}</div>
            <div className="form__form-group-field">
              <Field
                name="expense_salary"
                component="input"
                type="text"
                className="input-without-border-radius"
                onChange={(e) => handleChangeFirstThreeFields(e.target.value, "expense_salary")}
              />
            </div>
          </div>
          {
            get(validationErrors, `expense_salary.error`)
              ? <div className="validationErrors">{get(validationErrors, `expense_salary.error`)}</div>
              : null
          }
        </Col>
      </Col>
      <Col sm={12}>
        <h4 className="mb-3 mt-3">{t('financial_reports_dashboard_edit.expense_constant')}</h4>
        <Row>
          {
            !!expense_constant?.length && (
              <div className="d-flex w-100 pl-0">
                <Col sm={3} md={2} className="col-3">
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.amount_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-8 col-5" md={7} sm={6}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.description_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-1 col-3" sm={2}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.date_label')}</span>
                  </div>
                </Col>
              </div>
            )
          }
          {
            expense_constant?.map((_, i) => {
              return (
                <div key={i} className="d-flex w-100 flex-wrap pl-0">
                  <Col sm={3} className="pr-0 col-3" md={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field align-items-center">
                        <span style={{paddingRight: "5px"}}>$</span>
                        <Field
                          name={`expense_constant[${i}].amount`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('expense_constant', i, 'amount')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `expense_constant.${i}.amount.error`)
                        ? <div className="validationErrors">{get(validationErrors, `expense_constant.${i}.amount.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-8 pr-0 col-5" md={7} sm={6}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`expense_constant[${i}].description`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('expense_constant', i, 'description')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `expense_constant.${i}.description.error`)
                        ? <div className="validationErrors">{get(validationErrors, `expense_constant.${i}.description.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-1 pr-0 col-2 select-field" sm={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`expense_constant[${i}].date`}
                          component="select"
                          className="input-without-border-radius"
                          select={daysInMonth}
                          onChange={handleChangeField('expense_constant', i, 'date')}
                        >
                          {
                            map(new Array(daysInMonth), (_, i) => {
                                return (
                                  <option value={i + 1}>{i + 1}</option>
                                )
                            })
                          }
                        </Field>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-2 col-sm-1 wrapper-btn-decrease pr-0">
                    <button type="button" onClick={handleRemoveRow('expense_constant', i)} className="btn-decrease"><MinusThickIcon color="#fff" size={20}/></button>
                  </Col>
                  <Col className="col-12">
                    {get(validationErrors, `expense_constant.${i}.error`) ? <div className="validationErrors">{get(validationErrors, `expense_constant.${i}.error`)}</div> : null}
                  </Col>
                </div>
              )
            })
          }
        </Row>
        <button type="button" className="btn-add"><PlusThickIcon onClick={handleAddRow('expense_constant')} color="#fff" size={20}/></button>
      </Col>
      <Col sm={12}>
        <h4 className="mb-3 mt-3">{t('financial_reports_dashboard_edit.investments')}</h4>
        <Row>
          {
            !!investments?.length && (
              <div className="d-flex w-100 pl-0">
                <Col sm={3} md={2} className="col-3">
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.amount_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-8 col-5" md={7} sm={6}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.description_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-1 col-3" sm={2}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.date_label')}</span>
                  </div>
                </Col>
              </div>
            )
          }
          {
            investments?.map((_, i) => {
              return (
                <div key={i} className="d-flex w-100 flex-wrap pl-0">
                  <Col sm={3} className="pr-0 col-3" md={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field align-items-center">
                        <span style={{paddingRight: "5px"}}>$</span>
                        <Field
                          name={`investments[${i}].amount`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('investments', i, 'amount')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `investments.${i}.amount.error`)
                        ? <div className="validationErrors">{get(validationErrors, `investments.${i}.amount.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-8 pr-0 col-5" md={7} sm={6}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`investments[${i}].description`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('investments', i, 'description')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `investments.${i}.description.error`)
                        ? <div className="validationErrors">{get(validationErrors, `investments.${i}.description.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-1 pr-0 col-2 select-field" sm={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`investments[${i}].date`}
                          component="select"
                          className="input-without-border-radius"
                          select={daysInMonth}
                          onChange={handleChangeField('expense_constant', i, 'date')}
                        >
                          {
                            map(new Array(daysInMonth), (_, i) => {
                              return (
                                <option value={i + 1}>{i + 1}</option>
                              )
                            })
                          }
                        </Field>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-2 col-sm-1 wrapper-btn-decrease pr-0">
                    <button type="button" onClick={handleRemoveRow('investments', i)} className="btn-decrease"><MinusThickIcon color="#fff" size={20}/></button>
                  </Col>
                  <Col sm={12}>
                    {get(validationErrors, `investments.${i}.error`) ? <div className="validationErrors">{get(validationErrors, `investments.${i}.error`)}</div> : null}
                  </Col>
                </div>
              )
            })
          }
        </Row>
        <button type="button" onClick={handleAddRow('investments')} className="btn-add"><PlusThickIcon color="#fff" size={20}/></button>
      </Col>
      <Col sm={12}>
        <h4 className="mb-3 mt-3">{t('financial_reports_dashboard_edit.spent_corp_events')}</h4>
        <Row>
          {
            !!spent_corp_events?.length && (
              <div className="d-flex w-100 pl-0">
                <Col sm={3} md={2} className="col-3">
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.amount_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-8 col-5" md={7} sm={6}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.description_label')}</span>
                  </div>
                </Col>
                <Col className="col-xxl-1 col-3" sm={2}>
                  <div className="form__form-group mb-2">
                    <span className="form__form-group-label">{t('financial_reports_dashboard_edit.date_label')}</span>
                  </div>
                </Col>
              </div>
            )
          }
          {
            spent_corp_events?.map((_, i) => {
              return (
                <div key={i} className="d-flex w-100 flex-wrap pl-0">
                  <Col sm={3} className="pr-0 col-3" md={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field align-items-center">
                        <span style={{paddingRight: "5px"}}>$</span>
                        <Field
                          name={`spent_corp_events[${i}].amount`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('spent_corp_events', i, 'amount')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `spent_corp_events.${i}.amount.error`)
                        ? <div className="validationErrors">{get(validationErrors, `spent_corp_events.${i}.amount.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-8 pr-0 col-5" md={7} sm={6}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`spent_corp_events[${i}].description`}
                          component="input"
                          type="text"
                          className="input-without-border-radius"
                          onChange={handleChangeField('spent_corp_events', i, 'description')}
                        />
                      </div>
                    </div>
                    {
                      get(validationErrors, `spent_corp_events.${i}.description.error`)
                        ? <div className="validationErrors">{get(validationErrors, `spent_corp_events.${i}.description.error`)}</div>
                        : null
                    }
                  </Col>
                  <Col className="col-xxl-1 pr-0 col-2 select-field" sm={2}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={`spent_corp_events[${i}].date`}
                          component="select"
                          className="input-without-border-radius"
                          select={daysInMonth}
                          onChange={handleChangeField('expense_constant', i, 'date')}
                        >
                          {
                            map(new Array(daysInMonth), (_, i) => {
                              return (
                                <option value={i + 1}>{i + 1}</option>
                              )
                            })
                          }
                        </Field>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-2 col-sm-1 wrapper-btn-decrease pr-0">
                    <button type="button" onClick={handleRemoveRow('spent_corp_events', i)} className="btn-decrease"><MinusThickIcon color="#fff" size={20}/></button>
                  </Col>
                </div>
              )
            })
          }
        </Row>
        <button type="button" onClick={handleAddRow('spent_corp_events')} className="btn-add"><PlusThickIcon color="#fff" size={20}/></button>
      </Col>
      <Col className="justify-content-end d-flex col-12">
        <Button type="submit" color="primary" className="account__btn w-auto">
          Save
        </Button>
      </Col>
    </form>
  );
};

FinancialReportEditForm = reduxForm({
  enableReinitialize: true,
  form: "FinancialReportEditForm",
  validate
})(FinancialReportEditForm);

FinancialReportEditForm = connect(
  state => ({
    initialValues: state.financialReportEdit,
    financialReport: state.financialReportEdit
  }))(FinancialReportEditForm)

export default FinancialReportEditForm;