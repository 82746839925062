import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../../../utils/helpers';
import renderCheckBoxField from '../../../../../../../shared/components/form/CheckBox';
import validate from './validate';
import { connect } from 'react-redux';


let BusinessesPaymentMethodEditForm = ({handleSubmit}) => {
  return (
    <Card className="business-responsive-form">
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Name (Alternate Translation, Optional)</span>
              <div className="form__form-group-field">
                <Field
                  name="name_alt"
                  component={renderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Address</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="address"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Address (Alternate Translation, Optional)</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="address_alt"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Represented By</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="represented_by"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Represented By (Alternate Translation, Optional)</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="represented_by_alt"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Bank Information</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="bank_information"
                    component="textarea"
                    rows={5}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className="form__form-group">
              <span className="form__form-group-label">Bank Information (Alternate Translation, Optional)</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Field
                    name="bank_information_alt"
                    component="textarea"
                    rows={5}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className='form__form-group'>
              <div className='form__form-group-field'>
                <Field
                  name='is_default'
                  component={renderCheckBoxField}
                  label='Is default'
                />
              </div>
            </div>
          </Col>
          <Col className='col-12'>
            <ButtonToolbar className='form__button-toolbar d-flex w-100 justify-content-end'>
              <Button color='primary' type='submit'>Save</Button>
            </ButtonToolbar>
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

BusinessesPaymentMethodEditForm = reduxForm({
  form: 'edit_businesses_payment_method_form',
  enableReinitialize: true,
  validate,
})(BusinessesPaymentMethodEditForm);

BusinessesPaymentMethodEditForm = connect(
  state => ({
    initialValues: state?.editBusinessPaymentMethod?.paymentMethod[0]
  }))(BusinessesPaymentMethodEditForm);

export default BusinessesPaymentMethodEditForm;