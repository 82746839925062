import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonToolbar, Button, Spinner, Modal } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import validate from './validate';
import { renderField } from '../../../../../utils/helpers';
import CustomAlert from '../../../../../shared/components/customComponents/CustomAlert/CustomAlert';
import _ from 'lodash';
import renderSelectField from '../../../../../shared/components/form/Select';
import roles from '../../../../../config/roles';

let timerId;

const FormAddNewSubcontractor = ({
  handleSubmit,
  isSubmitting,
  modal,
  toggle,
  modalErrors,
  users,
  userLoading,
  userError,
  role,
  totalUsers,
  setUserQuery,
}) => {
  const { t } = useTranslation('common');
  const rtl = useSelector((state) => state.rtl);
  const theme = useSelector((state) => state.theme.className);

  const renderUserSelect = () => {
    if (userLoading) {
      return (
        <Spinner size="sm" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (userError) {
      return (
        <CustomAlert color="danger" className="alert--bordered">
          <p>{userError}</p>
        </CustomAlert>
      );
    }

    return (
      <Field
        name="select_user"
        component={renderSelectField}
        id="user"
        options={_.sortBy(users, 'first_name')?.map((c) => {
          return {
            value: c.id,
            label: `${c.first_name} ${c.last_name}`,
          };
        })}
        onInputChange={(value) => {
          if (totalUsers <= 10) return;
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            setUserQuery(value);
          }, 600);
        }}
      />
    );
  };

  return (
    <Modal
      isOpen={modal}
      modalClassName={`${rtl.direction}-support ${theme}`}
      toggle={toggle}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        {modalErrors.length > 0 &&
          modalErrors.map((error, i) => {
            return (
              <CustomAlert
                color="danger"
                className="alert--bordered"
                icon
                key={i}
              >
                <p>
                  <span className="bold-text">{error.param}</span>{' '}
                  {error.message}
                </p>
              </CustomAlert>
            );
          })}
        <form className="form" onSubmit={handleSubmit}>
          {role === roles.ADMIN && (
            <div className="form__form-group">
              <span className="form__form-group-label">User</span>
              <div className="form__form-group-field">{renderUserSelect()}</div>
            </div>
          )}
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('financial_reports_dashboard.title_label')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="title"
                component={renderField}
                type="text"
                placeholder={t('financial_reports_dashboard.title_placeholder')}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('financial_reports_dashboard.amount_label')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="amount"
                component={renderField}
                type="number"
                placeholder={t(
                  'financial_reports_dashboard.amount_placeholder',
                )}
              />
            </div>
          </div>
          <ButtonToolbar>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {t('financial_reports_dashboard.submit_text')}
            </Button>
            {isSubmitting && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

FormAddNewSubcontractor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  modalErrors: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  userLoading: PropTypes.bool.isRequired,
  userError: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  totalUsers: PropTypes.number.isRequired,
  setUserQuery: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'add_subcontractor_report_form',
  validate,
})(FormAddNewSubcontractor);
