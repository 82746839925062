import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import EditBusinessesForm from './EditBusinessesForm/EditBusinessesForm';
import { getBusinessEditAction } from '../../../../../redux/actions/editBusinessActions';
import { useNavigate, useParams } from 'react-router-dom';
import { putBusiness } from '../../../../../utils/api';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';

const EditBusinesses = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [failedEditModal, setFailedDeleteModal] = useState(false);
  const [messageEditError, setMessageEditError] = useState('');
  const [successEditModal, setSuccessEditModal] = useState(false);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getBusinessEditAction(id));
  }, [id, dispatch]);

  const handlePutBusinesses = (values) => {
    putBusiness(id, values, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessEditModal(!successEditModal);
      } else {
        setMessageEditError(res.data.errors[0].message);
        setFailedDeleteModal(!failedEditModal);
      }
    })
  }

  return (
    <Container>
      <Row>
        <EditBusinessesForm onSubmit={handlePutBusinesses} />
      </Row>

      <CustomModal
        successModal={failedEditModal}
        toggleCloseModal={() => setFailedDeleteModal(!failedEditModal)}
        textModal={messageEditError}
        color={"danger"}
      />
      <CustomModal
        successModal={successEditModal}
        toggleCloseModal={() => {
          setSuccessEditModal(!successEditModal);
          navigate('/dashboard/businesses')
        }}
        textModal={`Business №${id} was successfully changed!`}
        color={"success"}
      />
    </Container>
  );
};

export default EditBusinesses;