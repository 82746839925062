import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import maskForStatus from './../../../utils/maskForStatus';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import moment from 'moment/moment';


const InfoTableEdit = ({ data, handleSubmit, setEditMode, editMode, initialize }) => {
  const { t } = useTranslation('common');
  const {
    id,
    budget,
    description,
    email,
    phone,
    start_date,
    status,
    estimate,
    name,
    source,
  } = data;

  const [budgetEdit, setBudget] = React.useState(budget || '');
  const [descriptionEdit, setDescription] = React.useState(description || '');
  const [emailEdit, setEmail] = React.useState(email || '');
  const [phoneEdit, setPhone] = React.useState(phone || '');
  const [statusEdit, setStatus] = React.useState(status || '');
  const [nameEdit, setName] = React.useState(name || '');
  const [sourceEdit, setSourceEdit] = React.useState(source || '');
  const [estimateEdit, setEstimateEdit] = React.useState(estimate || '');

  useEffect(() => {
    initialize({
      start_date: start_date ? new Date(start_date) : null ,
    });
  }, [initialize, start_date])

  const handleSubmitEvent = (event) => {
    setEditMode(!editMode);
    event.preventDefault();

    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    handleSubmit({
      ...formProps,
      name: nameEdit,
      source: sourceEdit,
      budget: budgetEdit,
      start_date: moment(new Date(formProps.start_date.split("/").reverse().join('-'))).format('YYYY-MM-DD'),
      estimate: estimateEdit,
      description: descriptionEdit,
      email: emailEdit,
      phone: phoneEdit,
      status: statusEdit,
      lead_id: id,
      id: id,
    });
  };

  let Mask = maskForStatus(data.status);

  return (
    <Form className="form form--horizontal" onSubmit={handleSubmitEvent}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.name')}
        </span>
        <div className="form__form-group-field">
          <input
            type="text"
            disabled={!Mask.name}
            value={nameEdit}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.source')}
        </span>

        <div className="form__form-group-field">
          <input
            type="text"
            disabled={!Mask.source}
            value={sourceEdit}
            onChange={(e) => setSourceEdit(e.target.value)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.status')}
        </span>
        <div className="form__form-group-field">
          <select
            type="text"
            disabled={!Mask.status}
            value={statusEdit}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="dead_client"> Dead Client</option>
            <option value="lead">Lead</option>
            <option value="qualified_lead">Qualified Lead</option>
            <option value="prospect_lead">Prospect Lead</option>
            <option value="quoted_lead">Quoted Lead</option>
            <option value="client">Client</option>
          </select>
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.email')}
        </span>
        <div className="form__form-group-field">
          <input
            type="text"
            disabled={!Mask.email}
            value={emailEdit}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.phone')}
        </span>
        <div className="form__form-group-field">
          <input
            type="text"
            disabled={!Mask.phone}
            value={phoneEdit}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>

      <div className='form__form-group'>
        <span className='form__form-group-label'>
          {t('leads.single_summary.start')}
        </span>
        <div className='form__form-group-field'>
          <Field
            name='start_date'
            component={renderDatePickerField}
          />
        </div>
      </div>

      <div className='form__form-group'>
        <span className='form__form-group-label'>
          {t('leads.single_summary.budget')}
        </span>
        <div className='form__form-group-field'>
          <input
            type='text'
            disabled={!Mask.budget}
            value={budgetEdit}
            onChange={(e) => setBudget(e.target.value)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.estimate')}
        </span>
        <div className="form__form-group-field">
          <input
            type="text"
            disabled={!Mask.estimate}
            value={estimateEdit}
            onChange={(e) => setEstimateEdit(e.target.value)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('leads.single_summary.description')}
        </span>
        <div className="form__form-group-field">
          <textarea
            disabled={!Mask.description}
            value={descriptionEdit}
            rows="3"
            style={{ height: 'auto', minHeight: 'auto' }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar">
        {!editMode && (
          <Button color="primary" type="submit">
            Save
          </Button>
        )}
        {!editMode && (
          <Button color="warning" onClick={() => setEditMode(!editMode)}>
            Cancel
          </Button>
        )}
      </ButtonToolbar>
    </Form>
  );
};

export default reduxForm({
  form: 'info_table_edit_form',
  enableReinitialize: true
})(InfoTableEdit);
