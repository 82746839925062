import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Modal, Spinner } from 'reactstrap';
import Alert from '../../../../../shared/components/Alert';
import {renderField} from '../../../../../utils/helpers';
import validate from './validate';


const FormEditSubcontractor = (props) => {
  const { data, initialize, modal, toggle, handleSubmit, errors, loading } = props;
  const { t } = useTranslation('common');

  useEffect(() => {
    initialize(data);
  }, [data, initialize]);

  return (
    <Modal isOpen={modal}
           toggle={toggle}
           modalClassName={`ltr-support`}
           className={`modal-dialog--primary theme-light`}>
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle}/>
      </div>
      <div className="modal__body">
        {errors.length > 0 && errors.map((error, i) => {
          return (
            <Alert color="danger" className="alert--bordered" icon key={i}>
              <p><span className="bold-text">{error.param}</span> {error.message}</p>
            </Alert>
          );
        })}
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('financial_reports_dashboard.financial_report_id_label')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="financial_report_id"
                component={renderField}
                type="number"
                placeholder="1"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('financial_reports_dashboard.user_owner_id_label')}</span>
            <div className="form__form-group-field">
              <Field
                name="user_owner_id"
                component={renderField}
                type="number"
                placeholder="1"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('financial_reports_dashboard.title_label')}</span>
            <div className="form__form-group-field">
              <Field
                name="title"
                component={renderField}
                type="text"
                placeholder="title"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('financial_reports_dashboard.amount_label')}</span>
            <div className="form__form-group-field">
              <Field
                name="amount"
                component={renderField}
                type="number"
                placeholder="100"
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">Save</Button>
            <Button type="button" onClick={() => toggle()}>
              Cancel
            </Button>
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'edit_subcontractor',
  validate,
})(FormEditSubcontractor);