import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card, CardBody, Col, Button, ButtonToolbar, Row } from 'reactstrap';
import validate from './validate.js';
import renderFileInputField from "../../../../shared/components/form/FileInput";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { renderField } from '../../../../utils/helpers';


const FormAddNewLead = ({handleSubmit, value, setPhone, selectedFile, setSelectedFile}) => {
    return (
        <Col>
            <Card>
                <CardBody>
                    <form className="form" onSubmit={handleSubmit}>
                        <Row>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Name</span>
                                    <div className="form__form-group-field">
                                        <Field
                                          name="name"
                                          component={renderField}
                                          type="text"
                                          placeholder="Name"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Phone</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-input-wrap">
                                            <PhoneInput
                                              name="phone"
                                              placeholder="Enter phone number"
                                              value={value}
                                              onChange={setPhone}
                                              style={{width: "100%"}}
                                              limitMaxLength={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Email</span>
                                    <div className="form__form-group-field">
                                        <Field
                                          name="email"
                                          component={renderField}
                                          type="mail"
                                          placeholder="Email"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Source</span>
                                    <div className="form__form-group-field">
                                        <Field
                                          name="source"
                                          component={renderField}
                                          type="text"
                                          placeholder="Source"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Comment</span>
                                    <div className="form__form-group-field">
                                        <Field
                                          name="comment"
                                          component={renderField}
                                          type="text"
                                          placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" sm={6}>
                                <div className="form__form-group">
                            <span className="form__form-group-label">
                                Comment File
                            </span>
                                    <div className="form__form-group-field">
                                        <Field
                                          name="comment_file"
                                          value={selectedFile}
                                          onChange={(e) => setSelectedFile(e)}
                                          component={renderFileInputField}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12">
                                <ButtonToolbar className="form__button-toolbar d-flex w-100 justify-content-end">
                                    <Button color="primary" type="submit">Add</Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </Col>
    );
};


export default reduxForm({
    form: 'add_new_lead_form', // a unique identifier for this form
    validate,
})(FormAddNewLead);
