export const columns = [
  {
    Header: 'Customers',
    accessor: 'customers',
  },
  {
    Header: 'Project Name',
    accessor: 'name',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
  }
];