import {getFinancialReportEdit} from "../../utils/api";

export const GET_FINANCIAL_REPORT_EDIT_FETCHING = 'GET_FINANCIAL_REPORT_EDIT_FETCHING';
export const GET_FINANCIAL_REPORT_EDIT_FAIL = 'GET_FINANCIAL_REPORT_EDIT_FAIL';
export const GET_FINANCIAL_REPORT_EDIT_SUCCESS = 'GET_FINANCIAL_REPORT_EDIT_SUCCESS';
export const ADD_FINANCIAL_REPORT_ROW = 'ADD_FINANCIAL_REPORT_ROW';
export const REMOVE_FINANCIAL_REPORT_ROW = 'REMOVE_FINANCIAL_REPORT_ROW';
export const EDIT_FINANCIAL_REPORT_FIELD = 'EDIT_FINANCIAL_REPORT_FIELD';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const SET_FINANCIAL_REPORT_FIRST_THREE_FIELDS = 'SET_FINANCIAL_REPORT_FIRST_THREE_FIELDS';


export const getFinancialReportEditAction = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_FINANCIAL_REPORT_EDIT_FETCHING});
    getFinancialReportEdit(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getFinancialReportEditSuccess(data.data));
      })
      .catch(e => {
        dispatch(getFinancialReportEditFail(e));
      })
  }
};

export const getFinancialReportEditFail = (e) => {
  return {type: GET_FINANCIAL_REPORT_EDIT_FAIL, payload: {message: e?.message}}
};

export const getFinancialReportEditSuccess = (financialReportEdit) => {
  return {type: GET_FINANCIAL_REPORT_EDIT_SUCCESS, payload: {financialReportEdit}}
};

export const addFinancialReportRow = (e) => {
  return {type: ADD_FINANCIAL_REPORT_ROW, payload: {type: e}}
};

export const editFinancialReportField = (type, index, field, value) => {
  return {type: EDIT_FINANCIAL_REPORT_FIELD, payload: {type, index, field, value}}
};

export const removeFinancialReportRow = (type, index) => {
  return {type: REMOVE_FINANCIAL_REPORT_ROW, payload: {type, index}}
};

export const setValidationErrors = (data) => {
  return {type: SET_VALIDATION_ERRORS, payload: data}
};

export const setFinancialReportFirstThreeFields = ({ type, value }) => {
  return {type: SET_FINANCIAL_REPORT_FIRST_THREE_FIELDS, payload: {type, value}}
}