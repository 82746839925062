import React  from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';

import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';
import {
  changeThemeToDark,
  changeThemeToLight,
} from '../../redux/actions/themeActions';
import {
  CustomizerProps,
  SidebarProps,
  ThemeProps,
  RTLProps,
} from '../../shared/prop-types/ReducerProps';
import { withRouter } from '../../utils/helpers';


// eslint-disable-next-line no-return-assign
let notification = null;

NotificationSystem.newInstance(
  { style: { top: 65 } },
  (n) => {
    notification = n;
    return notification;
  },
);

const Layout = ({ dispatch, customizer, sidebar }) => {

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  // const changeToRTL = () => {
  //   dispatch(changeDirectionToRTL());
  // };

  // const changeToLTR = () => {
  //   dispatch(changeDirectionToLTR());
  // };

  // const topNavigation = () => {
  //   dispatch(toggleTopNavigation());
  // };

  // const borderRadius = () => {
  //   dispatch(changeBorderRadius());
  // };

  // const boxShadow = () => {
  //   dispatch(toggleBoxShadow());
  // };

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
    'layout--top-navigation': customizer.topNavigation,
  });

  return (
    <div className={layoutClass}>
      {customizer.topNavigation ? (
        <TopbarWithNavigation
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      ) : (
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
      )}
      {customizer.topNavigation ? (
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      ) : (
        <Sidebar
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      )}
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
    rtl: state.rtl,
  }))(Layout),
);
