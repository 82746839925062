import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../utils/helpers';


let ManagementDisapproveModalForm = ({confirm, successModal, toggleCloseModal, reportData, initialize}) => {
  const [hoursValue, setHoursValue] = useState('');

  useEffect(() => {
    initialize({
      hours: [parseInt(reportData?.hour?.split(':')[0]), parseInt(reportData?.hour?.split(':')[1] * 1.66666)].join('.')
    });
  }, [initialize, reportData.hour]);

  const handleHoursChange = (e) => {
    setHoursValue(e.target.value);
  };

  return (
    <Modal
      isOpen={successModal}
      toggle={toggleCloseModal}
      modalClassName="ltr-support"
      className="modal-dialog  modal-dialog--primary theme-light modal-add-new-variable"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleCloseModal}
        />
        <h4>Disapprove Report #{reportData.report_id}</h4>
      </div>
      <div className="modal__body">
        <form className="form w-100">
          <div className="form__form-group">
            <span className="form__form-group-label">Hours</span>
            <div className="form__form-group-field">
              <Field
                name="hours"
                component={renderField}
                type="input"
                onChange={handleHoursChange}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="modal__footer d-flex justify-content-between">
        <button className="btn btn-danger" onClick={toggleCloseModal}>Cancel</button>
        <button className="btn btn-primary" onClick={() => confirm(hoursValue)}>Confirm</button>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'management_disapprove_modal_form'
})(ManagementDisapproveModalForm);