import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import { backToLogin } from '../../../utils/helpers';
import { backToAdmin } from '../../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';

// import TopbarSearch from './TopbarSearch';


const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility,
}) => {
  const dispatch = useDispatch();
  const {user, loginAsBoolean} = useSelector(state => state.auth);

  const handleLogOut = () => {
    localStorage.clear();
    backToLogin();
  }

  return (
    <div className="topbar">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/dashboard" />
      </div>
      <div className="topbar__right">
        <Link to="#">Help</Link>
        <div className="d-flex name-and-role">
          <div>
            <span style={{whiteSpace: "nowrap"}}>{`${user.userData.first_name} ${user.userData.last_name}`}</span><br/>
            <span>{user.role}</span>
          </div>
          <img src={`${user?.userPhoto}` || process.env.PUBLIC_URL + '/img/user-avatar.svg'} alt={'Avatar'} />
        </div>
        <button className='log-out' onClick={handleLogOut}>
          <LogoutVariantIcon color="#b1c3c8" size={24} />
        </button>
        {/*<div className="topbar__right-search">*/}
          {/*<TopbarSearch />*/}
        {/*</div>*/}
        {
          loginAsBoolean ? (
            <div className="d-none d-md-flex justify-content-end align-items-center" style={{paddingLeft: "17px"}}>
              <button
                className="btn btn-primary mb-0"
                style={{whiteSpace: 'nowrap'}}
                onClick={() => dispatch(backToAdmin())}
              >
                Login back as ADMIN
              </button>
            </div>
          ) : null
        }
      </div>
    </div>
  )
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
