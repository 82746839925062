import { GET_LEAD_SUCCESS, GET_LEAD_FAIL, GET_LEAD_FETCHING } from '../actions/leadsSingleActions';

const initialState = {
    result: null,
    loading: false,
    errors: '',
};

const leadsSingleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEAD_FETCHING:
            return { ...state, loading: true };
        case GET_LEAD_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_LEAD_SUCCESS:
            return { ...state, result: action.payload, loading: false, errors: '' };
        default:
            return state;
    }
};

export default leadsSingleReducer;