export const columns = [
    {
        Header: '#',
        accessor: 'id',
    },
    {
        Header: 'Domain',
        accessor: 'domain',
    },
    {
        Header: 'Company(Name)',
        accessor: 'name',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },

    {
        Header: 'First name',
        accessor: 'first_name',
    },
    {
        Header: 'Last name',
        accessor: 'last_name',
        disableGlobalFilter: true,
    },
    {
        Header: 'Trial',
        accessor: 'trial_expires',
        disableGlobalFilter: true,
    },
    {
        Header: 'Active',
        accessor: 'is_active',
        disableGlobalFilter: true,
    },
    {
        Header: 'Confirmed',
        accessor: 'confirmed',
        disableGlobalFilter: true,
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        disableSortBy: true
    },
];