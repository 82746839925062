import AppDashboard from "../../../Dashboards/App";
import SaasDashboard from "../../../Dashboards/Saas";
import ClientModules from "../../../Dashboards/Saas/components/ClientModules/ClientModules";
import FormAddModule from "../../../Dashboards/Saas/components/ClientModules/FormAddModule/FormAddModule";
import BookingDashboard from "../../../Dashboards/Booking";
import MySalary from "../../../Account/Profile/components/MySalary/MySalary";
import FinancialReports from "../../../Dashboards/FinancialReports/index";
import StatisticProjects from "../../../Dashboards/Statistics/StatisticProjects";
import Subcontractors from "../../../Dashboards/FinancialReports/components/Subcontractors/Subcontractors";
import Roles from '../../../../config/roles';
import Environments from "../../../Dashboards/Projects/components/Environments/Environments";
import Projects from "../../../Dashboards/Projects/index";
import Variables from "../../../Dashboards/Projects/components/Environments/Variables/Variables";
import ProjectsEdit from "../../../Dashboards/Projects/components/ProjectsEdit/ProjectsEdit";
import AddNewLead from "../../../Dashboards/AddNewLead/AddNewLead";
import LeadsPage from "../../../Dashboards/LeadsPage/LeadsPage";
import SalaryReports from "../../../Dashboards/SalaryReports/SalaryReports";
import SalaryReportsList from "../../../Dashboards/SalaryReports/SalaryReportsList";
import SalaryReportEdit from "../../../Dashboards/SalaryReports/SalaryReportEdit";
import LeadsSingle from '../../../Dashboards/LeadsSingle';
import MyBankAccount from '../../../Dashboards/Settings/components/MyBankAccount/MyBankAccount';
import EmailTemplates from '../../../Dashboards/Settings/components/EmailTemplates/EmailTemplates';
import MyInvoices from '../../../Account/Profile/components/MyInvoices/MyInvoices';
import FinancialReportEdit from '../../../Dashboards/FinancialReports/components/FinancialReportEdit/FinancialReportEdit';
import FinancialReportIncomes from '../../../Dashboards/FinancialReports/components/FinancialReportIncome/FinancialReportIncomes';
import FinancialReportAutomaticIncome from '../../../Dashboards/FinancialReports/components/FinancialReportAutomaticIncome';
import FinancialReportAddIncome from '../../../Dashboards/FinancialReports/components/FinancialReportAutomaticIncome/FinancialReportAddIncome';
import YearlyStatistics from '../../../Dashboards/Statistics/YearlyStatistics';
import Invoices from '../../../Dashboards/Invoices';
import AddInvoices from '../../../Dashboards/Invoices/components/AddInvoices';
import FinancialReportStepsTwoAutomaticIncome
    from '../../../Dashboards/FinancialReports/components/FinancialReportAutomaticIncome/FinancialReportStepsTwoAutomaticIncome';
import VacationsHistory from '../../../Account/Profile/components/VacationsHistory';
import EditInvoice from '../../../Dashboards/Invoices/components/EditInvoice';
import Users from '../../../Dashboards/Users';
import CreateUser from '../../../Dashboards/Users/components/CreateUser';
import EditUser from '../../../Dashboards/Users/components/EditUser';
import WorkHistory from '../../../Dashboards/Users/components/WorkHistory';
import CreateWorkHistory from '../../../Dashboards/Users/components/WorkHistory/CreateWorkHistory/CreateWorkHistory';
import CreateSalaryReports from '../../../Dashboards/SalaryReports/components/CreateSalaryReports';
import ProjectsAdd from '../../../Dashboards/Projects/components/ProjectsAdd/ProjectsAdd';
import CreateSalaryList from '../../../Dashboards/SalaryReports/components/CreateSalaryList/CreateSalaryList';
import MyReports from '../../../Dashboards/Reports/MyReports';
import System from '../../../Dashboards/Account/System';
import FinancialActivity from '../../../Dashboards/FinancialActivity';
import Management from '../../../Dashboards/Management';
import MyReviews from '../../../Dashboards/MyReviews';
import Businesses from '../../../Dashboards/Businesses';
import AddNewBusinesses from '../../../Dashboards/Businesses/components/AddNewBusinesses/AddNewBusinesses';
import EditBusinesses from '../../../Dashboards/Businesses/components/EditBusinesses/EditBusinesses';
import BusinessesPaymentMethods
    from '../../../Dashboards/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethods';
import BusinessesPaymentMethodAdd
    from '../../../Dashboards/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethodAdd/BusinessesPaymentMethodAdd';
import BusinessesPaymentMethodEdit
    from '../../../Dashboards/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethodEdit/BusinessesPaymentMethodEdit';
import Account from '../../../Dashboards/Account/Account';
import BankAccount from '../../../Dashboards/Reports/BankAccount';

export const routes = [
    { path: "/dashboard", name: "Dashboard", Component: AppDashboard },
    { path: "/dashboard/booking_dashboard", name: "Booking dashboard", Component: BookingDashboard },
    {
        path: "/dashboard/profile/my-salary",
        name: "My salary",
        Component: MySalary
    },
    {
        path: "/dashboard/profile/my-invoices",
        name: "My Invoices",
        Component: MyInvoices,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM,
            Roles.GUEST
        ]
    },
    {
        path: "/dashboard/profile/vacations-history",
        name: "Vacations History",
        Component: VacationsHistory,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM
        ]
    },
    {
        path: "/dashboard/leads-page",
        name: "Leads page",
        Component: LeadsPage,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
        ]
    },
    {
        path: "/dashboard/leads-page/:id",
        name: "Lead #id",
        Component: LeadsSingle,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
        ]
    },
    {
        path: "/dashboard/add-a-new-lead",
        name: "Add New Lead",
        Component: AddNewLead,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
        ]
    },
    {
        path: "/dashboard/saas/clients",
        name: "Clients",
        Component: SaasDashboard,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/saas/clients/:id",
        name: "Client modules",
        Component: ClientModules,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/saas/clients/:id/module",
        name: "Add New Module",
        Component: FormAddModule,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/financial-reports",
        name: "Financial Reports",
        Component: FinancialReports,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:id/edit",
        name: "Financial report #id",
        Component: FinancialReportEdit,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:id/incomes",
        name: "Incomes",
        Component: FinancialReportIncomes,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:id/automatic-income",
        name: "Automatic Income",
        Component: FinancialReportAutomaticIncome,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:report_id/automatic-income/:id",
        name: "Invoices",
        Component: FinancialReportStepsTwoAutomaticIncome,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:id/incomes/income",
        name: "Add a New Income Item",
        Component: FinancialReportAddIncome,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/financial-reports/:id/subcontractors",
        name: "Subcontractors",
        Component: Subcontractors,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/project-statistics",
        name: "Project statistics",
        Component: StatisticProjects,
        permission: [
            Roles.ADMIN,
            Roles.FIN,
            Roles.SALES
        ]
    },
    {
        path: "/dashboard/yearly-statistics",
        name: "Yearly statistics",
        Component: YearlyStatistics,
        permission: [
            Roles.ADMIN
        ]
    },
    {
        path: "/dashboard/invoices",
        name: "Invoices",
        Component: Invoices,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/invoices/add",
        name: "Invoices Add",
        Component: AddInvoices,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/invoices/:id/edit",
        name: "Invoice Edit",
        Component: EditInvoice,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN
        ]
    },
    { path: "/dashboard/projects", name: "Projects", Component: Projects,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },
    { path: "/dashboard/projects/add", name: "Create Project", Component: ProjectsAdd,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },
    { path: "/dashboard/projects/:id/edit", name: "Edit Project #id", Component: ProjectsEdit,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },
    { path: "/dashboard/projects/:id/environments", name: "Environments", Component: Environments,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },
    { path: "/dashboard/projects/:id/environments/:env_id/variables", name: "Environment #env_id Variables", Component: Variables,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },

    { path: "/dashboard/salary-reports", name: "Salary reports", Component: SalaryReports,
        permission: [
            Roles.ADMIN,
            Roles.FIN
        ]
    },
    { path: "/dashboard/salary-reports/add", name: "Salary reports add", Component: CreateSalaryReports,
        permission: [
            Roles.ADMIN,
            Roles.FIN
        ]
    },
    { path: "/dashboard/salary-reports/:id/edit", name: "Edit Salary Report", Component: SalaryReportsList,
        permission: [
            Roles.ADMIN,
            Roles.FIN
        ]
    },
    { path: "/dashboard/salary-reports/:id/edit/add-list", name: "Create Salary List", Component: CreateSalaryList,
        permission: [
            Roles.ADMIN,
            Roles.FIN
        ]
    },
    { path: "/dashboard/salary-reports/:id/edit/:user_id/edit-list", name: "Edit Salary List", Component: SalaryReportEdit,
        permission: [
            Roles.ADMIN,
            Roles.FIN
        ]
    },
    {
        path: "/dashboard/settings/my-bank-account",
        name: "My Bank Account",
        Component: MyBankAccount,
        permission: [
            Roles.CLIENT
        ]
    },
    {
        path: "/dashboard/settings/email-templates",
        name: "Email Templates",
        Component: EmailTemplates,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM,
        ]
    },
    {
        path: "/dashboard/users",
        name: "Users",
        Component: Users
    },
    {
        path: "/dashboard/users/add",
        name: "Create User",
        Component: CreateUser
    },
    {
        path: "/dashboard/users/:id/edit",
        name: "Edit User",
        Component: EditUser
    },
    {
        path: "/dashboard/users/:id/work-history",
        name: "Work History",
        Component: WorkHistory
    },
    {
        path: "/dashboard/users/:id/work-history/add",
        name: "Create Work History",
        Component: CreateWorkHistory
    },
    {
        path: "/dashboard/my-reports",
        name: "My Reports",
        Component: MyReports,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM,
        ]
    },
    {
        path: "/dashboard/bank-account",
        name: "Bank Account",
        Component: BankAccount,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM,
        ]
    },
    {
        path: "/dashboard/system",
        name: "System",
        Component: System,
        permission: [
            Roles.ADMIN
        ]
    },
    {
        path: "/dashboard/financial-activity",
        name: "Financial Activity",
        Component: FinancialActivity,
        permission: [
            Roles.ADMIN
        ]
    },
    {
        path: "/dashboard/my-reviews",
        name: "My Reviews",
        Component: MyReviews,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.DEV,
            Roles.PM,
        ]
    },
    {
        path: "/dashboard/management",
        name: "Management",
        Component: Management,
        exec: true,
        permission: [
            Roles.ADMIN,
            Roles.SALES,
            Roles.FIN,
            Roles.CLIENT
        ]
    },
    {
        path: "/dashboard/businesses",
        name: "Businesses",
        Component: Businesses,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/businesses/add",
        name: "Add New Business",
        Component: AddNewBusinesses,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/businesses/:id/edit",
        name: "Edit Business",
        Component: EditBusinesses,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/businesses/:id/methods",
        name: "Payment Methods",
        Component: BusinessesPaymentMethods,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/businesses/:id/methods/add",
        name: "Add a new Payment Method",
        Component: BusinessesPaymentMethodAdd,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/businesses/:id/methods/:method_id/edit",
        name: "Edit Payment Method",
        Component: BusinessesPaymentMethodEdit,
        permission: [
            Roles.ADMIN,
        ]
    },
    {
        path: "/dashboard/settings",
        name: "Account",
        Component: Account,
        exec: true
    }
];