import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';

import renderSelectField from '../../../../../shared/components/form/Select';
import { renderField } from '../../../../../shared/helpers';
import { getMySalaryUsersFetchingAction } from '../../../../../redux/actions/mySalaryHistoryAction';

let timerId;

const MySalaryFilters = ({ resetForm, handleSubmit }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { users, totalUsers } = useSelector((state) => state.mySalary);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getMySalaryUsersFetchingAction({ search_query: search }));
  }, [search]);

  const options = users.map((u) => {
    return {
      id: u.id,
      label: `${u.first_name} ${u.last_name}`,
      value: u.id,
    };
  });

  return (
    <form className="form form--white w-100" onSubmit={handleSubmit}>
      <Row className="w-100">
        <Col sm={12} md={7} lg={8} xl={9}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('forms.search_label')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="search"
                component={renderField}
                type="text"
                placeholder={t('forms.search_placeholder')}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={5} lg={4} xl={3}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('forms.user_label')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="userId"
                component={renderSelectField}
                options={options}
                onInputChange={(value) => {
                  if (totalUsers <= 10) return;
                  clearTimeout(timerId);
                  timerId = setTimeout(() => {
                    setSearch(value);
                  }, 600);
                }}
              />
            </div>
          </div>
        </Col>
        <Col className="col-12">
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              color="outline-secondary"
              onClick={resetForm}
              style={{ maxHeight: '43px' }}
              className="mt-1"
            >
              {t('forms.clear_filters')}
            </Button>
            <Button
              type="submit"
              color="primary"
              style={{ maxHeight: '43px' }}
              className="mt-1"
            >
              {t('forms.apply_filters')}
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'my_salary_form',
})(MySalaryFilters);
