const validate = (value) => {
  const errors = {};

  if(!value.select_customer) {
    errors.select_customer = 'Please select the option';
  }

  if(!value.select_projects) {
    errors.select_projects = 'Please select the option';
  }

  if(!value.select_business) {
    errors.select_business = 'Please select the option';
  }

  if(!value.select_method) {
    errors.select_method = 'Please select the option';
  }

  if(!value.date_start) {
    errors.date_start = 'Date start field shouldn’t be empty';
  } else if(value.date_start > value.date_end) {
    errors.date_start = 'Data Start cannot be greater than Data End';
  }

  if(!value.date_end) {
    errors.date_end = 'Date end field shouldn’t be empty';
  }

  if(!value.subtotal) {
    errors.subtotal = 'Subtotal field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.subtotal)) {
    errors.subtotal = 'Invalid data type';
  }

  if(!value.discount) {
    errors.discount = 'Discount field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.discount)) {
    errors.discount = 'Invalid data type';
  }

  if(!value.total) {
    errors.total = 'Total field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.total)) {
    errors.total = 'Invalid data type';
  }

  if(!value.select_currency) {
    errors.select_currency = 'Please select the option';
  }

  if(!value.exchange_rate) {
    errors.exchange_rate = 'Exchange rate field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.exchange_rate)) {
    errors.exchange_rate = 'Invalid data type';
  }

  if(!value.note) {
    errors.note = 'Note field shouldn’t be empty';
  }

  return errors;
}

export default validate;