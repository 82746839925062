import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import FinancialReportAddIncomeForm from './FinancialReportAddIncomeForm';
import { postFinancialReportAddIncome } from '../../../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useSelector } from 'react-redux';

const FinancialReportAddIncome = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const authUser = useSelector(state => state.auth.user);

  const toggleCloseModal = () => {
    setErrorModal(!errorModal);
  }

  const handleAddIncome = (value) => {
    postFinancialReportAddIncome(value, id, authUser.accessToken)
      .then(res => {
       if(res.data.success !== true) {
         setErrorMessage(res.data.errors[0].message);
         setErrorModal(!errorModal);
       } else {
         navigate(`/dashboard/financial-reports/${id}/incomes`);
       }
      })
  }

  return (
    <Container>
      <Row>
        <Col>
          <FinancialReportAddIncomeForm onSubmit={handleAddIncome} />
        </Col>
        {
          <CustomModal
            successModal={errorModal}
            toggleCloseModal={toggleCloseModal}
            textModal={errorMessage}
            color={"danger"}
          />
        }
      </Row>
    </Container>
  );
};

export default FinancialReportAddIncome;