import React from 'react';
import { Button, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../utils/helpers';
import renderSelectField from '../../../../../shared/components/form/Select';
import { connect } from 'react-redux';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';

let ManagementFilterForm = ({handleSubmit, resetForm, reset, users = [], projects = [], onDownloadPDF, formValues, disabled, userRole}) => {
  const handleDownloadPDF = () => {
    onDownloadPDF(formValues);
  };

  return (
    <form onSubmit={handleSubmit} className="form w-100">
      <Col md={6} lg={4} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">Search Query</span>
          <div className="form__form-group-field">
            <Field
              name="search"
              component={renderField}
              type="input"
            />
          </div>
        </div>
      </Col>
      <Col md={6} lg={4} xl={2} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">Project</span>
          <div className="form__form-group-field">
            <Field
              name="selectProject"
              component={renderSelectField}
              id="project"
              options={[{id: '', name: "All Projects"}, ...projects || []]?.map(u => {
                return {
                  value: u.id,
                  label: u.name
                }
              })}
            />
          </div>
        </div>
      </Col>
      <Col md={6} lg={4} xl={2} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">Reporter</span>
          <div className="form__form-group-field">
            <Field
              name="selectReporter"
              component={renderSelectField}
              id="users"
              options={[{id: '', first_name: "All Users", last_name: ""}, ...users || []]?.map(u => {
                return {
                  value: u.id,
                  label: `${u.first_name} ${u.last_name}`
                }
              })}
            />
          </div>
        </div>
      </Col>
      <Col md={6} lg={6} xl={2} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">From Date</span>
          <div className="form__form-group-field">
            <Field
              name="from_date"
              component={renderDatePickerField}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col md={6} lg={6} xl={2} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">To Date</span>
          <div className="form__form-group-field">
            <Field
              name="to_date"
              component={renderDatePickerField}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col md={12} lg={6} className="col-12">
        <div className='d-flex align-items-center'>
          <button
            className='btn btn-primary w-50'
            style={{ maxHeight: '32px', marginBottom: 0, padding: '5px 10px' }}
            onClick={handleDownloadPDF}
            disabled={disabled}
          >
            Download PDF
          </button>
          {
            userRole !== "CLIENT" && (
              <div className='form__form-group mb-0'>
                <div className='form__form-group-field'>
                  <Field
                    name='is_internal'
                    component={renderCheckBoxField}
                    label='Is Internal'
                  />
                </div>
              </div>
            )
          }
        </div>
      </Col>
      <Col lg={6} className='d-flex align-items-center mt-3 col-12 mt-lg-1'>
        <div className='form_financial-activity-filter-buttons d-flex justify-content-end w-100'>
          <Button type='button' color='outline-secondary' onClick={() => {
            resetForm();
            reset();
          }} style={{ maxHeight: '32px', marginBottom: 0, padding: '5px 25px' }}>
            Clear Filters
          </Button>
          <Button type="submit" color="primary" style={{maxHeight: "32px", marginBottom: 0, padding: "5px 25px"}}>
            Apply Filters
          </Button>
        </div>
      </Col>
    </form>
  );
};

ManagementFilterForm = reduxForm({
  form: 'management_filter_form',
  enableReinitialize: true
})(ManagementFilterForm);

ManagementFilterForm = connect(
  (state, ownProps) => {
    const query = ownProps.query || {};
    const { users = [], projects = [] } = ownProps;

    return {
      initialValues: {
        from_date: new Date(query.from_date),
        to_date: query.to_date ? new Date(query.to_date) : null,
        selectProject: query?.project_id ? { value: query?.project_id, label: projects?.find(p => p?.id === Number(query?.project_id))?.name || '' } : { value: '', label: 'All Projects' },
        selectReporter: query?.user_id ? { value: query?.user_id, label: `${users?.find(u => u?.id === Number(query?.user_id))?.first_name} ${users?.find(u => u?.id === Number(query?.user_id))?.last_name}` || '' } : { value: '', label: 'All Users' },
        search: query.search_query || ''
      },
      formValues: state?.form?.management_filter_form ? state?.form.management_filter_form?.values : {}
    };
  }
)(ManagementFilterForm);

export default ManagementFilterForm;