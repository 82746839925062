import React from "react";
import PropTypes from "prop-types";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

const ShowError = ({ error, touched }) =>
  touched && error ? (
    <div className="error">
      <InformationOutlineIcon style={{ position: "relative", top: -2, marginRight: 2 }} size={18} color={'red'}/>
      {error}
    </div>
  ) : null;

ShowError.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool
};

export default ShowError;
