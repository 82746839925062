export const columns = [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    disableSortBy: true
  },
];