import {getFinancialReportIncome} from "../../utils/api";

export const GET_FINANCIAL_REPORT_INCOME_FETCHING = 'GET_FINANCIAL_REPORT_INCOME_FETCHING';
export const GET_FINANCIAL_REPORT_INCOME_FAIL = 'GET_FINANCIAL_REPORT_INCOME_FAIL';
export const GET_FINANCIAL_REPORT_INCOME_SUCCESS = 'GET_FINANCIAL_REPORT_INCOME_SUCCESS';


export const getFinancialReportIncomeAction = (query, id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_FINANCIAL_REPORT_INCOME_FETCHING});
    getFinancialReportIncome(query, id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getFinancialReportIncomeSuccess(data.data));
      })
      .catch(e => {
        dispatch(getFinancialReportIncomeFail(e));
      })
  }
};

export const getFinancialReportIncomeFail = (e) => {
  return {type: GET_FINANCIAL_REPORT_INCOME_FAIL, payload: {message: e?.message}}
};

export const getFinancialReportIncomeSuccess = (financialReportIncome) => {
  return {type: GET_FINANCIAL_REPORT_INCOME_SUCCESS, payload: {financialReportIncome}}
};