import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import UploadForm from './UploadForm/UploadForm';
import { getSignPhoto, postSignPhoto } from '../../../../../utils/api';
import UserPhoto from './UserPhoto/UserPhoto';
import { useSelector } from 'react-redux';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';


const AccountSign = () => {
  const [signPhoto, setSignPhoto] = useState('');
  const [showSignPhoto, setShowSignPhoto] = useState(false);
  const [loading, setLoading] = useState(true);
  const authUser = useSelector(state => state.auth.user);
  const [successDeleteLogoModal, setSuccessDeleteLogoModal] = useState(false);
  const [failedDeleteLogoModal, setFailedDeleteLogoModal] = useState(false);
  const [messageDeleteError, setMessageDeleteError] = useState('');

  useEffect( () => {
    getSignPhoto(authUser.userId, authUser.accessToken)
      .then(res => {
        if(!!res.data.data[0]) {
          setSignPhoto(res.data.data[0]);
          setShowSignPhoto(true);
        }
      }).catch(error => {
      console.error('Error fetching sign photo:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authUser.userId]);

  const handleDeletePhoto = () => {
    postSignPhoto('', authUser.accessToken).then((res) => {
      if(res.data.success) {
        setSuccessDeleteLogoModal(true);
      } else {
        setMessageDeleteError(res.data.errors[0].message);
        setFailedDeleteLogoModal(true);
      }
    })
  }

  return (
    <Card>
      <CardBody>
        {loading ? (
          <div>Loading...</div>
        ) : showSignPhoto ? (
          <UserPhoto signPhoto={signPhoto} setShowSignPhoto={setShowSignPhoto} handleDeletePhoto={handleDeletePhoto} />
        ) : (
          <UploadForm />
        )}
      </CardBody>

      <CustomModal
        successModal={failedDeleteLogoModal}
        toggleCloseModal={() => setFailedDeleteLogoModal(!failedDeleteLogoModal)}
        textModal={messageDeleteError}
        color={"danger"}
      />
      <CustomModal
        successModal={successDeleteLogoModal}
        toggleCloseModal={() => {
          setSuccessDeleteLogoModal(!successDeleteLogoModal);
          window.location.reload();
        }}
        textModal={`Sign was successfully deleted!`}
        color={"success"}
      />
    </Card>
  );
};

export default AccountSign;