export const columns = [
    {
        Header: 'Invoice ID',
        accessor: 'id',
    },
    {
        Header: 'Employee',
        accessor: 'user.username'
    },
    {
        Header: 'Period',
        accessor: 'period',
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableGlobalFilter: true,
    },
];