import React, {useState} from 'react';
import {Container, Row} from "reactstrap";
import FormAddNewLead from "./FormAddNewLead/FormAddNewLead";
import {postAddNewLead} from "../../../utils/api";
import {useNavigate} from "react-router-dom";
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import roles from '../../../config/roles';


const AddNewLead = () => {
    let navigate =useNavigate();
    let color = "danger";
    const [visible, setVisible] = useState(false);
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const authUser = useSelector(state => state.auth.user);

    const onDismiss = () => {
        setVisible(!visible);
    };

    const handleFile = async (file) => {
        let reader = new FileReader();
        file && reader.readAsDataURL(file);

        return file && (await new Promise((resolve) => {
            reader.addEventListener('load', (e) => {
                resolve(e?.currentTarget?.result)
            });
        }));

    }

    const handleSubmit = async (val) => {
        const result = await handleFile(val.comment_file?.file);

        postAddNewLead(phone, val.email, val.name, val.source, val.comment, result, authUser.accessToken)
            .then(res => {
                if (res.data.success) {
                    return navigate("dashboard/leads-page");
                }
                setErrors(res.data.errors);
                setVisible(true);
            }
        );
    }

    return (
        <Container>
            <Row>
                {
                    <Alert color={color} isOpen={visible}>
                        <button className="close" type="button" onClick={onDismiss} style={{border: 0, background: 'transparent'}}>
                            <span className="lnr lnr-cross" />
                        </button>
                        <div className="alert__content"><p><span className="bold-text">Warning! </span>{errors?.map(r => r.message)}</p></div>
                    </Alert>
                }
                {
                    authUser.role === roles.ADMIN || authUser.role === roles.SALES ? (<FormAddNewLead
                                                                            value={phone}
                                                                            setPhone={setPhone}
                                                                            onSubmit={handleSubmit}
                                                                            selectedFile={selectedFile}
                                                                            setSelectedFile={setSelectedFile}/>
                    ) : (<Alert color={color} isOpen={visible}>
                        <button className="close" type="button" onClick={onDismiss} style={{border: 0, background: 'transparent'}}>
                            <span className="lnr lnr-cross" />
                        </button>
                        <div className="alert__content"><p><span className="bold-text">Warning! </span> You do not have sufficient permissions to view this page.</p></div>
                    </Alert>)
                }
            </Row>
        </Container>
    );
};

export default AddNewLead;
