import React from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';


const YearlyStatisticsBonusesModal = ({toggle, modal, bonuses}) => {
  return (
    <Modal toggle={toggle} className="theme-light ltr-support add-income" isOpen={modal} style={{maxWidth: "800px", padding: 0}}>
      <ModalHeader>BONUSES</ModalHeader>
      <ModalBody>
        <Table responsive>
          <thead>
          <tr>
            <th>Bonus</th>
            <th>Bonus Points</th>
            <th>User Name</th>
          </tr>
          </thead>
          <tbody>
          {bonuses?.map((item, i) => (
            <tr key={i}>
              <td>{item.bonus}</td>
              <td>{item.bonusPoints}</td>
              <td>{item.user.name}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default YearlyStatisticsBonusesModal;