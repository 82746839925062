const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title field shouldn’t be empty';
  }

  if (!values.amount) {
    errors.amount = 'Amount field shouldn’t be empty';
  }

  return errors;
};

export default validate;

