import { getAllLeads } from '../../utils/api';

export const GET_ALL_LEADS_FETCHING = 'GET_ALL_LEADS_FETCHING';
export const GET_ALL_LEADS_SUCCESS = 'GET_ALL_LEADS_SUCCESS';
export const GET_ALL_LEADS_FAIL = 'GET_ALL_LEADS_FAIL';

export const getAllLeadsFail = (e) => {
  return { type: GET_ALL_LEADS_FAIL, payload: { message: e?.message } };
};

export const getAllLeadsSuccess = (data) => {
  return { type: GET_ALL_LEADS_SUCCESS, payload: data };
};

export const getAllLeadsFetchingActions = (query) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: GET_ALL_LEADS_FETCHING });
    getAllLeads(query, state.auth.user.accessToken)
      .then(({ data }) => {
        dispatch(getAllLeadsSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getAllLeadsFail(e));
      });
  };
};
