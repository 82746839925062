const validate = (value) => {
  const errors = {};

  if(!value.select_business) {
    errors.select_business = 'Please select the option';
  }

  if(!value.select_method) {
    errors.select_method = 'Please select the option';
  }

  if(!value.start_date) {
    errors.start_date = 'Date start field shouldn’t be empty';
  } else if(value.start_date > value.end_date) {
    errors.start_date = 'Data Start cannot be greater than Data End';
  }

  if(!value.end_date) {
    errors.end_date = 'Date end field shouldn’t be empty';
  }

  if(!value.subtotal) {
    errors.subtotal = 'Subtotal field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.subtotal)) {
    errors.subtotal = 'Invalid data type';
  }

  if(!String(value.discount)) {
    errors.discount = 'Discount field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.discount)) {
    errors.discount = 'Invalid data type';
  }

  if(!String(value.total)) {
    errors.total = 'Total field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.total)) {
    errors.total = 'Invalid data type';
  }

  if(!value.select_currency) {
    errors.select_currency = 'Currency field shouldn’t be empty';
  }

  if(!value.exchange_rate) {
    errors.exchange_rate = 'Exchange rate field shouldn’t be empty';
  } else if(!/^[0-9.]*$/.test(value.exchange_rate)) {
    errors.exchange_rate = 'Invalid data type';
  }

  if(!value.notes) {
    errors.notes = 'Note field shouldn’t be empty';
  }

  if(!String(value.vat_value)) {
    errors.vat_value = 'VAT Percentage field shouldn’t be empty';
  } else if(!/^[0-9]*$/.test(value.vat_value)) {
    errors.vat_value = 'Invalid data type';
  } else if(value.vat_value > 100) {
    errors.vat_value = 'VAT Percentage should not be more than 100';
  }

  return errors;
}

export default validate;