import {
  GET_WORK_HISTORY_FAIL,
  GET_WORK_HISTORY_FETCHING,
  GET_WORK_HISTORY_SUCCESS
} from '../actions/workHistoryActions';

const initialState = {
  workHistory: [],
  loading: false,
  errors: '',
  totalRecords: 0
};


const workHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_HISTORY_FETCHING:
      return { ...state, loading: true };
    case GET_WORK_HISTORY_FAIL:
      return { ...state, loading: false, errors: action.payload.errors[0].message };
    case GET_WORK_HISTORY_SUCCESS:
      return { ...state, workHistory: action.payload.data.workHistory, totalRecords: Number(action.payload.data?.total_records), loading: false, errors: '' };
    default:
      return state;
  }
};

export default workHistoryReducer;
