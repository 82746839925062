import {
    GET_FINANCIAL_REPORTS_FAIL,
    GET_FINANCIAL_REPORTS_FETCHING,
    GET_FINANCIAL_REPORTS_SUCCESS
} from '../actions/financialReportsActions';

const initialState = {
    financial_reports: [],
    loading: false,
    errors: '',
    totalRecords: 0
};


const financialReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FINANCIAL_REPORTS_FETCHING:
            return { ...state, loading: true };
        case GET_FINANCIAL_REPORTS_FAIL:
            return { ...state, loading: false, errors: action.payload.message };
        case GET_FINANCIAL_REPORTS_SUCCESS:
            return { ...state, financial_reports: action.payload.financial_reports.financialReport, totalRecords: Number(action.payload.financial_reports.meta.total), loading: false, errors: '' };
        default:
            return state;
    }
};

export default financialReportsReducer;
