import React from 'react';
import PropTypes from 'prop-types';

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case 'theme-dark': {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default: return ligthTheme;
  }
}

export default getTooltipStyles;

// Redux default input wrapper
export const renderField = (props) => {
  const { input, placeholder, type, meta: { touched, error } } = props;

  return (
    <div className="form__form-group-input-wrap">
      <input {...input} placeholder={placeholder} type={type}/>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};
