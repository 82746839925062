import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInvoiceFetchingAction } from '../../../../../redux/actions/editInvoiceActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import EditInvoiceForm from './EditInvoiceForm/EditInvoiceForm';
import { getBusinesses, getMethods, putInvoice } from '../../../../../utils/api';
import { change, formValueSelector } from 'redux-form';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';

const EditInvoice = ({selectBusiness}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { errors } = useSelector(state => state.editInvoice);
  const authUser = useSelector(state => state.auth.user);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [business, setBusiness] = useState([]);
  const [methods, setMethods] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    dispatch(getInvoiceFetchingAction(id));

    getBusinesses('', authUser.accessToken).then((res) => {
      if(res.data.success) {
        setBusiness(res.data.data.businesses);
      }
    })

    getMethods(1, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setMethods(res.data.data);
      }
    })
  }, [id, dispatch, refreshCounter])

  useEffect(() => {
    if(!selectBusiness) return;

    getMethods(selectBusiness.value, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setMethods(res.data.data);
        dispatch(change('editInvoiceForm', 'select_method', {value: res.data.data[0].id, label: res.data.data[0].name}));
      }
    })
  }, [selectBusiness, dispatch])

  const handleInvoiceEditForm = (values) => {
    putInvoice(values, id, authUser.accessToken)
      .then(res => {
        if(res.data.success !== true) {
          setErrorMessage(res.data.errors[0].message);
          setErrorModal(!errorModal);
        } else {
          setSuccessModal(!successModal);
        }
      })
  }

  return (
    <Container>
      <Row>
        <Col xxl={6} xl={8} lg={10} className="col-12">
          <div className="card">
            <div className="card-body pl-0 pr-0">
              <EditInvoiceForm
                onSubmit={handleInvoiceEditForm}
                business={business}
                methods={methods}
              />
            </div>
          </div>
          {
            errors ? (<div>{errors}</div>) : null
          }
          <CustomModal
            successModal={errorModal}
            toggleCloseModal={() => setErrorModal(!errorModal)}
            textModal={errorMessage}
            color={"danger"}
          />
          <CustomModal
            successModal={successModal}
            toggleCloseModal={() => {
              setSuccessModal(!successModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Invoice information with ${id} saved!`}
            color={"success"}
          />
        </Col>
      </Row>
    </Container>
  );
};

const selector = formValueSelector('editInvoiceForm');
export default connect(state => ({
  selectBusiness: selector(state, 'select_business')
}))(EditInvoice)