import {getInvoice} from "../../utils/api";

export const GET_INVOICE_FETCHING = 'GET_INVOICE_FETCHING';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';

export const getInvoiceFetchingAction = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: GET_INVOICE_FETCHING});
    getInvoice(id, state.auth.user.accessToken)
      .then(({data}) => {
        dispatch(getInvoiceSuccess(data.data));
      })
      .catch(e => {
        dispatch(getInvoiceFail(e));
      })
  }
};

export const getInvoiceFail = (e) => {
  return {type: GET_INVOICE_FAIL, payload: {message: e?.message}}
};

export const getInvoiceSuccess = (invoice) => {
  return {type: GET_INVOICE_SUCCESS, payload: {invoice}}
};