import {
  GET_BUSINESS_EDIT_FAIL,
  GET_BUSINESS_EDIT_FETCHING,
  GET_BUSINESS_EDIT_SUCCESS
} from '../actions/editBusinessActions';

const initialState = {
  business: [],
  loading: false,
  errors: ''
};


const editBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_EDIT_FETCHING:
      return { ...state, loading: true };
    case GET_BUSINESS_EDIT_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_BUSINESS_EDIT_SUCCESS:
      return { ...state, business: action.payload.business, loading: false, errors: '' };
    default:
      return state;
  }
};

export default editBusinessReducer;
