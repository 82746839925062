import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProjectsFetchingAction } from '../../../../../redux/actions/projectsActions';
import {
  deleteSubscriptionProject,
  postSubscriptionProject,
} from '../../../../../utils/api';
import DataReactTable from '../../../../Tables/DataTable/components/DataReactTable';
import { columns } from './AccountProjectsTableColumns';
import ConfirmModal from '../../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';


const TableButton = ({isActive, setSubscribeId, id, setSubscribeModal, setUnsubscribeId, setUnsubscribeModal, name, setNameProject}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getMetaData = () => {
    const obj = {
      btnLabel: "",
      btnClass: ""
    }
    if(isActive) {
      obj.btnLabel = "Activated";
      obj.btnClass = "btn-activated";
    }
    if(isHovered && isActive) {
      obj.btnLabel = "Deactivate";
      obj.btnClass = "btn-deactivated";
    }

    if(!isActive) {
      obj.btnLabel = "Deactivated";
      obj.btnClass = "btn-deactivated";
    }
    if(!isActive && isHovered) {
      obj.btnLabel = "Activate";
      obj.btnClass = "btn-activated";
    }

    return obj;
  }

  const {btnLabel, btnClass} = getMetaData();

  return (
    <button
      className={btnClass}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setNameProject(name);
        if(!isActive) {
          setSubscribeId(id);
          setSubscribeModal(true);
        } else {
          setUnsubscribeId(id);
          setUnsubscribeModal(true);
        }
      }}
    >{btnLabel}</button>
  )
}

const AccountProjects = () => {
  const { t } = useTranslation('common');
  const {projects, errors, totalRecords} = useSelector(state => state.projects);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [query] = useState({start: 0, limit: 10, search_query: ''});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [subscribeId, setSubscribeId] = useState(null);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [unsubscribeId, setUnsubscribeId] = useState(null);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [messageSubscribeError, setMessageSubscribeError] = useState('');
  const [failedSubscribeModal, setFailedSubscribeModal] = useState(false);
  const [successSubscribeModal, setSuccessSubscribeModal] = useState(false);
  const [messageUnsubscribeError, setMessageUnsubscribeError] = useState('');
  const [failedUnsubscribeModal, setFailedUnsubscribeModal] = useState(false);
  const [successUnsubscribeModal, setSuccessUnsubscribeModal] = useState(false);
  const [nameProject, setNameProject] = useState('');


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    dispatch(getProjectsFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, query, dispatch, refreshCounter]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleSubscribeProject = () => {
    postSubscriptionProject(subscribeId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessSubscribeModal(!successSubscribeModal);
      } else {
        setMessageSubscribeError(res.data.errors[0].message);
        setFailedSubscribeModal(!failedSubscribeModal);
      }
    })
  }

  const handleUnsubscribeProject = () => {
    deleteSubscriptionProject(unsubscribeId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessUnsubscribeModal(!successUnsubscribeModal);
      } else {
        setMessageUnsubscribeError(res.data.errors[0].message);
        setFailedUnsubscribeModal(!failedUnsubscribeModal);
      }
    })
  }

  return (
    <>
        <DataReactTable
          sortTable={false}
          search={false}
          pagination={{
            onChangePageSize: handleChangePageSize,
            onChangePage: handleChangePage,
            currentPage,
            totalRecords,
            pageLimit
          }}
          emptyStateMessage={t('empty_states.projects')}
          reactTableData={{tableHeaderData: columns, tableRowsData: projects?.map(p => {
            return {
              ...p,
              customers: p?.clients?.map(c => {
                return (
                  <span style={{whiteSpace: "nowrap"}}>
                    {c.first_name + " " + c.last_name} <br />
                  </span>
                )
              }),
              actions:
                <div className={"tableActions"}>
                <TableButton
                  isActive={p.is_subscribed}
                  id={p.id}
                  name={p.name}
                  setNameProject={setNameProject}
                  setSubscribeId={setSubscribeId}
                  setSubscribeModal={setSubscribeModal}
                  setUnsubscribeId={setUnsubscribeId}
                  setUnsubscribeModal={setUnsubscribeModal}
                />
              </div>
            }
          })}}
        />

        {
          errors ? (<div>{errors}</div>) : null
        }

        <ConfirmModal
          color="primary"
          btn="Default"
          message={`Are you sure you want to subscribe project #${subscribeId}?`}
          toggle={() => setSubscribeModal(!subscribeModal)}
          modal={subscribeModal}
          onNext={handleSubscribeProject}
        />
        <CustomModal
          successModal={failedSubscribeModal}
          toggleCloseModal={() => setFailedSubscribeModal(!failedSubscribeModal)}
          textModal={messageSubscribeError}
          color={"danger"}
        />
        <CustomModal
          successModal={successSubscribeModal}
          toggleCloseModal={() => {
            setSuccessSubscribeModal(!successSubscribeModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Subscribed to ${nameProject}`}
          color={"success"}
        />
        <ConfirmModal
          color="danger"
          btn="Default"
          message={`Are you sure you want to unsubscribe project #${unsubscribeId}?`}
          toggle={() => setUnsubscribeModal(!unsubscribeModal)}
          modal={unsubscribeModal}
          onNext={handleUnsubscribeProject}
        />
        <CustomModal
          successModal={failedUnsubscribeModal}
          toggleCloseModal={() => setFailedUnsubscribeModal(!failedUnsubscribeModal)}
          textModal={messageUnsubscribeError}
          color={"danger"}
        />
        <CustomModal
          successModal={successUnsubscribeModal}
          toggleCloseModal={() => {
            setSuccessUnsubscribeModal(!successUnsubscribeModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Unsubscribe from project ${nameProject}`}
          color={"success"}
        />
      </>
  );
};

export default AccountProjects;